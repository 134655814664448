import { useState, useEffect, useCallback, FC, MouseEvent } from 'react'
import { Route, useNavigate, useLocation, Routes } from 'react-router-dom'
import styled from 'styled-components'
import ReactHtmlParser from 'html-react-parser'

import {
    simMod, getFaultMessage, DefaultFaultId,
    showDebugMenu as enableDebugTools
} from '../configs'

import {
    useModuleContext, useTroubleshootContext,
    useUnityContext, useSolverContext, useLearningLabContext
} from '../contexts'
import { useSolver, useLearningLab, useToggle, useLRS } from '../hooks'
import { generalLogger as GeneralLogger } from '../Loggers'

import { FaultMode, SafetyExceptionType, ResourceImage } from '../interfaces'

import {
    Dialog, NavigationBar, UnityComponent,
    ResourcesViewer, HomeAccordion, BottomBar, EnvironmentVariables
} from './'

import { LearningPanel } from './learninglab'

import {
    FaultAttempt, FaultHistory, PostAssessment,
    Scoreboard, WorkOrder, FaultSelector,
    CustomTestFaultSelector
} from './troubleshoot'

const Simulation: FC = () => {
    const {
        moduleState: {
            moduleContent: { title },
            criticalErrorMessage
        }
    } = useModuleContext()
    const { solverState: { solverAvailable } } = useSolverContext()
    const {
        unityState: { unityLoaded, unityProgress },
        unityActions: { quitUnity }
    } = useUnityContext()

    const location = useLocation()
    const navigate = useNavigate()

    const { llState: { sectionIndex, } } = useLearningLabContext()
    const {
        tsState: { faultMode, isCustomTest },
        tsActions: { setFaultMode }
    } = useTroubleshootContext()

    const { section } = useLearningLab()
    const { loadFault, stopConnection, exitFault, completeFault } = useSolver()
    const { sendTerminate } = useLRS()

    const [showFaultMessage, toggleFaultMessage] = useToggle(true)

    const [visibleDiagrams, setVisibleDiagrams] = useState<ResourceImage[]>([])
    const [showWorkOrder, toggleWorkOrder] = useToggle(false)

    const closeFolderReferences = useCallback(() => {
        setVisibleDiagrams([])
        if (showWorkOrder) toggleWorkOrder()
    }, [showWorkOrder, toggleWorkOrder])

    const closeDiagram = (e: MouseEvent<HTMLElement>, diagram: ResourceImage) => {
        e.preventDefault()
        setVisibleDiagrams((prevDiagrams) => {
            const filteredDiagrams: ResourceImage[] = prevDiagrams.filter((item: ResourceImage) => item !== diagram)
            return filteredDiagrams.length === 0
                ? []
                : filteredDiagrams
        })
    }

    const showDiagram = (diagram: ResourceImage) => {
        if (visibleDiagrams.indexOf(diagram) > -1) return
        setVisibleDiagrams((prevDiagrams) => {
            const newDiagrams: ResourceImage[] = []
            newDiagrams.push(...prevDiagrams, diagram)
            return newDiagrams
        })
    }

    useEffect(() => {
        if (!unityLoaded && location.pathname !== '/') navigate('/', { replace: true })
    }, [navigate, location.pathname, unityLoaded])

    useEffect(() => {
        if (!solverAvailable || location.pathname !== '/explore' || !showFaultMessage) return
        const loadExplore = async () => {
            setFaultMode(FaultMode.Explore)
            if (faultMode !== FaultMode.Explore) return
            GeneralLogger.log('Loading Explore Mode...')
            await loadFault(DefaultFaultId)
        }
        loadExplore()
    }, [faultMode, loadFault, location.pathname, setFaultMode, showFaultMessage, solverAvailable])

    const handleLeave = useCallback(() => {
        closeFolderReferences()
        toggleFaultMessage()
    }, [closeFolderReferences, toggleFaultMessage])

    const goToPostAssessment = useCallback(() => {
        handleLeave()
        navigate('/postassessment', { replace: true })
    }, [handleLeave, navigate])

    const goHome = useCallback(() => {
        handleLeave()
        navigate('/', { replace: true })
    }, [handleLeave, navigate])

    const handleHomeClick = () => goHome()

    const handleExit = useCallback(() => {
        GeneralLogger.log(`Handling exit from ${location.pathname}...`)

        if (faultMode === FaultMode.Explore || location.pathname.includes('/lab/')) {
            goHome()
            return
        }
        goToPostAssessment()
    }, [faultMode, goHome, goToPostAssessment, location.pathname])

    const handleSafetyExceptionDialogClose = useCallback((safetyType: SafetyExceptionType) => {
        if (safetyType !== SafetyExceptionType.Zap && safetyType !== SafetyExceptionType.Stop) return

        handleExit()
    }, [handleExit])

    const handleUnload = useCallback(() => {
        GeneralLogger.warn('App unload triggered. Exiting app...')
        quitUnity()
        const { pathname } = location
        try {
            if (pathname.includes('/workorder')) {
                GeneralLogger.log('App: handleUnload: workorder')
                exitFault()
            }

            if (pathname.includes('/troubleshoot')) {
                GeneralLogger.log('App: handleUnload: troubleshoot')
                completeFault()
            }
            sendTerminate()
            stopConnection()
        }
        catch (error) {
            GeneralLogger.error(error)
        }
    }, [completeFault, exitFault, location, quitUnity, sendTerminate, stopConnection])

    useEffect(() => {
        if (!criticalErrorMessage) return
        handleUnload()
    }, [criticalErrorMessage, handleUnload])

    useEffect(() => {
        window.addEventListener('unload', handleUnload)
        return () => {
            window.removeEventListener('unload', handleUnload)
        }
    }, [handleUnload])

    const sectionName = simMod.isLab && sectionIndex !== undefined ? section(sectionIndex).name : ''
    const pageTitle = simMod.isLab ? sectionName : title
    const faultModeMessage = ReactHtmlParser(
        isCustomTest && simMod.faultModeMessage?.customTest
            ? simMod.faultModeMessage.customTest
            : faultMode ? getFaultMessage(faultMode) : ''
    )

    return (
        <>
            <Routes>
                <Route path='/' element={<HomeAccordion />} />
                <Route path='/scoreboard' element={<Scoreboard />} />
                <Route path='/workorder' element={<WorkOrder />} />
                <Route path='/workorder/:selectedFaultId' element={<WorkOrder />} />
                <Route path='/postassessment' element={<PostAssessment />} />
                <Route path='/faulthistory' element={<FaultHistory />} />
                <Route path='/faultattempt/:id' element={<FaultAttempt />} />
                <Route path='/faultselector' element={isCustomTest ? <CustomTestFaultSelector /> : <FaultSelector />} />
                {
                    ['/explore', '/troubleshoot'].map((path, index) => (
                        <Route path={path} key={'fm' + index} element={(
                            <Dialog
                                title={'Attention'}
                                visible={showFaultMessage}
                                onHide={toggleFaultMessage}
                            >
                                {faultModeMessage}
                            </Dialog>)
                        } />
                    ))
                }
                <Route path='/lab/:sectionIndex/:lessonIndex' element={<LearningPanel />} />
                {
                    enableDebugTools &&
                    <Route path='/environment-variables' element={<EnvironmentVariables />} />
                }
            </Routes>

            <Wrapper>
                <UnityComponent onZapandStop={handleSafetyExceptionDialogClose} />
                <NavigationBar
                    faultMode={faultMode}
                    pageTitle={pageTitle}
                    onConfirmExit={handleExit}
                    onHomeClick={handleHomeClick}
                    showDiagram={showDiagram}
                    showWorkOrder={toggleWorkOrder}
                />
                {
                    visibleDiagrams.length || showWorkOrder ? (
                        <ResourcesViewer
                            diagrams={visibleDiagrams}
                            onDiagramClose={closeDiagram}
                            showWorkOrder={showWorkOrder}
                            hideWorkOrder={toggleWorkOrder}
                        />
                    ) : <></>
                }
                {
                    !solverAvailable || !unityLoaded ? (
                        <BottomBar>
                            <div>{!solverAvailable ? `Establishing server connection...` : `Please wait. Loading simulation...`}</div>
                            <div>{`${!solverAvailable ? 50 : unityProgress}%`}</div>
                        </BottomBar>
                    ) : <></>
                }
            </Wrapper>
        </>
    )
}
export default Simulation

const Wrapper = styled.div({
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: '48em',
    minWidth: '85.375em',
    padding: '0',
    margin: 0,
    border: 0,
    top: 0,
    left: 0,
    overflow: 'hidden' //required in Firefox
})
