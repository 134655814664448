import { FC, ReactNode } from 'react'
import { Modal } from 'react-bootstrap'
import ReactHtmlParser from 'html-react-parser'

import { PrimaryButton } from '../styles/Buttons'

type Props = {
    visible: boolean
    onHide: () => void
    title: string
    size?: 'sm' | 'lg' | 'xl'
    buttons?: ReactNode[] | null
    children?: ReactNode
}

const Dialog: FC<Props> = ({ visible, onHide, title, size = 'lg', buttons, children }: Props) => {

    return (
        <>
            <Modal
                show={visible}
                onHide={onHide}
                size={size}
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
                className={'SimutechModal'}
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {ReactHtmlParser(title)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Modal.Footer>
                    {
                        !buttons || !buttons.length
                            ? <PrimaryButton onClick={onHide}>{`Close`}</PrimaryButton>
                            : <></>
                    }
                    {buttons}
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default Dialog