import { IUnityConfig } from 'react-unity-webgl/distribution/interfaces/unity-config'
import UnityConfig from '../unity-config.json'

const unityAssetsURL = './assets/unity/'

const { loaderUrl, dataUrl, frameworkUrl, codeUrl } = UnityConfig

const unityConfig: IUnityConfig = {
    loaderUrl: unityAssetsURL + loaderUrl,
    dataUrl: unityAssetsURL + dataUrl,
    frameworkUrl: unityAssetsURL + frameworkUrl,
    codeUrl: unityAssetsURL + codeUrl
}

export default unityConfig