import { FC } from 'react'
import { Img } from 'react-image'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { usePartSpecsImage } from '../hooks'
import { preventContextMenu } from '../utils'
import exitIcon from '../assets/icons/exit.svg'

type Props = {
    partType: string
    onClose: () => void
}

export const PartSpecsViewer: FC<Props> = ({ partType, onClose }: Props) => {
    const imgURL = usePartSpecsImage(partType)

    if (!partType) return <></>
    return (
        <Overlay onContextMenu={preventContextMenu}>
            <Wrapper>
                <CloseButton onClick={onClose} />
                <SpecsImage>
                    <Img
                        src={imgURL}
                        className='img-fluid'
                        alt=''
                        key={imgURL}
                        loader={<ClipLoader color={'white'} />}
                    />
                </SpecsImage>
            </Wrapper>
        </Overlay>
    )
}
export default PartSpecsViewer

const Overlay = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 20;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
`
const Wrapper = styled.div`
    pointer-events: auto;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .85);
`

const SpecsImage = styled.div`
    position: relative;
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: center;
    align-content: center;
    width: 100%;
    @media #{$mq-xxlarge} {
      width: 75%;
    }
    @media #{$mq-xxxlarge} {
      width: 65%;
    }
    img {
      max-height: 31.25em;
    }
`

const CloseButton = styled.div`
    position: absolute;
    top: 4em;
    right: 1em;
    width: 1.563em;
    height: 1.563em;
    margin: 1em;
    background-image: url(${exitIcon});
    &:hover {
      cursor: pointer;
    }
`