import { useCallback } from 'react'

import {
    build, commitID, environmentName,
    simMod, XAPI_URL
} from '../configs'
import { useModuleContext, useLMSContext } from '../contexts'

import { useLMS } from '.'
import { lrsService as LRSService } from '../Services'
import { getSessionInfo } from '../common/utils'
import { lmsLogger as LMSLogger } from '../Loggers'

import { TS_Profile, ModuleProfile, } from '../interfaces'
import { FaultAttemptItem, FullFaultAttempt, SessionInfo } from '../common/types'

const useLRS = () => {
    const { moduleState: { user, userProfile }, moduleActions: { setUserProfile, setCriticalErrorMessage } } = useModuleContext()
    const { lmsState: { isLMSAvailable, courseCompleted }, lmsActions: { setCourseCompleted } } = useLMSContext()
    const { isCourseComplete, setModulePassed } = useLMS()

    const markComplete = useCallback(async () => {
        if (courseCompleted) return
        const isCompleteInLMS = await isCourseComplete()
        if (isCompleteInLMS) {
            LMSLogger.warn(`Course is already marked as completed in LMS, but not in state. Setting now...`)
            setCourseCompleted(true)
            return
        }
        setModulePassed()
    }, [courseCompleted, isCourseComplete, setCourseCompleted, setModulePassed])

    const doCompleteCheck = useCallback((profile: TS_Profile) => {
        if (!isLMSAvailable || simMod.isLab || courseCompleted || !user || user.moduleLevel) return
        LMSLogger.log(`Checking if course should be marked as completed...`)

        if ((user.completeOnLevel && profile['module-level-achieved'] >= user.completeOnLevel)
            || (user.completeOnFirstFault && profile['module-number-faults-attempted'] > 0)) {

            LMSLogger.info(`Course config says course should be marked completed...`)
            markComplete()
            return
        }
        LMSLogger.info(`Course should not be marked completed.`)
    }, [courseCompleted, isLMSAvailable, markComplete, user])

    const getUserProfile = useCallback(async () => {
        if (!user) throw new Error('Tried to fetch user profile without a user.')

        const profile = await LRSService.fetchUserProfile<TS_Profile>(user)
        doCompleteCheck(profile)
        return profile
    }, [doCompleteCheck, user])

    const getAndSetUserProfile = useCallback(async () => {
        const profile = await getUserProfile()
        setUserProfile(profile)
    }, [getUserProfile, setUserProfile])

    const initUserProfile = useCallback(async () => {
        if (!user) throw new Error('Tried to initialize user profile without a user.')
        try {
            const userProfile = await getUserProfile()
            const sessionInfo: SessionInfo = {
                ...getSessionInfo(),
                environment: environmentName,
                'build-number': build,
                'commit-number': commitID,
            }
            await LRSService.sendInitialize(user, userProfile['module-registration-id'], sessionInfo)
            setUserProfile(userProfile)
        }
        catch (error) {
            if (error instanceof Error) {
                LRSService.logger.fatal('Failed trying to send initialize message to LRS.', error)
                setCriticalErrorMessage(error.message.toString())
            }
        }
    }, [setUserProfile, getUserProfile, user, setCriticalErrorMessage])

    const updateUserProfile = useCallback(async <T>(profile: Partial<T>) => {
        if (!user) throw new Error('Tried to update user profile without a user.')

        const updatedProfile = await LRSService.updateUserProfile<ModuleProfile>(user, profile)
        setUserProfile(updatedProfile)
        doCompleteCheck(updatedProfile as TS_Profile) //TODO: fix this
    }, [doCompleteCheck, setUserProfile, user])

    const updateLRSModuleScore = useCallback(async (moduleScore: number) => {
        const profile: Partial<ModuleProfile> = { 'module-score': moduleScore }
        await updateUserProfile(profile)
    }, [updateUserProfile])

    // Fault History

    const getFaultAttemptListByUser = useCallback(async (): Promise<FaultAttemptItem[]> => {
        if (!user) throw new Error('Tried to get fault attempt list without a user.')

        return await LRSService.Queries.getFaultAttemptListByUser(user)
    }, [user])

    const getFaultAttemptDetails = useCallback(async (attemptId: string): Promise<FullFaultAttempt> => {
        if (!user) throw new Error('Tried to get fault attempt details without a user.')

        return await LRSService.Queries.getFaultAttemptDetails(user.lmsSessionId, attemptId)
    }, [user])

    const sendTerminate = useCallback(() => {
        if (!user) throw new Error('Tried to send terminate without a user.')
        if (!userProfile) throw new Error('Tried to send terminate without a user profile.')
        return LRSService.sendTerminateBeacon(XAPI_URL, user, userProfile)
    }, [user, userProfile])

    return {
        initUserProfile,
        getAndSetUserProfile,
        sendTerminate,
        updateUserProfile,
        updateLRSModuleScore,
        getFaultAttemptListByUser,
        getFaultAttemptDetails
    }

}
export default useLRS
