import { useReducer, createContext, useContext, Dispatch, FC, ReactNode, useCallback } from 'react'

import { simMod } from '../configs'
import { SimutechModule } from '../common/types'


type State = {
    lessonModuleId: SimutechModule
    sectionIndex?: number
    altBg: number
}

const initialState: State = {
    lessonModuleId: simMod.moduleId,
    altBg: 0,
}

enum ActionType {
    SET_LESSON_MODULE_ID,
    SET_SECTION_INDEX,
    SET_ALT_BG,
}

type Action =
    {
        type: ActionType.SET_LESSON_MODULE_ID
        payload: typeof initialState.lessonModuleId
    } |
    {
        type: ActionType.SET_SECTION_INDEX
        payload: typeof initialState.sectionIndex
    } |
    {
        type: ActionType.SET_ALT_BG
        payload: typeof initialState.altBg
    }

const Context = createContext<{
    llState: State
    dispatch: Dispatch<Action>
}>({ llState: initialState, dispatch: () => null })

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.SET_LESSON_MODULE_ID:
            return { ...state, lessonModuleId: action.payload }
        case ActionType.SET_SECTION_INDEX:
            return { ...state, sectionIndex: action.payload }
        case ActionType.SET_ALT_BG:
            return { ...state, altBg: action.payload }
        default:
            return state
    }
}

const LearningLabProvider: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const [llState, dispatch] = useReducer(reducer, initialState)
    return (
        <Context.Provider value={{ llState, dispatch }}>
            {children}
        </Context.Provider>
    )
}

const useLearningLabContext = () => {
    const context = useContext(Context)
    if (!context) {
        throw new Error(
            'useLearningLab must be used within a LearningLabProvider. Wrap a parent component in <LearningLabProvider> to fix this error.'
        )
    }
    const { llState, dispatch } = context

    const actions = {
        setLessonModuleId: useCallback((moduleId: SimutechModule) =>
            dispatch({ type: ActionType.SET_LESSON_MODULE_ID, payload: moduleId }), [dispatch]),
        setSectionIndex: useCallback((index: number) =>
            dispatch({ type: ActionType.SET_SECTION_INDEX, payload: index }), [dispatch]),
        setAltBg: useCallback((altBg: number) =>
            dispatch({ type: ActionType.SET_ALT_BG, payload: altBg }), [dispatch]),
    }
    return { llState, llActions: actions }
}
export default useLearningLabContext
export { LearningLabProvider }