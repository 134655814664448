declare global {
    interface Document {
        documentMode?: any
    }
    interface Window {
        opr?: any
        opera?: any
        chrome?: any
        safari?: any
        styleMedia?: any
        ApplePaySetupFeature?: any
        InstallTrigger?: any
    }
}

// Agent strings are horrible for browser-detection since user's can modify their value.
// So we will detect browser features FIRST, then check for browser agent strings.
// We only try to use it as a last resort or to strengthen a check, and only if all other checks fail.
const agentHas = (keyword: string): boolean => navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1

// We will use IE's 'documentMode' to detect IE8+ or the unsupported 'reversed' 
// property of an 'ol' element for all other IE versions.
const isIE = /*@cc_on!@*/false || !!document.documentMode || !('reversed' in document.createElement('ol'))

const isEdge = !isIE && !!window.styleMedia && agentHas('Edg') && !agentHas('Edge')

// Since Edge Legacy is not detected as Edge, we need to check for it separately. 
// It also detects as Internet Explorer in the isIE check above so we'll use that 
// AND the agent string to detect it here.
const isEdgeLegacy = isIE && agentHas('Edge')

const isSafari = (!!window.ApplePaySetupFeature || !!window.safari) && agentHas('Safari') && !agentHas('Chrome') && !agentHas('CriOS')

// Opera is based on Chromium
const isOpera = !!window.opr?.addons || !!window.opera || (agentHas('OPR') || agentHas('Opera'))

const isFirefox = !!(typeof window.InstallTrigger) && agentHas('Firefox')

// Since Edge and Opera are based on Chromium, let's make sure that we are not detecting Edge or Opera as Chromium
const isChrome = !isEdge && !isOpera && (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) && (agentHas('CriOS') || agentHas('Chrome'))

enum BrowserNames {
    Safari = 'Safari',
    Opera = 'Opera',
    Firefox = 'Firefox',
    MicrosoftEdge = 'Microsoft Edge',
    MicrosoftEdgeLegacy = 'Microsoft Edge Legacy',
    Chrome = 'Chrome',
    InternetExplorer = 'Internet Explorer',
    Unknown = 'Unknown Browser',
}

const whichBrowser = () => {
    switch (true) {
        case isSafari:
            return BrowserNames.Safari
        case isOpera:
            return BrowserNames.Opera
        case isFirefox:
            return BrowserNames.Firefox
        case isEdge:
            return BrowserNames.MicrosoftEdge
        case isEdgeLegacy:
            return BrowserNames.MicrosoftEdgeLegacy
        case isChrome:
            return BrowserNames.Chrome
        case isIE:
            // Must stay after Edge checks since Edge Legacy would also be detected as IE
            return BrowserNames.InternetExplorer
        default:
            return BrowserNames.Unknown
    }
}

const browserName = whichBrowser()

export const BrowserDetect = { browserName, isIE, isSafari, isOpera, isChrome, isFirefox, isEdge }
