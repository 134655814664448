import { FC, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Draggable from 'react-draggable'

import { useTroubleshootContext } from '../../contexts'

import { WorkOrderSheet } from '.'
import './WorkOrder.scss'

type Props = {
    onWorkOrderClose: () => void
    cssClass: string
    onMouseDown: () => void
}

const WorkOrderReference: FC<Props> = ({ onWorkOrderClose, cssClass, onMouseDown }: Props) => {
    const { tsState: { workOrder } } = useTroubleshootContext()

    const width = window.outerWidth
    const height = window.outerHeight
    const nodeRef = useRef(null)

    return (
        <Container>
            <Row>
                <Col>
                    <Draggable
                        nodeRef={nodeRef}
                        bounds={{ top: -height, left: -width, right: width, bottom: height }}
                        positionOffset={{ x: `1vw`, y: `-90vh` }}
                        onMouseDown={onMouseDown}
                    >
                        <div
                            ref={nodeRef}
                            className={`work-order-page ${cssClass}`}
                        >

                            <div className={'interactive center-all column'}>

                                <WorkOrderSheet
                                    workOrder={workOrder}
                                    onWorkOrderClose={onWorkOrderClose}
                                />

                            </div>

                        </div>

                    </Draggable>
                </Col>
            </Row>
        </Container>
    )
}

export default WorkOrderReference

