import { ConsolaLogObject, ConsolaOptions, LogLevel } from 'consola'
import { isDebug } from '.'

export const DEFAULTS: ConsolaLogObject = {

}

const defaultLevel = { level: isDebug ? LogLevel.Verbose : LogLevel.Error }


const ccLoggerOptions: ConsolaOptions = { ...defaultLevel }

const rluLoggerOptions: ConsolaOptions = { ...defaultLevel }

const lrsLoggerOptions: ConsolaOptions = { ...defaultLevel }

const fmLoggerOptions: ConsolaOptions = { ...defaultLevel }

const solverLoggerOptions: ConsolaOptions = { ...defaultLevel }

const unityLoggerOptions: ConsolaOptions = { ...defaultLevel }

const generalLoggerOptions: ConsolaOptions = { ...defaultLevel }

const lmsLoggerOptions: ConsolaOptions = { ...defaultLevel }

const LoggerOptions = {
    CC: ccLoggerOptions,
    RLU: rluLoggerOptions,
    LRS: lrsLoggerOptions,
    LMS: lmsLoggerOptions,
    FaultManager: fmLoggerOptions,
    Solver: solverLoggerOptions,
    Unity: unityLoggerOptions,
    General: generalLoggerOptions,
}

export default LoggerOptions