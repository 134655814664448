import { useCallback, useMemo } from 'react'
import { debugCustomTestMaxTries, debugCustomTestShowResults } from '../configs'
import { useModuleContext, useLMSContext, useTroubleshootContext } from '../contexts'
import { CustomTestFault } from '../interfaces'

const useCustomTestSettings = () => {
    const { moduleState: { user } } = useModuleContext()
    const { lmsState: { isLMSAvailable } } = useLMSContext()
    const { tsState: { customTestState } } = useTroubleshootContext()

    // If the LMS is available, then we use its value.
    // If it is not available, then we use the value from the defaults or use the override from the query string.
    const maxNumberOfAttempts = customTestState?.maxNumberOfAttempts ?? 0 // if null or undefined, default to 0 (unlimited).
    const maxTries: number = useMemo(() => (
        isLMSAvailable
            ? maxNumberOfAttempts
            : debugCustomTestMaxTries === undefined
                ? maxNumberOfAttempts
                : debugCustomTestMaxTries
    ), [maxNumberOfAttempts, isLMSAvailable])

    // The Fault is unavailable if:    
    // - the max number of attempts has been reached, if not unlimited.
    // - the LMS is available and the solver has determined that the user cannot attempt the fault.
    // - the fault has been disabled in the backend and the user is not allowed to use disabled faults.
    const isFaultAvailable = useCallback((fault: CustomTestFault): boolean => {
        return !(
            !fault.faultId
            || (maxTries !== 0 && fault.currentNumberOfAttempts >= maxTries)
            || (isLMSAvailable && !fault.userCanAttemptFault)
            || (!fault.faultIsSelectable && !user?.canUseDisabledFaults)
        )
    }, [isLMSAvailable, maxTries, user?.canUseDisabledFaults])

    // The default is to show the Post Assessment page when exiting a fault and to allow reviewing 
    // past fault attempts from the Fault History page. So if any of the values are undefined,
    // we set this to TRUE.
    // If the LMS is available, then we use the value provided by the solver. 
    // If the LMS is NOT available, then we use the default value provided by the solver or we 
    // override it from the query string.
    const showResults = customTestState?.displayFaultAttemptResults ?? true
    const showFaultAttempts = useMemo(() => (
        isLMSAvailable
            ? showResults
            : debugCustomTestShowResults === undefined
                ? showResults
                : debugCustomTestShowResults
    ), [showResults, isLMSAvailable])

    return { isFaultAvailable, showFaultAttempts }
}
export default useCustomTestSettings