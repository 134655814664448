import { FC, MouseEvent } from 'react'

type Props = {
    text: string
    onButtonClick: (e: MouseEvent) => void
    disabled?: boolean
    visible?: boolean
    cssClass: string
}

const LessonButton: FC<Props> = ({ visible, onButtonClick, cssClass, disabled, text }: Props) => {
    if (!visible) return <></>

    const handleClick = (e: MouseEvent) => {
        e && e.preventDefault()
        onButtonClick(e)
    }

    return (
        <div className='button-bar'>
            <button
                className={'button ' + cssClass}
                onClick={handleClick}
                disabled={disabled}
            >
                {text}
            </button>
        </div>
    )
}
export default LessonButton