import { CSSProperties, MouseEvent, FC } from 'react'
import { Img } from 'react-image'
import { preventContextMenu } from '../../utils'

type Props = {
    visible: boolean
    onClose: (event: MouseEvent) => void
    imagePath: string
    containerCSS: CSSProperties
}

const ImageViewer: FC<Props> = ({ visible, onClose, containerCSS, imagePath }: Props) => {
    if (!visible) return <></>

    return (
        <div onContextMenu={preventContextMenu} className='media-area' style={containerCSS}>
            <div className='media-area-container' style={imageContainerStyle(visible)}>
                <div className='close-button' onClick={onClose} />
                <div className="interactive player-wrapper">
                    {imagePath !== '' && <Img alt='' src={imagePath} className='img-fluid' />}
                </div>
            </div>
        </div>
    )
}

export default ImageViewer

const imageContainerStyle = (showImage: boolean) => {
    return {
        animation: `${showImage ? 'show' : 'hide'}-videoplayer ease ${showImage ? '.5' : '0'}s alternate`,
        opacity: `${showImage ? '1' : '0'}`
    } as CSSProperties
}