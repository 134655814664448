import { Consola } from 'consola'

import { IApiClient } from '../api/ApiClient'
import { EndPoints, LRS_PATH, Query } from './constants'

import { getFromSession, isEmpty, sanitizePath, sanitizeBaseUrl } from '../../utils'

import {
    FaultAttemptItem, FaultAttemptSummary, FullFaultAttempt,
    ModuleProfile, ModuleSummaryResponse, ReportingUser,
    UserModuleProfile, SessionInfo, SimutechModule,
    Student, StudentModuleProfile, User
} from '../../types'

import {
    ILRSApiClient, LRSAgentProfile, LRSQueryRequest,
    LRSQueryResponse, LRSStatement, ModuleIDParam,
    QueryParameters, ModuleSummarQueryParams,
} from './types'

export class LRSApiClient implements ILRSApiClient {
    private apiBase: string
    private lrsApiClient: IApiClient
    logger: Consola

    constructor(lrsApiClient: IApiClient, logger: Consola) {
        this.apiBase = sanitizePath(LRS_PATH)
        this.lrsApiClient = lrsApiClient
        this.logger = logger
    }

    fetchUserProfile = async <T>(user: User | ReportingUser): Promise<T> => {
        this.logger.log('Retrieving user profile...')
        try {
            const response = await this.lrsApiClient.post<User | ReportingUser, string>(
                `${this.apiBase}${EndPoints.QueryLRSAgent}`,
                user,
                { headers: { 'Accept': 'application/json', } }
            )
            if (!response)
                throw new Error('Failed to fetch user profile: Response is empty.')
            const userProfile = (JSON.parse(response) as LRSAgentProfile<T>).profile
            if (!userProfile || isEmpty(userProfile))
                throw new Error('Received response, but user profile is empty.')

            this.logger.success('User profile retrieved:\n', userProfile)
            return userProfile
        }
        catch (exception) {
            this.logger.fatal(exception)
            throw new Error(`There was a problem fetching the user profile.\n ${exception}`)
        }
    }

    private getSessionTime = () => {
        const sessionEnd = new Date().getTime()
        const sessionStart = Number(getFromSession('sessionStart'))
        if (!sessionStart) {
            this.logger.error('No sessionStart timestamp found in session.')
            return 0
        }
        const sessionTime = (sessionEnd - sessionStart)

        this.logger.info('Total session time:', sessionTime)
        return sessionTime
    }

    private sendStatement = async (statement: LRSStatement) => {
        try {
            await this.lrsApiClient.post(
                `${this.apiBase}${EndPoints.PostLRSStatement}`,
                JSON.stringify(statement)
            )
        }
        catch (exception) {
            this.logger.error(exception)
            if (exception instanceof Error)
                throw new Error(exception.message)
        }
    }

    sendInitialize = async (
        user: User | ReportingUser,
        registrationId: string,
        sessionInfo: SessionInfo
    ) => {
        try {
            const lrsStatement: LRSStatement = {
                user,
                statement: {
                    'registration-id': registrationId,
                    action: 'initialize',
                    lmsSessionId: user.lmsSessionId,
                    duration: undefined,
                    sessionInfo: {
                        ...sessionInfo
                    }
                }
            }
            this.logger.info('Sending initialize statement:\n', lrsStatement)
            await this.sendStatement(lrsStatement)
        }
        catch (error) {
            const errorMessage = `There was a problem sending the initialize statement.\n ${error}`
            this.logger.error(errorMessage)
            throw new Error(errorMessage)
        }
    }

    updateUserProfile = async <T>(user: User | ReportingUser, profile: Partial<T>): Promise<T> => {
        this.logger.info('Updating LRS Profile with:\n', profile)
        const updatedProfile: LRSAgentProfile<Partial<T>> = { user, profile }

        return await this.lrsApiClient.post<string, LRSAgentProfile<T>>(
            `${this.apiBase}${EndPoints.UpdateLRSAgent}`,
            JSON.stringify(updatedProfile)
        )
            .then(response => {
                this.logger.success('LRS Profile updated:\n', response)
                return response.profile
            })
            .catch(error => {
                const errorMessage = `There was a problem updating LRS Profile.\n ${error}`
                this.logger.error(errorMessage)
                throw new Error(errorMessage)
            })
    }

    private sendLRSBeacon = (url: string, statement: LRSStatement): boolean => {
        const beaconUrl = `${sanitizeBaseUrl(url)}${this.apiBase}${EndPoints.PostLRSStatement}`
        return navigator.sendBeacon(beaconUrl, JSON.stringify(statement))
    }

    sendTerminateBeacon = (
        baseURL: string,
        user: User | ReportingUser,
        userProfile: ModuleProfile | UserModuleProfile
    ) => {
        try {
            this.logger.log('Sending LRS Terminate beacon...')
            const lrsStatement: LRSStatement = {
                user,
                statement: {
                    'registration-id': userProfile['module-registration-id'],
                    action: 'terminate',
                    lmsSessionId: user.lmsSessionId,
                    duration: this.getSessionTime().toString(),
                }
            }
            this.sendLRSBeacon(baseURL, lrsStatement)
        }
        catch (error) {
            const errorMessage = `There was a problem sending the terminate beacon.\n ${error}`
            this.logger.error(errorMessage)
        }
    }

    private runQuery = async <T>(
        queryName: Query,
        lmsSessionId: string,
        queryParams?: QueryParameters
    ): Promise<T> => {

        const request: LRSQueryRequest = {
            lmsSessionId,
            queryName,
            queryParms: {
                ...queryParams
            }
        }
        this.logger.info(`${EndPoints.RunLRSQuery} query REQUEST: ${request.queryName}`, request)
        try {

            const response = await this.lrsApiClient.post<LRSQueryRequest, LRSQueryResponse<T>>(
                `${this.apiBase}${EndPoints.RunLRSQuery}`,
                request
            )
            this.logger.success(`${EndPoints.RunLRSQuery} query RESPONSE: ${request.queryName}\n`, response)
            return response.queryResults
        }
        catch (error) {
            this.logger.error(`An error occurred while retrieving ${EndPoints.RunLRSQuery} data:\n`, error)
            this.logger.error(`The error occurred with query request:\n`, request)
            throw new Error(`An error occurred while retrieving data:\n ${error}`)
        }
    }

    getFaultAttemptListByUser = async (user: User | ReportingUser): Promise<FaultAttemptItem[]> => {
        const params: QueryParameters = { 'userId': user.userId, 'moduleId': user.moduleId }
        return await this.runQuery<FaultAttemptItem[]>(Query.GetFaultAttemptListByUser, user.lmsSessionId, params)
    }

    getFaultAttemptListByStudent = async (
        studentUserId: string,
        userLMSSessionId: string,
        moduleId: string
    ): Promise<FaultAttemptItem[]> => {
        const params: QueryParameters = { 'userId': studentUserId, 'moduleId': moduleId }
        return await this.runQuery<FaultAttemptItem[]>(Query.GetFaultAttemptListByUser, userLMSSessionId, params)
    }

    getFaultAttemptDetails = async (userLMSSessionId: string, faultAttemptId: string): Promise<FullFaultAttempt> => {
        const params: QueryParameters = { faultAttemptId }
        const faultAttempt = await this.runQuery<FullFaultAttempt[]>(Query.GetFaultAttemptDetails, userLMSSessionId, params)
        return faultAttempt[0]
    }

    getFaultAttemptSummaryByUser = async (
        userId: string,
        sessionId: string,
        moduleId: SimutechModule
    ): Promise<FaultAttemptSummary[]> => {
        const params: QueryParameters = { 'userId': userId, 'moduleId': moduleId }
        return await this.runQuery<FaultAttemptSummary[]>(Query.GetFaultAttemptSummaryByUser, sessionId, params)
    }

    getModuleSummaryByUser = async (userLMSSessionId: string): Promise<ModuleSummaryResponse[]> => {
        const response = this.runQuery<ModuleSummaryResponse[]>(Query.GetModuleSummaryByUser, userLMSSessionId)
        return response
    }

    private queryLRS = async <T>(params: ModuleSummarQueryParams) => {
        try {
            const response = await this.lrsApiClient.post<ModuleSummarQueryParams, T>(
                `${this.apiBase}${EndPoints.QueryLRSModuleSummary}`,
                params
            )
            this.logger.success(`${EndPoints.QueryLRSModuleSummary} (${params.LRSDatabaseName}, ${params.moduleId}, ${params.userId}) query RESPONSE:\n`, response)
            return response
        }
        catch (error) {
            this.logger.error(error)
            throw new Error(`Error querying LRS (${EndPoints.QueryLRSModuleSummary}):\n ${error}`)
        }
    }

    private getData = async <T>(
        lrsDBName: string,
        userId: string,
        moduleId: ModuleIDParam
    ) =>
        await this.queryLRS<T>({ LRSDatabaseName: lrsDBName, userId, moduleId })


    // User Profiles
    getAllStudents = async (lrsDBName: string) => {
        this.logger.log(`Retrieving all student profiles...`)
        return await this.getData<Student[]>(lrsDBName, '*', 'user-data')
    }

    getStudentProfileByUserID = async (userId: string, lrsDBName: string): Promise<Student> => {
        this.logger.log(`Retrieving student profile for user ID ${userId}...`)
        const response = await this.getData<Student[]>(lrsDBName, userId, 'user-data')
        if (response.length < 1) {
            this.logger.error(`No student profile found for user ID ${userId}`)
            throw new Error(`Seems like there is no student with that identifier.`)
        }
        return response[0]
    }

    // Module Profiles

    getAllModsForAllStudents = async (lrsDBName: string): Promise<StudentModuleProfile[]> => {
        this.logger.log(`Retrieving all module profiles for all students...`)
        const profiles = await this.getData<StudentModuleProfile[]>(lrsDBName, '*', '*')
        const pattern = /^((?!user-data).)*$/,
            filteredProfiles = profiles.filter((item: StudentModuleProfile) => pattern.test(item.profileId))
        return filteredProfiles
    }

    getModuleProfilesForAllStudentsByModule = async (lrsDBName: string, moduleID: SimutechModule): Promise<StudentModuleProfile[]> => {
        this.logger.log(`Retrieving module profiles for all students in '${moduleID}'...`)
        return await this.getData<StudentModuleProfile[]>(lrsDBName, '*', moduleID)
    }

    getAllModuleProfilesByStudent = async (student: Student, lrsDBName: string): Promise<StudentModuleProfile[]> => {
        this.logger.log(`Retrieving all module profiles for student '${student.userName}'...`)
        const profiles = await this.getData<StudentModuleProfile[]>(lrsDBName, student.userId, '*')
        const pattern = /^((?!user-data).)*$/,
            filteredProfiles = profiles.filter((item: StudentModuleProfile) => pattern.test(item.profileId))
        return filteredProfiles
    }

    getAllModuleProfilesByUserID = async (studentID: string, lrsDBName: string): Promise<StudentModuleProfile[]> => {
        this.logger.log(`Retrieving all module profiles for student ID '${studentID}'...`)
        const profiles = await this.getData<StudentModuleProfile[]>(lrsDBName, studentID, '*')
        const pattern = /^((?!user-data).)*$/,
            filteredProfiles = profiles.filter((item: StudentModuleProfile) => pattern.test(item.profileId))
        return filteredProfiles
    }

    // getModuleProfileByStudent = async (moduleID: SimutechModule, student: User, lrsDBName: string): Promise<StudentModuleProfile>=> {
    //     this.logger.info(`Retrieving '${moduleID}' module profile for student '${student.userName}'...`) 
    //     return await this.getData<StudentModuleProfile>(lrsDBName, student.userId, moduleID)
    // }
}

export default class LRSService {
    private lrsApiClient: ILRSApiClient
    logger: Consola

    constructor(apiClient: ILRSApiClient) {
        this.lrsApiClient = apiClient
        this.logger = apiClient.logger
    }

    fetchUserProfile = async <T>(user: User | ReportingUser) =>
        this.lrsApiClient.fetchUserProfile<T>(user)
    sendInitialize = async (user: User | ReportingUser, registrationId: string, sessionInfo: SessionInfo) =>
        this.lrsApiClient.sendInitialize(user, registrationId, sessionInfo)
    updateUserProfile = async <T>(user: User, profile: Partial<T>) =>
        this.lrsApiClient.updateUserProfile<T>(user, profile)
    sendTerminateBeacon = (baseURL: string, user: User | ReportingUser, userProfile: ModuleProfile | UserModuleProfile) =>
        this.lrsApiClient.sendTerminateBeacon(baseURL, user, userProfile)
    Queries = {
        getFaultAttemptListByUser: async (user: User | ReportingUser) =>
            this.lrsApiClient.getFaultAttemptListByUser(user),
        getFaultAttemptListByStudent: async (studentUserId: string, userLMSSessionId: string, moduleId: string) =>
            this.lrsApiClient.getFaultAttemptListByStudent(studentUserId, userLMSSessionId, moduleId),
        getFaultAttemptDetails: async (userLMSSessionId: string, faultAttemptId: string) =>
            this.lrsApiClient.getFaultAttemptDetails(userLMSSessionId, faultAttemptId),
        getFaultAttemptSummaryByUser: async (userId: string, sessionId: string, moduleId: SimutechModule) =>
            this.lrsApiClient.getFaultAttemptSummaryByUser(userId, sessionId, moduleId),
        getAllStudents: async (lrsDBName: string) =>
            this.lrsApiClient.getAllStudents(lrsDBName),
        getStudentProfileByUserID: async (userId: string, lrsDBName: string) =>
            this.lrsApiClient.getStudentProfileByUserID(userId, lrsDBName),
        getAllModsForAllStudents: async (lrsDBName: string) =>
            this.lrsApiClient.getAllModsForAllStudents(lrsDBName),
        getModuleProfilesForAllStudentsByModule: async (lrsDBName: string, moduleID: SimutechModule) =>
            this.lrsApiClient.getModuleProfilesForAllStudentsByModule(lrsDBName, moduleID),
        getAllModuleProfilesByStudent: async (student: Student, lrsDBName: string) =>
            this.lrsApiClient.getAllModuleProfilesByStudent(student, lrsDBName),
        getAllModuleProfilesByUserID: async (studentID: string, lrsDBName: string) =>
            this.lrsApiClient.getAllModuleProfilesByUserID(studentID, lrsDBName),
        getModuleSummaryByUser: async (userLMSSessionId: string) =>
            this.lrsApiClient.getModuleSummaryByUser(userLMSSessionId),
    }
}