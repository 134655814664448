import ModuleConfig from '../module.config.json'
import NEC from '../content-nec.json'
import IEC from '../content-iec.json'

import { simulationModuleId } from '.'

import { ModuleClass } from '../common/types'
import { FaultMode, ModuleContent, SimulationModule } from '../interfaces'

export const ContentIEC = IEC as ModuleContent
export const ContentNEC = NEC as ModuleContent

export const DefaultFaultId = 'F000'

const simMod = {
    moduleId: simulationModuleId,
    isLab: ModuleConfig.type === ModuleClass.LL,
    isTS: ModuleConfig.type === ModuleClass.TS,
    ...ModuleConfig
} as SimulationModule

export const getFaultMessage = (faultMode: FaultMode) => {
    if (!simMod.faultModeMessage) return ''

    switch (faultMode) {
        case FaultMode.Explore:
            return simMod.faultModeMessage.explore
        case FaultMode.Troubleshoot:
            return simMod.faultModeMessage.troubleshoot
        default:
            return ''
    }
}

export default simMod
