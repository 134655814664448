import { Consola } from 'consola'
import { LMSUser } from '../../types'

export interface ICCApiClient {
    fetchStudentInfo(lmsSessionId: string): Promise<LMSUser>
    getLMSSuspendedScore(
        lmsSessionId: string,
        courseTrackerId: number
    ): Promise<number>
    getLMSCourseStatus(
        lmsSessionId: string,
        courseTrackerId: number
    ): Promise<string>
    setLMSScore(
        lmsSessionId: string,
        courseTrackerId: number,
        score: number
    ): void
    setLMSSuspendData(
        lmsSessionId: string,
        courseTrackerId: number,
        data: string
    ): Promise<void>
    setLMSCourseCompleted(
        lmsSessionId: string,
        courseTrackerId: number
    ): Promise<void>
    sendConcedeControl(
        lmsSessionId: string,
        courseTrackerId: number
    ): Promise<void>
    logger: Consola
}

export enum LMSDataKey {
    Score = 'Score',
    SuspendData = 'SuspendData',
    CourseStatus = 'CourseStatus'
}

export type Score = { score: number }
export type SuspendData = { data: string }
export type CourseStatus = { status: string }

export interface LMSDataDTO<T> {
    dataRequested: LMSDataKey
    data: T
}
