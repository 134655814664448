import { FC } from 'react'
import { Modal } from 'react-bootstrap'

import { simMod } from '../configs'
import { ElectricalCode } from '../common/types'
import { LDLItem } from '../interfaces'

import './Modal.scss'
import { SecondaryButton, PrimaryButton } from '../styles/Buttons'

type Props = {
    ldlInfo: LDLItem
    show: boolean
    onHide: () => void
    onAccept: () => void
    electricalCode: ElectricalCode
}

const LDL: FC<Props> = ({ ldlInfo: { title, tableHeaders, steps }, onHide, onAccept, electricalCode, ...rest }: Props) => {

    const ldlHeaders = tableHeaders.map((header: string, idx: number) => (
        <th key={'ldlTh' + idx}>
            {header}
        </th>
    ))

    const ldlBody = steps.map((row, idx: number) => (
        <tr key={'ldlBody' + idx}>
            <td width={100}>{row.step.toString()}</td>
            <td width={500}>{row.test}</td>
            <td width={200}>{row.result[`${electricalCode.toLowerCase()}`]}</td>
        </tr>
    ))

    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
            style={{ color: 'Black' }}
            className={'SimutechModal'}
        >
            <Modal.Header closeButton={false}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (simMod.moduleId.includes('LLTS') &&
                        <p>{'You have successfully applied your lock and Do Not Operate tag.'}</p>
                    ) ||
                    (
                        <table className={'LDL-table'}>
                            <thead>
                                <tr>
                                    {ldlHeaders}
                                </tr>
                            </thead>
                            <tbody>
                                {ldlBody}
                            </tbody>
                        </table>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    (simMod.moduleId.includes('LLTS') &&
                        <PrimaryButton onClick={() => onAccept()}>{'Close'}</PrimaryButton>
                    ) ||
                    (
                        <>
                            <SecondaryButton onClick={() => onHide()}>{`Leave Testing`}<br />{`Procedure`}</SecondaryButton>
                            <PrimaryButton onClick={() => onAccept()}>{`Accept Test Results`}<br />{`And Continue Working`}</PrimaryButton>
                        </>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}
export default LDL