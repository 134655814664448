import { FC, ReactNode } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { SimutechBlue, SimutechOrange, SimutechOrangeActive, SimutechBlueActive } from './Theme'

const StyledButton = styled(Button)`
    font-family: "SourceSansPro-SemiBold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;        
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    min-height: 3rem;
    min-width: 10em;
    font-size: 1.5rem;
    margin: 0.5rem 0.5rem;
    border-radius: 0;
    padding: 1rem;
    line-height: 1.5rem;
    text-decoration: none;
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus
    {
        background-color: ${SimutechBlue} !important;
        color: ${SimutechOrangeActive};
        border-color: ${SimutechOrangeActive} !important;
        outline: none !important;
        box-shadow: none !important;
    } 
`

export const PrimaryButton = styled(StyledButton)`
    border: 1px solid ${props => props.disabled ? 'grey !important' : 'white'};
    background-color: ${props => props.disabled ? 'darkgrey !important' : SimutechBlue};
    color: ${props => props.disabled ? 'grey' : 'white'};
    ${props => props.disabled ? '' :
        `&:hover {
            border: 1px solid white;
            background-color: ${SimutechBlueActive};
            color: ${SimutechOrangeActive};
        }`
    }

`

export const SecondaryButton = styled(StyledButton)`
    border: 1px solid ${props => props.disabled ? 'grey !important' : SimutechBlue};
    background-color: ${props => props.disabled ? 'darkgrey !important' : 'white'};
    color: ${props => props.disabled ? 'grey' : SimutechBlue};
    ${props => props.disabled ? '' :
        `&:hover {
            border: 1px solid white;
            background-color: ${SimutechBlueActive};
            color: ${SimutechOrangeActive};
        }`
    }
`

export const ButtonGroup: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <ButtonGroupWrapper>
                        {children}
                    </ButtonGroupWrapper>
                </Col>
            </Row>
        </Container>
    )

}
const ButtonGroupWrapper = styled.div`
    margin: 1em;
    display: flex;
    align-content: center;
    justify-content: center;   
`

export const IconButton = styled.div`
    width: 1.75em;        

    &:hover {
        cursor: pointer;
        .svg-home, .svg-help, .svg-door {
            fill: ${SimutechOrange};
        }
        .svg-door2, .svg-folder {
            stroke: ${SimutechOrange};
        }
    }

    &:active {
        .svg-home, .svg-help, .svg-door {
            fill: ${SimutechOrangeActive};
        }
        .svg-door2, .svg-folder {        
            stroke: ${SimutechOrangeActive};
        }
    }

    &:disabled,
    &.disabled {
        
    }
    
`