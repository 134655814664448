import { FC, MouseEvent, useState, useEffect, ReactNode, useCallback } from 'react'
import { Container, Row, Col, Nav, Accordion } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ReactHtmlParser from 'html-react-parser'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { simMod, showDebugMenu } from '../configs'

import {
    useLearningLabContext, useLMSContext,
    useModuleContext, useTroubleshootContext
} from '../contexts'
import { useLearningLab, useLMS } from '../hooks'

import { addBodyClasses, preventContextMenu, removeBodyClasses } from '../utils'

import { Section, Lesson } from '../interfaces'

import { ExitIcon, HelpIcon } from '../assets/icons'
import SimutechLogo from '../assets/images/Logo.png'

import { HelpMenu, LRSPath } from '.'

import './HomeAccordion.scss'
import { IconButton } from '../styles/Buttons'

const { type, company, isLab, isTS } = simMod

const Home: FC = () => {
    const {
        moduleState: {
            user,
            userProfile,
            moduleContent: { title, description, customTestDescription, sections }
        }
    } = useModuleContext()
    const { tsState: { isCustomTest } } = useTroubleshootContext()
    const { lmsState: { isLMSAvailable } } = useLMSContext()
    const { llState: { altBg } } = useLearningLabContext()
    const { loadLesson, getLRSState, LRSState } = useLearningLab()

    const [showHelpMenu, setShowHelpMenu] = useState(false)
    const hideHelpMenu = () => setShowHelpMenu(false)

    const { concedeControl } = useLMS()
    const navigate = useNavigate()

    const activeSection = !userProfile
        ? 0
        : sections.indexOf(sections.find((section, sectionIndex) => getLRSState(sectionIndex) === LRSState.Ready)!) ?? sections.length - 1


    useEffect(() => {
        const classes = [type, 'home']
        if (altBg) classes.push(`alt${altBg}`)

        addBodyClasses(classes)
        return () => removeBodyClasses(classes)
    }, [altBg])


    const handleHelpClick = (e: MouseEvent) => {
        e.preventDefault()
        setShowHelpMenu(true)
    }

    const handleLessonClick = useCallback(async (event: MouseEvent, sectionIndex: number, lessonIndex: number) => {
        event?.preventDefault()
        loadLesson(sectionIndex, lessonIndex)
        navigate(`/lab/${sectionIndex}/${lessonIndex}`)
    }, [loadLesson, navigate])

    const getNavigation = useCallback((): ReactNode => {
        if (isTS || !sections.length) {

            return (
                <RightNav>
                    {
                        !user
                            ? (<Skeleton count={2} />)
                            : (
                                <>
                                    <LRSPath label={`Explore`} path={`./explore`} />
                                    <LRSPath label={isCustomTest ? `Faults` : `Troubleshoot`} path={`./scoreboard`} />
                                </>
                            )
                    }
                </RightNav>
            )
        }

        if (!userProfile) {
            return (
                <Accordion flush={true} key={'noprofile'}>
                    {
                        sections.map((section: Section, sectionIndex: number) => {
                            if (section.debugOnly && !showDebugMenu) return null

                            return (
                                <Accordion.Item
                                    key={'asi' + sectionIndex}
                                    eventKey={(sectionIndex).toString()}
                                >
                                    <Accordion.Header>
                                        <LRSPath
                                            label={section.name}
                                            sectionIndex={sectionIndex}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className={`description disabled`}>
                                            {ReactHtmlParser(section.description)}
                                        </div>
                                        {
                                            section.lessons.map((lesson: Lesson, lessonIndex: number) => (
                                                <LRSPath
                                                    key={'ll' + lessonIndex}
                                                    label={lesson.name}
                                                    path={''}
                                                    sectionIndex={sectionIndex}
                                                    lessonIndex={lessonIndex}
                                                    className={'lesson-link'}
                                                />
                                            ))
                                        }

                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            )
        }

        return (
            <Accordion key={'hasprofile'} defaultActiveKey={activeSection.toString()} flush={true}>
                {
                    sections.map((section: Section, sectionIndex: number) => {
                        if (section.debugOnly && !showDebugMenu) return null
                        // If section index is greater than 0 and the previous section LRSState is not Complete, make description look disabled
                        const sectionIsDisabled = !section.debugOnly && sectionIndex > 0 && !(getLRSState(sectionIndex - 1) === LRSState.Completed)

                        return (
                            <Accordion.Item
                                key={'asi' + sectionIndex}
                                eventKey={(sectionIndex).toString()}
                            >
                                <Accordion.Header>
                                    <LRSPath
                                        label={section.name}
                                        sectionIndex={sectionIndex}
                                    />
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className={`description ${sectionIsDisabled ? ' disabled' : ''}`}>
                                        {ReactHtmlParser(section.description)}
                                    </div>
                                    {
                                        section.lessons.map((lesson: Lesson, lessonIndex: number) => (
                                            <LRSPath
                                                key={lesson.LRSID + lessonIndex}
                                                label={lesson.name}
                                                path={''}
                                                sectionIndex={sectionIndex}
                                                lessonIndex={lessonIndex}
                                                className={'lesson-link'}
                                                clickHandler={(e: MouseEvent) => handleLessonClick(e, sectionIndex, lessonIndex)}
                                            />
                                        ))
                                    }

                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>
        )
    }, [sections, userProfile, activeSection, user, isCustomTest, getLRSState, LRSState.Completed, handleLessonClick])

    const getModuleTitle = useCallback(() => {
        return !user && isTS
            ? <Skeleton count={1} />
            : (
                <>
                    {isCustomTest && <div>{`Custom Curriculum for`}</div>}
                    {title}
                </>
            )
    }, [isCustomTest, title, user])

    const getModuleDescription = useCallback(() => {
        return !user && isTS
            ? (
                <>
                    <p><Skeleton count={3} /></p>
                    <p><Skeleton count={5} /></p>
                    <p><Skeleton count={2} /></p>
                </>
            )
            : ReactHtmlParser(isCustomTest && customTestDescription ? customTestDescription : description)

    }, [customTestDescription, description, isCustomTest, user])

    const moduleTitle = getModuleTitle()
    const moduleDescription = getModuleDescription()
    const contentNav = getNavigation()

    return (
        <>
            <Container
                onContextMenu={preventContextMenu}
                className="min-specs module-content homepage"
                fluid={true}
            >
                <Row>
                    <Col>
                        <div className={'logo'}>
                            <img src={SimutechLogo} alt={company} />
                        </div>
                    </Col>
                    <Col>
                        <TopNav>
                            <Nav.Link>
                                <IconButton
                                    title={'Help'}
                                    onClick={handleHelpClick}
                                >
                                    <HelpIcon />
                                </IconButton>
                            </Nav.Link>
                            <HelpMenu
                                visible={showHelpMenu}
                                hideMenu={hideHelpMenu}
                            />
                            <Nav.Link hidden={!isLMSAvailable}>
                                <IconButton onClick={concedeControl}>
                                    <ExitIcon />
                                </IconButton>
                            </Nav.Link>
                        </TopNav>
                    </Col>
                </Row>
                <SkeletonTheme
                    baseColor={isTS ? 'rgba(0, 52, 98, 0.5)' : 'rgb(244, 126, 86, 0.5)'}
                    highlightColor={isTS ? 'rgba(255, 255, 255, 0.25)' : 'rgb(244, 126, 86, 0.25)'}
                >
                    <Row>
                        <Col sm={{ offset: 1 }}>
                            <div className={'module-title'}>
                                {moduleTitle}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            sm={{
                                span: isLab ? 4 : 5,
                                offset: 1
                            }}
                        >
                            <div className={'module-description'}>
                                {moduleDescription}
                            </div>
                        </Col>
                        <Col
                            sm={{
                                span: 5,
                                offset: 1
                            }}
                            xl={{
                                span: isLab ? 5 : 4,
                                offset: 1
                            }}
                            xxl={{
                                span: isLab ? 5 : 4,
                                offset: 1
                            }}
                        >
                            {contentNav}
                        </Col>
                    </Row>
                </SkeletonTheme>
            </Container>
        </>
    )
}

export default Home

const TopNav = styled(Nav)`
    padding: 1em 1.25em;
    justify-content: flex-end;
    flex-wrap: nowrap;

    .nav-link {
        padding-left: 1.25em;
    }
`

const RightNav = styled(Nav)`
    justify-content: flex-end;
    flex-direction: column;
    font-size: 1.75em;    
`
