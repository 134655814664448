import { FC, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import { useModuleContext, useTroubleshootContext } from '../../contexts'
import { useCustomTestSettings, useFaultAttempt } from '../../hooks'

import { NavigationBar, LoadingOverlay, ErrorBox } from '../'
import { FaultAttemptDetails } from './'

import { SecondaryButton, ButtonGroup, PrimaryButton } from '../../styles/Buttons'
import { Wrapper } from '../../styles/Theme'

const FaultAttempt: FC = () => {
    const { id } = useParams()
    const { moduleState: { user } } = useModuleContext()
    const { tsState: { customTestState, isCustomTest }, tsActions: { setSelectedCustomTestFault } } = useTroubleshootContext()
    const { faultAttempt, error } = useFaultAttempt(id)
    const { isFaultAvailable, showFaultAttempts } = useCustomTestSettings()

    const navigate = useNavigate()

    const customTestFault = customTestState?.faultList.find((fault) => fault.faultId === faultAttempt?.faultId)
    const canRetry = isCustomTest && customTestFault ? isFaultAvailable(customTestFault) : !!faultAttempt
    const canReview = !isCustomTest || (isCustomTest && showFaultAttempts)

    const goToFaultHistory = (e: MouseEvent) => {
        e.preventDefault()
        navigate('/faulthistory')
    }

    const handleRetry = (e: MouseEvent) => {
        e.preventDefault()
        if (!canRetry || !faultAttempt) return
        if (customTestFault) setSelectedCustomTestFault(customTestFault)
        navigate(`/workorder/${faultAttempt.faultId}`)
    }

    useEffect(() => {
        if (!canReview) navigate('/', { replace: true })
    }, [canReview, navigate])

    if (error) return (
        <>
            <NavigationBar />
            <Wrapper>
                <ErrorBox message={error}>
                    <p><Link to={'/faulthistory'}>{'Go Back'}</Link></p>
                </ErrorBox>
            </Wrapper>
        </>
    )

    if (!canReview) return null

    if (!user || !faultAttempt) {
        const msg = !user ? 'Loading your account' : 'Loading fault attempt details'
        return (
            <div className="module-content">
                <NavigationBar />
                <Container className="pre-fault" fluid={true}>
                    <LoadingOverlay
                        active={!faultAttempt}
                        text={msg}
                    />
                </Container>
            </div>
        )
    }

    return (
        <div className="module-content">
            <NavigationBar />
            <Wrapper>
                <FaultAttemptDetails faultAttempt={faultAttempt} />
                <ButtonGroup>
                    <PrimaryButton onClick={goToFaultHistory}>
                        {'BACK'}
                    </PrimaryButton>
                    <SecondaryButton
                        disabled={!canRetry}
                        onClick={handleRetry}
                    >
                        {'Retry Fault'}
                    </SecondaryButton>
                </ButtonGroup>
            </Wrapper>
        </div>
    )
}

export default FaultAttempt