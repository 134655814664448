import { useEffect, FC, MouseEvent, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import { useTroubleshootContext } from '../../contexts'
import { useCustomTestSettings, useSolver } from '../../hooks'

import { preventContextMenu } from '../../utils'
import { generalLogger as GeneralLogger } from '../../Loggers'

import { NavigationBar } from '../'
import { WorkOrderSheet } from './'

import { ButtonGroup, PrimaryButton, SecondaryButton } from '../../styles/Buttons'
import './WorkOrder.scss'


const WorkOrder: FC = () => {
    const { tsState: { workOrder, isCustomTest, selectedCustomTestFault }, tsActions: { setSelectedCustomTestFault } } = useTroubleshootContext()
    const { loadFault, beginFault, exitFault } = useSolver()
    const navigate = useNavigate()
    const { isFaultAvailable } = useCustomTestSettings()

    const { selectedFaultId } = useParams()

    const handleBegin = (event: MouseEvent) => {
        event.preventDefault()
        if (!workOrder) return
        (event.target as HTMLButtonElement).disabled = true
        beginFault()
        navigate('/troubleshoot')
    }

    const handleCancel = (event: MouseEvent) => {
        event.preventDefault()
        if (!workOrder) return
        exitFault()
        if (isCustomTest) setSelectedCustomTestFault(null)
        navigate(-1)
    }

    const faultIsLoaded = useRef(false)
    useEffect(() => {
        if (faultIsLoaded.current || (isCustomTest && !selectedCustomTestFault)) return

        if (isCustomTest && selectedCustomTestFault && !isFaultAvailable(selectedCustomTestFault)) {
            GeneralLogger.warn('Fault is not available, exiting...')
            navigate('/', { replace: true })
            return
        }
        faultIsLoaded.current = true
        loadFault(selectedFaultId || 'FRND')
    }, [isCustomTest, isFaultAvailable, loadFault, navigate, selectedCustomTestFault, selectedFaultId])


    return (
        <div className="work-order-page module-content" onContextMenu={preventContextMenu}>
            <NavigationBar onHomeClick={exitFault} />
            <Container style={{ height: '100%' }}>
                <Row>
                    <Col>
                        <div className={'center-all column'}>
                            <WorkOrderSheet workOrder={workOrder} />

                            <ButtonGroup>
                                <PrimaryButton disabled={!workOrder} onClick={handleCancel}>
                                    {'CANCEL'}
                                </PrimaryButton>
                                <SecondaryButton disabled={!workOrder} onClick={handleBegin}>
                                    {'BEGIN'}
                                </SecondaryButton>
                            </ButtonGroup>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default WorkOrder