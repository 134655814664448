import { useState, FC, MouseEvent, useCallback } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import DataTable, { TableColumn } from 'react-data-table-component'

import { simMod } from '../../configs'
import { useModuleContext, useTroubleshootContext } from '../../contexts'

import { useFaultList } from '../../hooks'

import { NavigationBar, LoadingOverlay, NoDataComponent, ErrorBox } from '../'
import { customDataTableStyles, TableTitle, Wrapper } from '../../styles/Theme'
import { PrimaryButton, SecondaryButton, ButtonGroup } from '../../styles/Buttons'
import { SortDownIcon } from '../../styles/Icons'
import { ModuleFault } from '../../common/types'

const FaultSelector: FC = () => {
    const navigate = useNavigate()
    const { moduleState: { user } } = useModuleContext()
    const { tsState: { isCustomTest } } = useTroubleshootContext()

    const canSelectFaults = !!user?.canSelectFaults

    if (simMod.isLab || (!canSelectFaults && !isCustomTest)) navigate('/', { replace: true })

    const { faultList, error } = useFaultList()
    const [selectedFault, setSelectedFault] = useState<ModuleFault | null>(null)

    const goBack = () => navigate('/scoreboard')
    const rowSelected = useCallback((row: ModuleFault) => row.faultId === selectedFault?.faultId, [selectedFault])

    const onRowClick = useCallback((row: ModuleFault, event: MouseEvent) => {
        event.preventDefault()
        if (!rowSelected(row)) setSelectedFault(row)
    }, [rowSelected])

    const onStart = useCallback((e: MouseEvent) => {
        e.preventDefault()
        if (!selectedFault || (!selectedFault.isFaultSelectable && !canSelectFaults)) return
        navigate(`/workorder/${selectedFault.faultId}`)
    }, [canSelectFaults, navigate, selectedFault])

    const columns: TableColumn<ModuleFault>[] = [
        {
            id: 'faultId',
            name: 'Fault ID',
            selector: (row) => row.faultId,
            cell: (row) => `${row.faultId}${row.isFaultSelectable ? '' : ' (Disabled)'}`
        }
    ]

    if (error) return <ErrorBox message={error} />

    if (!user || !faultList)
        return (
            <div className="module-content">
                <NavigationBar />
                <Container className="pre-fault" fluid={true}>
                    <LoadingOverlay
                        active={!faultList}
                        text={`Loading list of faults`}
                    />
                </Container>
            </div>
        )


    return (
        <div className="module-content">
            <NavigationBar />
            <Wrapper>
                <TableTitle className={'title'}>
                    {'Fault Selection'}
                </TableTitle>
                <div>
                    <DataTable
                        noHeader={true}
                        columns={columns}
                        data={faultList}
                        highlightOnHover={true}
                        pointerOnHover={true}
                        onRowClicked={(row, event) => onRowClick(row, event)}
                        selectableRowsHighlight={true}
                        selectableRowsNoSelectAll={true}
                        selectableRowsSingle={true}
                        selectableRowSelected={rowSelected}
                        striped={true}
                        sortIcon={<SortDownIcon />}
                        pagination={true}
                        paginationPerPage={10}
                        paginationComponentOptions={{ noRowsPerPage: true }}
                        customStyles={customDataTableStyles}
                        dense={true}
                        noDataComponent={<NoDataComponent text={`There are no faults available.`} />}
                    />
                </div>
                <ButtonGroup>
                    <PrimaryButton onClick={goBack}>
                        {'Back'}
                    </PrimaryButton>
                    {
                        faultList.length ? (
                            <SecondaryButton
                                disabled={!selectedFault || !selectedFault.faultId || (!selectedFault.isFaultSelectable && !user.canUseDisabledFaults)}
                                onClick={onStart}
                            >
                                {
                                    selectedFault && selectedFault.faultId && !selectedFault.isFaultSelectable && !user.canUseDisabledFaults
                                        ? 'FAULT DISABLED'
                                        : 'Begin Fault'
                                }
                            </SecondaryButton>
                        ) : <></>
                    }
                </ButtonGroup>
            </Wrapper>
        </div>
    )
}
export default FaultSelector
