import { FC } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { useTroubleshootContext } from '../../contexts'
import { sumProp } from '../../common/utils'
import { Component, FaultState, FullFaultAttempt } from '../../common/types'
import { FaultAttempt } from '../../interfaces'

interface Props {
    faultAttempt: FaultAttempt | FullFaultAttempt
}

const FaultAttemptDetails: FC<Props> = ({ faultAttempt }: Props) => {
    const { tsState: { isCustomTest } } = useTroubleshootContext()

    const {
        faultSuccess,
        circuitLeftNotOperational,
        hourlyCost,
        overallScore,
        skillRating,
        safetyRating,
        accuracyRating,
        efficiencyRating,
        totalTimeMinutes,
        totalCost,
        exitByZapPersonalInjuryError,
        stopLiveCircuitErrorOccurrences,
        cautionSafeWorkplacePracticeOccurrences,
        componentsReplaced,
        hintsTaken,
        totalMeterReadings,
        seatTimePenaltyScore,
        meterReadingsPenaltyScore,
        allowedReplacementPenaltyScore,
        allowedRepairsPenaltyScore,
        numberUnnecessaryReplacedComponents,
        numberUnnecessaryRepairedComponents
    } = getFaultAttemptDetails(faultAttempt)

    const isAbandoned = overallScore === undefined
    const title = isAbandoned
        ? 'The fault was abandoned.'
        : faultSuccess
            ? 'Congratulations on solving the fault!'
            : 'The fault was not solved correctly!'

    const componentsBreakdown = componentsReplaced.map((replacedItem: Component, i: number) => <ComponentExpenditure key={i} replacedItem={replacedItem} />)

    return (
        <Container className="post-fault" fluid={true}>
            <Row>
                <Col>
                    <div className={`title ${faultSuccess ? 'text-success' : 'text-danger'} ${isCustomTest ? ' mb-5' : ''}`}>
                        {title}
                    </div>
                </Col>
            </Row>
            {
                !isCustomTest && (
                    <Row>
                        <Col>
                            <Row>{'Your score on this fault:'}</Row>
                            <Row className="fault-score">
                                {overallScore ?? 0}
                            </Row>
                        </Col>
                    </Row>
                )
            }
            <Row className={'rating-column'}>
                <Col sm={{ span: 4 }}>
                    <ScoreRating title={'Skill Rating'} value={skillRating} />
                    <ScoreRating title={'Safety'} value={safetyRating} />
                    <ScoreRating title={'Accuracy'} value={accuracyRating} />
                    <ScoreRating title={'Efficiency'} value={efficiencyRating} />
                </Col>
                <Col sm={{ span: 4 }}>
                    <Row className="breakdown title">
                        <span>{'Key Indicators:'}</span>
                    </Row>
                    <Row className="breakdown">
                        <Col>
                            <Row>
                                <Col className={circuitLeftNotOperational ? 'warning' : ''}>
                                    {circuitLeftNotOperational ? 1 : 0} {' - System Left Non-Operational'}
                                </Col>
                            </Row>
                            <Row>
                                <Col className={exitByZapPersonalInjuryError ? 'warning' : ''}>
                                    {`${exitByZapPersonalInjuryError ? 1 : 0} - Personal Injury Errors`}
                                </Col>
                            </Row>
                            <Row>
                                <Col className={stopLiveCircuitErrorOccurrences > 0 ? 'warning' : ''}>
                                    {`${stopLiveCircuitErrorOccurrences} - Live Circuit Errors`}
                                </Col>
                            </Row>
                            <Row>
                                <Col className={cautionSafeWorkplacePracticeOccurrences > 0 ? 'warning' : ''}>
                                    {`${cautionSafeWorkplacePracticeOccurrences} - Safe Work Practice Errors`}
                                </Col>
                            </Row>
                            {
                                numberUnnecessaryReplacedComponents !== undefined ? (
                                    <Row>
                                        <Col className={allowedReplacementPenaltyScore < 0 ? 'warning' : ''}>
                                            {`${numberUnnecessaryReplacedComponents} - Components Unnecessarily Replaced`}
                                        </Col>
                                    </Row>
                                ) : <></>
                            }
                            {
                                numberUnnecessaryRepairedComponents !== undefined ? (
                                    <Row>
                                        <Col className={allowedRepairsPenaltyScore < 0 ? 'warning' : ''}>
                                            {`${numberUnnecessaryRepairedComponents} - Unnecessary Repairs Made`}
                                        </Col>
                                    </Row>
                                ) : <></>
                            }
                            <Row>
                                <Col className={seatTimePenaltyScore < 0 ? 'warning' : ''}>
                                    {`${totalTimeMinutes} - Time Taken (minutes)`}
                                </Col>
                            </Row>
                            <Row>
                                <Col className={hintsTaken > 0 ? 'warning' : ''}>
                                    {`${hintsTaken} - Hints Taken`}
                                </Col>
                            </Row>
                            <Row>
                                <Col className={meterReadingsPenaltyScore < 0 ? 'warning' : ''}>
                                    {`${totalMeterReadings} - Meter Readings`}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={{ span: 4 }}>
                    <Row className="breakdown title">
                        <span>{'Expenditures:'}</span>
                    </Row>
                    <Row className="breakdown">
                        <Col>
                            <Row>
                                <Col>{`${totalTimeMinutes} minute${totalTimeMinutes === 1 ? '' : 's'} at $100/hr.`}</Col>
                                <Col>{`$${hourlyCost}`}</Col>
                            </Row>
                            {
                                componentsBreakdown
                            }
                            <Row className={'total'}>
                                <Col>{`TOTAL`}</Col>
                                <Col>{`$${(totalCost + hourlyCost).toFixed(2)}`}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

const getFaultAttemptDetails = (faultAttempt: FaultAttempt | FullFaultAttempt) => {

    const isPostAssessment = !('_id' in faultAttempt)

    let faultFixed: boolean = false,
        faultOperational: boolean,
        overallScore: number,
        skillRating: number,
        safetyRating: number,
        accuracyRating: number,
        efficiencyRating: number

    let totalTimeMinutes: number,
        totalCost: number,
        exitByZapPersonalInjuryError: boolean,
        stopLiveCircuitErrorOccurrences: number,
        cautionSafeWorkplacePracticeOccurrences: number,
        componentsReplaced: Component[],
        repairs: Component[],
        hintsTaken: number,
        totalMeterReadings: number

    let seatTimePenaltyScore: number,
        meterReadingsPenaltyScore: number,
        allowedReplacementPenaltyScore: number,
        allowedRepairsPenaltyScore: number

    // These values are not stored in the LRS and are calculated only at the time 
    // the fault is completed and shown on the Post Assessment screen    
    let numberUnnecessaryReplacedComponents: number | undefined = 0, // Set to zero to always show in Key Indicators list
        numberUnnecessaryRepairedComponents: number | undefined = 0  // Set to zero to always show in Key Indicators list

    if (isPostAssessment) {
        const fault: FaultAttempt = faultAttempt
        const metrics = fault.performanceSummary
        const efficiencyPenalties = fault.efficiencyPenalties
        const accuracyPenalties = fault.accuracyPenalties

        faultFixed = fault.faultFixed
        faultOperational = fault.faultOperational
        overallScore = fault.overallScore
        skillRating = fault.skillRating
        safetyRating = fault.safetyRating
        accuracyRating = fault.accuracyRating
        efficiencyRating = fault.efficiencyRating
        totalTimeMinutes = Math.ceil(metrics.totalTimeMinutes) // Includes time for Replaced Components.
        componentsReplaced = metrics.componentsReplaced
        totalCost = metrics.totalCost // Cost of replaced components. Does not include hourly cost.
        exitByZapPersonalInjuryError = metrics.exitByZapPersonalInjuryError
        stopLiveCircuitErrorOccurrences = metrics.stopLiveCircuitErrorOccurrences
        cautionSafeWorkplacePracticeOccurrences = metrics.cautionSafeWorkplacePracticeOccurrences
        hintsTaken = metrics.hintsTaken
        totalMeterReadings = metrics.totalMeterReadings // Includes total of all 3 types of meter readings.
        seatTimePenaltyScore = efficiencyPenalties.seatTimePenaltyScore
        meterReadingsPenaltyScore = efficiencyPenalties.meterReadingsPenaltyScore
        numberUnnecessaryReplacedComponents = accuracyPenalties.numberUnnecessaryReplacedComponents
        numberUnnecessaryRepairedComponents = accuracyPenalties.numberUnnecessaryRepairedComponents
        allowedReplacementPenaltyScore = accuracyPenalties.allowedReplacementPenaltyScore
        allowedRepairsPenaltyScore = accuracyPenalties.allowedRepairsPenaltyScore
    }
    else {
        const fault: FullFaultAttempt = faultAttempt
        const metrics = fault.performanceMetrics
        const scoring = fault.scoringResults
        const parValuesMetrics = fault.parValuesMetrics
        const totalReplaceTime = sumProp(metrics['replaced-components'], 'Time')
        const totalTime = Math.ceil(metrics['seat-time'] + totalReplaceTime)

        faultFixed = fault.faultState === FaultState.Repaired
        faultOperational = !metrics['exit-without-circuit-being-operational']
        overallScore = scoring['overall-score']
        skillRating = scoring['skill-rating']
        safetyRating = scoring['safety-rating']
        accuracyRating = scoring['accuracy-rating']
        efficiencyRating = scoring['efficiency-rating']
        totalTimeMinutes = totalTime
        componentsReplaced = metrics['replaced-components']
        repairs = metrics['repairs']
        totalCost = sumProp(componentsReplaced, 'Cost')
        exitByZapPersonalInjuryError = metrics['exit-by-zap-personal-injury-error']
        stopLiveCircuitErrorOccurrences = metrics['stop-live-circuit-error-occurrences']
        cautionSafeWorkplacePracticeOccurrences = metrics['caution-safe-workplace-practice-occurrences']
        hintsTaken = metrics['hints-taken']
        totalMeterReadings = metrics['ammeter-readings'] + metrics['ohmmeter-readings'] + metrics['voltmeter-readings']
        seatTimePenaltyScore = scoring['seat-time-penalty-score']
        meterReadingsPenaltyScore = scoring['meter-readings-penalty-score']
        allowedReplacementPenaltyScore = scoring['allowed-replacement-penalty-score']
        allowedRepairsPenaltyScore = scoring['allowed-repairs-penalty-score']

        if (parValuesMetrics && faultFixed && faultOperational) {
            numberUnnecessaryReplacedComponents = componentsReplaced
                .filter(replacedItem => !parValuesMetrics['allowed-replaced-components']
                    .some(allowed => replacedItem.Name === allowed)).length

            numberUnnecessaryRepairedComponents = repairs
                .filter(repairedItem => !parValuesMetrics['allowed-repairs']
                    .some(allowed => repairedItem.Name === allowed)).length

        }
    }

    const hourlyCost = Number((totalTimeMinutes * 100 / 60).toFixed(2))
    const faultSuccess = faultFixed && faultOperational
    const circuitLeftNotOperational = faultFixed && !faultOperational

    const faultAttemptDetails = {
        faultSuccess,
        circuitLeftNotOperational,
        hourlyCost,
        overallScore,
        skillRating,
        safetyRating,
        accuracyRating,
        efficiencyRating,
        totalTimeMinutes,
        totalCost,
        exitByZapPersonalInjuryError,
        stopLiveCircuitErrorOccurrences,
        cautionSafeWorkplacePracticeOccurrences,
        componentsReplaced,
        hintsTaken,
        totalMeterReadings,
        seatTimePenaltyScore,
        meterReadingsPenaltyScore,
        allowedReplacementPenaltyScore,
        allowedRepairsPenaltyScore,
        numberUnnecessaryReplacedComponents,
        numberUnnecessaryRepairedComponents
    }

    return faultAttemptDetails
}

export default FaultAttemptDetails

type ScoreRatingProps = {
    title: string
    value: number
}

const ScoreRating: FC<ScoreRatingProps> = ({ title, value }: ScoreRatingProps) => {
    return (
        <Row className="rating">
            <Col sm={{ span: 8 }}>
                {title}
            </Col>
            <Col sm={{ span: 4 }} className={'skill-rating'}>
                <div>{(value ?? 0) + '%'}</div>
            </Col>
        </Row>
    )
}

const ComponentExpenditure: FC<{ replacedItem: Component }> = ({ replacedItem }: { replacedItem: Component }) => {
    const { PartType, Name, Cost } = replacedItem
    return (
        <Row>
            <Col>{`1 - ${PartType} (${Name})`}</Col>
            <Col>{`$${(Cost).toFixed(2)}`}</Col>
        </Row>
    )
}