import { useState } from 'react'

enum StorageType {
    LocalStorage = 'localStorage',
    SessionStorage = 'sessionStorage'
}

export const storageAvailable = (type: StorageType): boolean => {
    try {
        const storage = window[type]
        const x = '__storage_test__'
        storage.setItem(x, x)
        storage.removeItem(x)
        return true
    }
    catch (e) {
        return false
    }
}

export const storeInSession = (key: string, value: string) => {
    if (!storageAvailable(StorageType.SessionStorage))
        throw new Error(`Failed to store (${key}, ${value}) in session storage. Error: Session storage is unavailable!`)

    window.sessionStorage.setItem(key, value)
}

export const getFromSession = (key: string): string => {
    if (!storageAvailable(StorageType.SessionStorage))
        throw new Error(`Failed to get session key '${key}'! Error: Session storage is unavailable!`)

    return window.sessionStorage.getItem(key) || ''
}

export const removeFromSession = (key: string) => {
    window.sessionStorage.removeItem(key)
}

export const useLocalStorage = <T extends unknown>(key: string, initialValue: T) => {
    const isServer = typeof window === 'undefined'

    const [storedValue, setStoredValue] = useState(() => {
        if (isServer) return initialValue

        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        }
        catch (error) {
            console.log(error)
            return initialValue
        }
    })

    const setValue = (value: T) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value
            setStoredValue(valueToStore)
            if (!isServer) {
                window.localStorage.setItem(key, JSON.stringify(valueToStore))
            }
        }
        catch (error) {

            console.log(error)
        }
    }

    return [storedValue, setValue]
}