import ApiClient from './common/core/api/ApiClient'
import { ApiConfiguration } from './common/core/api/types'

import ContentControllerService, { CCApiClient } from './common/core/cc/ContentControllerService'
import FaultManagerService, { FaultManagerApiClient } from './common/core/fault-manager/FaultManagerService'
import LRSService, { LRSApiClient } from './common/core/lrs/LRSService'
import ResourceLookupService, { RLUApiClient } from './common/core/rlu/ResourceLookupService'

import { XAPI_URL } from './configs'
import { ccLogger, fmLogger, lrsLogger, rluLogger } from './Loggers'

const apiConfig: ApiConfiguration = {
    baseURL: XAPI_URL
}
const XAPIClient = new ApiClient(apiConfig)

const lrsAPIClient = new LRSApiClient(XAPIClient, lrsLogger)
export const lrsService = new LRSService(lrsAPIClient)

const ccAPIClient = new CCApiClient(XAPIClient, ccLogger)
export const ccService = new ContentControllerService(ccAPIClient)

const faultManagerAPIClient = new FaultManagerApiClient(XAPIClient, fmLogger)
export const faultManagerService = new FaultManagerService(faultManagerAPIClient)

const rluAPIClient = new RLUApiClient(XAPIClient, rluLogger)
export const resourceLookupService = new ResourceLookupService(rluAPIClient)