import { useReducer, createContext, useContext, Dispatch, FC, ReactNode, useCallback } from 'react'

import { generalLogger as GeneralLogger } from '../Loggers'

import { FaultAttemptItem, ModuleFault } from '../common/types'
import {
    ScoreboardData, FaultMode, FaultAttempt,
    WorkOrder, CustomTestState, CustomTestFaultWithNumber,
    CustomTestFault
} from '../interfaces'

type State = {
    faultHistory: FaultAttemptItem[] | null
    scoreboard: ScoreboardData | null
    faultMode: FaultMode
    faultList: ModuleFault[] | null
    workOrder: WorkOrder | null
    faultAttempt: FaultAttempt | null
    isCustomTest: boolean
    customTestState?: CustomTestState | null
    selectedCustomTestFault?: CustomTestFaultWithNumber | CustomTestFault | null
}

const initialState: State = {
    faultHistory: null,
    scoreboard: null,
    faultMode: FaultMode.Explore,
    faultList: null,
    workOrder: null,
    faultAttempt: null,
    isCustomTest: false,
    selectedCustomTestFault: null,
}

enum ActionType {
    SAVE_FAULT_HISTORY,
    SAVE_SCOREBOARD,
    SET_FAULT_MODE,
    SAVE_FAULT_LIST,
    SAVE_CUSTOM_TEST_STATE,
    SAVE_WORK_ORDER,
    SAVE_FAULT_ATTEMPT,
    SET_IS_CUSTOM_TEST,
    SET_SELECTED_CUSTOM_TEST_FAULT,
    RESET
}

type Action =
    {
        type: ActionType.SAVE_FAULT_HISTORY
        payload: FaultAttemptItem[] | null
    } |
    {
        type: ActionType.SAVE_SCOREBOARD
        payload: ScoreboardData | null
    } |
    {
        type: ActionType.SET_FAULT_MODE
        payload: FaultMode
    } |
    {
        type: ActionType.SAVE_FAULT_LIST
        payload: ModuleFault[] | null
    } |
    {
        type: ActionType.SAVE_CUSTOM_TEST_STATE
        payload: CustomTestState
    } |
    {
        type: ActionType.SAVE_WORK_ORDER
        payload: WorkOrder | null
    } |
    {
        type: ActionType.SAVE_FAULT_ATTEMPT
        payload: FaultAttempt | null
    } |
    {
        type: ActionType.SET_IS_CUSTOM_TEST
        payload: boolean
    } |
    {
        type: ActionType.SET_SELECTED_CUSTOM_TEST_FAULT
        payload: CustomTestFaultWithNumber | CustomTestFault | null
    } |
    {
        type: ActionType.RESET
    }

const TroubleshootContext = createContext<{
    tsState: State
    dispatch: Dispatch<Action>
}>({ tsState: initialState, dispatch: () => null })

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.SAVE_FAULT_HISTORY:
            return { ...state, faultHistory: action.payload } as State
        case ActionType.SAVE_SCOREBOARD:
            return { ...state, scoreboard: action.payload } as State
        case ActionType.SET_FAULT_MODE:
            return { ...state, faultMode: action.payload } as State
        case ActionType.SAVE_FAULT_LIST:
            return { ...state, faultList: action.payload } as State
        case ActionType.SAVE_CUSTOM_TEST_STATE:
            return { ...state, customTestState: action.payload } as State
        case ActionType.SAVE_WORK_ORDER:
            GeneralLogger.log('Saving Work Order...')
            return { ...state, workOrder: action.payload } as State
        case ActionType.SAVE_FAULT_ATTEMPT:
            return { ...state, faultAttempt: action.payload } as State
        case ActionType.SET_IS_CUSTOM_TEST:
            return { ...state, isCustomTest: action.payload } as State
        case ActionType.SET_SELECTED_CUSTOM_TEST_FAULT:
            return { ...state, selectedCustomTestFault: action.payload } as State
        case ActionType.RESET:
            return {
                ...state,
                faultHistory: null,
                scoreboard: null,
                faultMode: FaultMode.Troubleshoot,
                faultAttempt: null,
                selectedCustomTestFault: null,
            }

        default:
            return state
    }
}

const TroubleshootProvider: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const contextValue = { tsState: state, dispatch }
    return (
        <TroubleshootContext.Provider value={contextValue}>
            {children}
        </TroubleshootContext.Provider>
    )
}

const useTroubleshootContext = () => {
    const context = useContext(TroubleshootContext)
    if (!context) {
        throw new Error(
            'useTroubleshoot must be used within a TroubleshootProvider. Wrap a parent component in <TroubleshootProvider> to fix this error.'
        )
    }
    const { tsState, dispatch } = context
    const actions = {
        saveFaultHistory: useCallback((faultHistory: FaultAttemptItem[] | null) =>
            dispatch({ type: ActionType.SAVE_FAULT_HISTORY, payload: faultHistory }), [dispatch]),
        saveScoreboard: useCallback((scoreboardValues: ScoreboardData | null) =>
            dispatch({ type: ActionType.SAVE_SCOREBOARD, payload: scoreboardValues }), [dispatch]),
        setFaultMode: useCallback((faultMode: FaultMode) =>
            dispatch({ type: ActionType.SET_FAULT_MODE, payload: faultMode }), [dispatch]),
        saveFaultList: useCallback((faultList: ModuleFault[] | null) =>
            dispatch({ type: ActionType.SAVE_FAULT_LIST, payload: faultList }), [dispatch]),
        saveCustomTestState: useCallback((customTestState: CustomTestState) =>
            dispatch({ type: ActionType.SAVE_CUSTOM_TEST_STATE, payload: customTestState }), [dispatch]),
        saveWorkOrder: useCallback((workOrder: WorkOrder) =>
            dispatch({ type: ActionType.SAVE_WORK_ORDER, payload: workOrder }), [dispatch]),
        clearWorkOrder: useCallback(() =>
            dispatch({ type: ActionType.SAVE_WORK_ORDER, payload: null }), [dispatch]),
        saveFaultAttempt: useCallback((faultAttempt: FaultAttempt | null) =>
            dispatch({ type: ActionType.SAVE_FAULT_ATTEMPT, payload: faultAttempt }), [dispatch]),
        setIsCustomTest: useCallback((isCustomTest: boolean) =>
            dispatch({ type: ActionType.SET_IS_CUSTOM_TEST, payload: isCustomTest }), [dispatch]),
        setSelectedCustomTestFault: useCallback((selectedCustomTestFault: CustomTestFaultWithNumber | CustomTestFault | null) =>
            dispatch({ type: ActionType.SET_SELECTED_CUSTOM_TEST_FAULT, payload: selectedCustomTestFault }), [dispatch]),
        resetTSContext: useCallback(() => dispatch({ type: ActionType.RESET }), [dispatch]),
    }

    return { tsState, tsActions: actions }
}

export default useTroubleshootContext
export { TroubleshootProvider }