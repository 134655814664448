import { useEffect, FC, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTroubleshootContext } from '../../contexts'
import { useCustomTestSettings, useSolver } from '../../hooks'
import { generalLogger as GeneralLogger } from '../../Loggers'

import { NavigationBar, LoadingOverlay } from '../'
import { FaultAttemptDetails } from '../troubleshoot'

import { SecondaryButton, ButtonGroup } from '../../styles/Buttons'

import './PostAssessment.scss'

const PostAssessment: FC = () => {
    const { tsState: { faultAttempt, isCustomTest } } = useTroubleshootContext()
    const { completeFault } = useSolver()
    const { showFaultAttempts } = useCustomTestSettings()

    const navigate = useNavigate()

    const faultCompleted = useRef(false)

    useEffect(() => {
        if (faultCompleted.current || faultAttempt) return
        GeneralLogger.log('Post assessment handling CompleteFault...')
        faultCompleted.current = true
        const doCompleteFault = async () => {
            await completeFault()
        }
        doCompleteFault()
    }, [completeFault, faultAttempt])

    useEffect(() => {
        if (faultAttempt && isCustomTest && !showFaultAttempts)
            navigate('/scoreboard', { replace: true })
    }, [faultAttempt, isCustomTest, navigate, showFaultAttempts])

    const gotoScoreboard = (event: any) => {
        event.preventDefault()
        navigate('/scoreboard')
    }

    if (isCustomTest && !showFaultAttempts) return null

    return (
        <div className="module-content">
            <NavigationBar />

            <LoadingOverlay
                active={!faultAttempt}
                text={isCustomTest && !showFaultAttempts ? `Loading your Overall Score` : `Loading your fault results`}
            />
            {
                faultAttempt && (
                    <>
                        <FaultAttemptDetails faultAttempt={faultAttempt} />
                        <ButtonGroup>
                            <SecondaryButton onClick={gotoScoreboard}>
                                {'OK'}
                            </SecondaryButton>
                        </ButtonGroup>
                    </>)
            }
        </div>
    )
}
export default PostAssessment