import { useEffect, useCallback, FC, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactHotkeys from 'react-hot-keys'

import { useLMSContext, useModuleContext, useTroubleshootContext } from '../../contexts'
import { useLMS, useToggle } from '../../hooks'
import { generalLogger as GeneralLogger } from '../../Loggers'

import { TS_Profile, ScoreboardData } from '../../interfaces'

import { NavigationBar, LoadingOverlay, Dialog } from '../'
import { ScoreboardView } from './'
import { ButtonGroup, PrimaryButton, SecondaryButton } from '../../styles/Buttons'

const Scoreboard: FC = () => {
    const { moduleState: { user, userProfile } } = useModuleContext()
    const { tsState: { scoreboard, isCustomTest }, tsActions: { saveScoreboard } } = useTroubleshootContext()
    const { lmsState: { isLMSAvailable } } = useLMSContext()
    const { concedeControl, setModulePassed } = useLMS()

    const navigate = useNavigate()
    const [showConfirm, toggleConfirm] = useToggle()
    const [exiting, toggleExiting] = useToggle()

    const canSelectFaults = !!user?.canSelectFaults

    const goToFaultSelector = useCallback(() => navigate('/faultselector'), [navigate])

    const markCustomTestComplete = useCallback((e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        toggleConfirm()
        if (!isLMSAvailable || !isCustomTest) return
        toggleExiting()
        const doComplete = async () => {
            await setModulePassed()
            concedeControl()
        }
        doComplete()
    }, [concedeControl, isCustomTest, isLMSAvailable, setModulePassed, toggleConfirm, toggleExiting])

    const onKeyUp = useCallback(() => {
        if (!canSelectFaults || isCustomTest) return
        GeneralLogger.log('Fault Selector hotkey triggered.')
        goToFaultSelector()
    }, [canSelectFaults, isCustomTest, goToFaultSelector])

    const handleTroubleshootClick = (event: MouseEvent) => {
        event.preventDefault()
        navigate('/workorder')
    }

    const goToFaultHistory = (event: MouseEvent) => {
        event.preventDefault()
        navigate('/faulthistory')
    }

    const getScoreboard = useCallback(() => {
        GeneralLogger.log(`Fetching Scoreboard values from userprofile`)
        if (!userProfile) throw new Error('Tried to get Scoreboard values without a user profile.')
        const scoreboardValues: ScoreboardData = getScoreboardValues(userProfile as TS_Profile)
        saveScoreboard(scoreboardValues)
    }, [userProfile, saveScoreboard])

    useEffect(() => {
        if (!userProfile || scoreboard) return
        getScoreboard()
    }, [getScoreboard, userProfile, scoreboard])

    if (exiting) {
        return (
            <div className="module-content">
                <LoadingOverlay active={exiting} text="Marking module as complete and exiting..." />
            </div>
        )
    }

    return (
        <div className="module-content">
            <NavigationBar />
            <LoadingOverlay
                active={!userProfile || !scoreboard}
                text={userProfile ? 'Loading your account' : 'Loading your fault results'}
            />
            {
                userProfile && scoreboard && (
                    <>
                        <ScoreboardView data={scoreboard} isCustomTest={isCustomTest} />
                        <div>
                            <ButtonGroup>
                                <PrimaryButton onClick={goToFaultHistory}>
                                    {'View History'}
                                </PrimaryButton>

                                <SecondaryButton onClick={isCustomTest ? goToFaultSelector : handleTroubleshootClick}>
                                    {`${isCustomTest ? 'Choose an Available Fault' : 'Troubleshoot'}`}
                                </SecondaryButton>
                            </ButtonGroup>
                            {
                                isCustomTest && isLMSAvailable && (
                                    <ButtonGroup>
                                        <PrimaryButton
                                            style={{ width: '100%', marginTop: '0', marginBottom: '0' }}
                                            onClick={toggleConfirm}
                                        >
                                            {'Mark Module as Complete'}
                                        </PrimaryButton>
                                    </ButtonGroup>
                                )
                            }
                            {
                                canSelectFaults && !isCustomTest && <ReactHotkeys keyName="ctrl+shift+f" onKeyUp={onKeyUp} />
                            }
                        </div>
                        <Dialog
                            title={'Mark this Module as Complete'}
                            visible={showConfirm}
                            onHide={toggleConfirm}
                            buttons={[
                                <SecondaryButton key={'cancelConfirm'} onClick={toggleConfirm}>{'Cancel'}</SecondaryButton>,
                                <PrimaryButton key={'confirmBtn'} onClick={markCustomTestComplete}>{'Mark Complete'}</PrimaryButton>
                            ]}
                        >
                            <p>
                                {`This module will be marked as complete and you will not be able to
                                reopen this module. Once marked as complete, your results will be
                                available for the curriculum administrator to view.`}
                            </p>
                            <p>
                                {`Are you sure you want to mark this module as complete?`}
                            </p>
                        </Dialog>
                    </>
                )
            }

        </div>
    )
}
export default Scoreboard

const getScoreboardValues = (userProfile: TS_Profile) => {

    const scoreboardValues: ScoreboardData = {
        overallScore: {
            name: 'Overall Score',
            description: 'The more you successfully solve faults, the higher your score will go.',
            value: userProfile['module-overall-score']
        },
        levelAchieved: {
            name: 'Achievements',
            description: 'Successfully complete five faults in a level with an average skill rating above the level target (60 - 80%) to move up to the next achievement level.',
            value: userProfile['module-level-achieved']
        },
        faultsAttempted: userProfile['module-number-faults-attempted'],
        faultsSolved: userProfile['module-number-faults-solved'],
        faultPercentage: Math.round(userProfile['module-percentage-faults-solved'] * 100),
        accuracyScore: {
            name: 'Accuracy',
            description: 'Achieve a high accuracy rating (100%) by replacing only defective parts and not more than necessary.',
            value: Math.round(userProfile['module-accuracy-score'] * 100)
        },
        efficiencyScore: {
            name: 'Efficiency',
            description: 'Achieve a high efficiency rating (up to 120%) by completing faults within the allocated time and following the Simutech Troubleshooting Approach.',
            value: Math.round(userProfile['module-efficiency-score'] * 100)
        },
        safetyScore: {
            name: 'Safety',
            description: 'Achieve a high safety rating (100%) by following safety procedures and avoiding safety errors and warnings.',
            value: Math.round(userProfile['module-safety-score'] * 100)
        },
        skillRating: {
            name: 'Skill Rating',
            description: 'Achieve a higher overall skill rating (up to 120%) by improving in the other three ratings.',
            value: Math.round(userProfile['module-skill-rating'] * 100)
        },
    }
    return scoreboardValues
}
