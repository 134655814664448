import { FC } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

import { WorkOrder } from '../../interfaces'

import { ExitMediaIcon } from '../../assets/icons'
import { SimutechBlue, SimutechOrangeActive } from '../../styles/Theme'
import workorderimg from '../../assets/images/work-order.png'
import workordergrime from '../../assets/images/work-order-grime.png'

type Props = {
    workOrder: WorkOrder | null
    onWorkOrderClose?: () => void
}

const WorkOrderSheet: FC<Props> = ({ workOrder, onWorkOrderClose }: Props) => {

    return (
        <>
            <Wrapper className={`${onWorkOrderClose ? 'asReference' : ''}`} fluid={true}>
                {
                    onWorkOrderClose
                        ? (
                            <ExitButton onClick={() => onWorkOrderClose && onWorkOrderClose()}>
                                <ExitMediaIcon />
                            </ExitButton>
                        )
                        : <></>
                }
                <Paper>
                    <Inner>
                        <Info>
                            <Details sm={{ span: 5 }}>
                                <DetailRow>
                                    <Col sm={{ span: 7 }}>
                                        {'Work Order #:'}
                                    </Col>
                                    <Col sm={{ span: 5 }}>
                                        <WorkOrderField>
                                            {workOrder?.OrderNumber || <Skeleton />}
                                        </WorkOrderField>

                                    </Col>
                                </DetailRow>
                                <DetailRow>
                                    <Col sm={{ span: 7 }}>
                                        {'Date:'}
                                    </Col>
                                    <Col sm={{ span: 5 }}>
                                        <WorkOrderField>
                                            {workOrder?.Date || <Skeleton />}
                                        </WorkOrderField>
                                    </Col>
                                </DetailRow>
                                <DetailRow>
                                    <Col sm={{ span: 7 }}>
                                        {'Status of Repair:'}
                                    </Col>
                                    <Col sm={{ span: 5 }}>
                                        <WorkOrderField>
                                            {workOrder?.StatusOfRepair || <Skeleton />}
                                        </WorkOrderField>

                                    </Col>
                                </DetailRow>
                                <LocationBox>
                                    <Col>
                                        <WorkOrderField style={{ height: '100%' }}>
                                            <div><strong>{'Location:'}</strong></div>
                                            <div>{'ACE Growers'}</div>
                                            <div>{'114 Port Street'}</div>
                                            <div>{'Torosonto ON N2F 3PO'}</div>
                                        </WorkOrderField>
                                    </Col>
                                </LocationBox>
                            </Details>
                            <Col sm={{ span: 7 }}>
                                <WorkOrderField style={{ height: '100%' }}>
                                    <div><strong>{'Reported Problem:'}</strong></div>
                                    <div>{workOrder?.ReportedProblem || <Skeleton count={7} />}</div>
                                </WorkOrderField>

                            </Col>
                        </Info>
                        <Comments>
                            <Col sm={{ span: 12 }}>
                                <WorkOrderField style={{ height: '100%' }}>
                                    <div><strong>{'Comments'}</strong></div>
                                    <div>{workOrder?.Comment === undefined ? <Skeleton count={4} /> : workOrder.Comment}</div>
                                </WorkOrderField>
                            </Col>
                        </Comments>
                    </Inner>
                </Paper>
            </Wrapper>

        </>
    )
}
export default WorkOrderSheet



const Info = styled(Row)`
    flex: 3;
`

const Details = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const DetailRow = styled(Row)`
    flex: 1;
`

const LocationBox = styled(Row)`
    flex:3;
`

const Paper = styled(Row)`
    width: 90%;
    flex: 3;
    margin-top: 6.5em;
    margin-bottom: 4em;
    max-width: 64em;
    min-width: 64em;
    .col {
        display: flex;
        flex-direction: column;
        height: 90%;
    }
`
const Inner = styled(Col)`
    padding: 0 5em;
`
const Wrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 37.5em;
    height: 100%;
    max-width: 64em;
    max-height: 37.5em;
    min-width: 64em;
    background: url(${workorderimg}) no-repeat;
    background-size: 100% 100%;
    align-items: center;
    user-select: none;
    margin-top: 5vh;

    &:after {
        content: ' ';
        position: absolute;
        height: 100%;
        width: 100%;
        background: url(${workordergrime}) no-repeat;
        background-size: 100% 100%;
    }

    &.asReference {        
        margin: 0;
        height: 100vh;
        padding: 0;
        &:hover {
            cursor: grab;
        }

        &:active {
            cursor: grabbing;
        }
    }

    div {
        color: ${SimutechBlue};
    }

    div[class*='col'] {
        height: 90%;
    }

    .row {
        align-items: center;
        justify-content: center;
    }
    
`

const Comments = styled(Row)`
    flex: 2;
`

const WorkOrderField = styled.div`
    border: 1px solid ${SimutechBlue};
    margin: 0 0.125em;
    padding: 0.063em 0.188em;
    width: 100%;    
    background-color: white;
    overflow: hidden;
`

const ExitButton = styled.div`
    border: 1px solid rgba(75, 75, 75, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    box-shadow: 0px 2px 5px rgba(75, 75, 75, 0.75);
    width: 2.188em;
    height: 2.188em;
    top: -1em;
    right: -1em;
    z-index: 2;

    svg {
        height: 100%;
        width: 50%;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 10px rgba(75, 75, 75, 0.75);
        .svg-exit-media-btn {
            stroke: ${SimutechOrangeActive};
        }
    }
`