import { useCallback, useEffect, useRef, FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { isDebug, lmsRequired, simMod } from './configs'
import { useModuleContext, useLMSContext, useTroubleshootContext } from './contexts'

import {
    generalLogger as GeneralLogger,
    lmsLogger as LMSLogger,
    lrsLogger as LRSLogger,
} from './Loggers'
import { useLearningLab, useLMS, useLRS, useLMSInfo } from './hooks'
import { getUser } from './User'
import { Simulation, Dialog, ErrorBox, } from './components'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import './styles/index.scss'


const App: FC = () => {
    const {
        moduleState: {
            user,
            userProfile,
            criticalErrorMessage,
            moduleContent,
            errorDialog,
        },
        moduleActions: {
            setUser,
            setErrorDialog,
            setCriticalErrorMessage,
        }
    } = useModuleContext()
    const { tsState: { isCustomTest }, tsActions: { setIsCustomTest } } = useTroubleshootContext()
    const { lmsState: { isLMSAvailable } } = useLMSContext()

    const { updateLRSModuleScore } = useLRS()
    const { setModulePassed, setScore, getScore, isCourseComplete } = useLMS()
    const { lmsInfo, validateCourseConfig } = useLMSInfo()

    const { calculateScore, getLRSState, LRSState } = useLearningLab()

    const handleCriticalError = useCallback((error: string) => {
        const errorMsg = `An error has occurred.  Please contact your Learning Management System administrator. ${isDebug ? error : ''}`
        GeneralLogger.fatal(`${errorMsg} ${error}`)
        setCriticalErrorMessage(errorMsg)
    }, [setCriticalErrorMessage])

    const userFetching = useRef(false)

    useEffect(() => {
        if (userFetching.current) return
        const timer = setTimeout(() => {
            if (!lmsInfo) {
                userFetching.current = true
                if (lmsRequired) {
                    handleCriticalError('LMS could not be found to retrieve user.')
                    return
                }
                const setDebugUser = async () => {
                    const debugUser = await getUser()
                    validateCourseConfig(debugUser)
                    const isCustomTest = !!debugUser.customTestId
                    isDebug && GeneralLogger.info('isCustomTest', isCustomTest)
                    setIsCustomTest(isCustomTest)
                    setUser(debugUser)
                }
                setDebugUser()
                    .catch(error => {
                        clearTimeout(timer)
                        handleCriticalError(error.toString())
                    })
                clearTimeout(timer)
            }
        }, process.env.NODE_ENV === 'development' ? 250 : 3500)
        return () => { clearTimeout(timer) }
    }, [lmsInfo, handleCriticalError, setUser, setIsCustomTest, validateCourseConfig])

    useEffect(() => {
        if (!lmsInfo || user || criticalErrorMessage || userFetching.current) return

        userFetching.current = true
        const setUserFromCC = async () => {
            validateCourseConfig() // Needs to be done before getUser()
            const user = await getUser(lmsInfo)
            const isCustomTest = !!user.customTestId
            setIsCustomTest(isCustomTest)
            setUser(user)
        }
        setUserFromCC()
            .catch(error => handleCriticalError(error.toString()))
    }, [criticalErrorMessage, lmsInfo, handleCriticalError, setUser, user, setIsCustomTest, validateCourseConfig])

    //--> Learning Lab score and module completion handling
    const shouldRecalculateScore = useRef(simMod.isLab)

    useEffect(() => {
        // This should only run once in case content has changed
        if (!shouldRecalculateScore.current || criticalErrorMessage || !userProfile) return
        shouldRecalculateScore.current = false

        // Let's recalculate the score based on the user's LRS progress...
        const recalculatedScore = calculateScore(userProfile)

        // Let's make sure the LMS has the same score as the recalculated score...
        const syncScoreToLMS = async () => {
            if (!isLMSAvailable) return

            const currentLMSScore = await getScore()
            if (currentLMSScore === recalculatedScore) return
            isDebug && LMSLogger.warn(`LMS Score is out of sync. It is recorded as ${currentLMSScore}, but the LRS profile progress says it should be ${recalculatedScore}.`)
            isDebug && LMSLogger.info(`Saving recalculated score of ${recalculatedScore} to LMS...`)

            await setScore(recalculatedScore)
        }
        syncScoreToLMS()

        // Now, let's make sure the user's LRS Profile score matches with their actual progress...
        const recordedLRSScore = userProfile['module-score']

        if (recordedLRSScore === recalculatedScore) return // It matches, let's get out of here.
        isDebug && LRSLogger.warn(`LRS User Profile Module Score is out of sync with the student's actual course progress.`)
        isDebug && LRSLogger.warn(`It is recorded as ${recordedLRSScore}, but the student's progress says it should be ${recalculatedScore}.`)
        isDebug && LRSLogger.info(`Updating LRS Module Profile Score with recalculated score and saving new profile to state...`)

        updateLRSModuleScore(recalculatedScore)
            .catch(e => LRSLogger.error(e))

    }, [calculateScore, criticalErrorMessage, getScore, isLMSAvailable, setScore, updateLRSModuleScore, userProfile])

    useEffect(() => {
        // Completion handling
        if (!userProfile || simMod.isTS || !moduleContent.sections.length) return
        isDebug && LRSLogger.info(`Checking if module is complete...`)
        // Iterate through all the lessons in the module and check if the user has completed all of them to set the module as complete
        const allLessonsCompleted = moduleContent.sections.every((section, sectionIndex) =>
            section.lessons.every((lesson, lessonIndex) => getLRSState(sectionIndex, lessonIndex) === LRSState.Completed))

        if (!allLessonsCompleted) return

        isDebug && LRSLogger.info('All lessons completed...')
        isLMSAvailable && setModulePassed()

    }, [LRSState.Completed, getLRSState, isLMSAvailable, moduleContent.sections, setModulePassed, userProfile])

    //--> End of Learning Lab score and module completion handling.

    const customTestCompleteChecked = useRef(false)
    useEffect(() => {
        if (!isCustomTest || !isLMSAvailable || !user || customTestCompleteChecked.current) return
        customTestCompleteChecked.current = true

        const doCheck = async () => {
            const isCustomTestComplete = await isCourseComplete()
            if (isCustomTestComplete) setCriticalErrorMessage('This course has already been completed.')
        }
        doCheck()

    }, [isCourseComplete, isCustomTest, isLMSAvailable, setCriticalErrorMessage, user])

    if (criticalErrorMessage) return <ErrorBox message={criticalErrorMessage} />

    return (
        <>
            <Helmet>
                <title>{moduleContent.title}</title>
                <base href="/" />
            </Helmet>

            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<Simulation />} />
                </Routes>
            </BrowserRouter>

            <Dialog
                visible={!!errorDialog}
                title={errorDialog ? errorDialog.title : ''}
                onHide={errorDialog ? errorDialog.onHide : () => { setErrorDialog(null) }}
                buttons={errorDialog && errorDialog.buttons}
            >
                {errorDialog && errorDialog.body}
            </Dialog>
        </>
    )
}

export default App