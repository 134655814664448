import consola, { } from 'consola'
import { default as Options, DEFAULTS } from './configs/LoggerOptions'

// Service Loggers
export const ccLogger = consola.create(Options.CC).withTag('Content Controller').withDefaults(DEFAULTS)
export const rluLogger = consola.create(Options.RLU).withTag('Resource Lookup Service').withDefaults(DEFAULTS)
export const lrsLogger = consola.create(Options.LRS).withTag('Learning Record Service').withDefaults(DEFAULTS)
export const fmLogger = consola.create(Options.FaultManager).withTag('Fault Manager (RFG)').withDefaults(DEFAULTS)

export const lmsLogger = consola.create(Options.LMS).withTag('Learning Management System').withDefaults(DEFAULTS)

export const generalLogger = consola.create(Options.General).withTag('General').withDefaults(DEFAULTS)
export const solverLogger = consola.create(Options.Solver).withTag('Solver').withDefaults(DEFAULTS)
export const unityLogger = consola.create(Options.Unity).withTag('Unity').withDefaults(DEFAULTS)



// ccLogger.fatal('CC logger fatal test.')
// ccLogger.error('CC logger error test.')
// ccLogger.warn('CC logger warn test.')
// ccLogger.log('CC logger log test.')
// ccLogger.info('CC logger info test.')
// ccLogger.success('CC logger initialized')
// ccLogger.debug('CC logger debug test.')
// ccLogger.trace('CC logger trace test.')