import { Consola } from 'consola'

import { IApiClient } from '../api/ApiClient'
import { EndPoints, CC_PATH } from './constants'
import { sanitizePath } from '../../utils'

import { LMSLessonStatus, LMSUser } from '../../types'
import {
    CourseStatus, ICCApiClient, LMSDataDTO,
    LMSDataKey, Score, SuspendData,
} from './types'

export class CCApiClient implements ICCApiClient {
    private apiBase: string
    private ccApiClient: IApiClient
    logger: Consola

    constructor(apiClient: IApiClient, logger: Consola) {
        this.apiBase = sanitizePath(CC_PATH)
        this.ccApiClient = apiClient
        this.logger = logger
    }

    fetchStudentInfo = async (lmsSessionId: string): Promise<LMSUser> => {
        try {
            const lmsUser = await this.ccApiClient.get<LMSUser>(
                `${this.apiBase}${EndPoints.GetStudentInfo}?sid=${lmsSessionId}`
            )
            this.logger.success('LMS User:\n', lmsUser)
            return lmsUser
        }
        catch (exception) {
            this.logger.fatal(exception)
            throw new Error(`Error fetching student info:\n ${exception}`)
        }
    }

    private fetchLMSData = async <T extends Score | SuspendData | CourseStatus>(lmsSessionId: string, courseTrackerId: number, key: LMSDataKey): Promise<LMSDataDTO<T>> => {
        try {
            const lmsData = await this.ccApiClient.get<LMSDataDTO<T>>(
                `${this.apiBase}${EndPoints.GetLMSData}?sid=${lmsSessionId}&courseTrackerId=${courseTrackerId.toString()}&key=${key}`
            )
            this.logger.success('fetchLMSData: RESPONSE\n', lmsData)
            return lmsData
        }
        catch (exception) {
            this.logger.error(exception)
            throw new Error(`Error fetching LMS data:\n ${exception}`)
        }
    }

    private setLMSData = async <T>(lmsSessionId: string, courseTrackerId: number, lmsData: LMSDataDTO<T>) => {
        this.logger.info('setLMSData:', lmsData)
        try {
            const response = await this.ccApiClient.put(
                `${this.apiBase}${EndPoints.SetLMSData}/${lmsSessionId}/${courseTrackerId.toString()}`,
                lmsData,
            )
            this.logger.success('setLMSData: RESPONSE\n', response)
        }
        catch (exception) {
            this.logger.error(exception)
            throw new Error(`Error setting LMS data: ${exception}`)
        }
    }

    getLMSSuspendedScore = async (lmsSessionId: string, courseTrackerId: number): Promise<number> => {
        try {

            const respData = await this.fetchLMSData<SuspendData>(lmsSessionId, courseTrackerId, LMSDataKey.SuspendData)
            this.logger.info('getLMSSuspendData:', respData)

            if (respData.data.data === '')
                return 0

            const suspendedScore = Number(respData.data.data)

            if (isNaN(suspendedScore))
                throw new Error(`Suspended Score (${suspendedScore}) is not a number.`)

            return suspendedScore
        }
        catch (exception) {
            this.logger.error(exception)
            throw new Error(`Error getting suspended score:\n ${exception}`)
        }
    }

    getLMSCourseStatus = async (lmsSessionId: string, courseTrackerId: number): Promise<string> => {
        try {
            const respData = await this.fetchLMSData<CourseStatus>(lmsSessionId, courseTrackerId, LMSDataKey.CourseStatus)
            this.logger.success('getLMSCourseStatus:\n', respData)
            return respData.data.status
        }
        catch (exception) {
            this.logger.info(exception)
            throw new Error(`Error getting course status:\n ${exception}`)
        }
    }

    setLMSScore = (lmsSessionId: string, courseTrackerId: number, score: number) => {
        try {
            this.setLMSData<number>(lmsSessionId, courseTrackerId, {
                dataRequested: LMSDataKey.Score,
                data: score
            })
        }
        catch (exception) {
            this.logger.error(exception)
            throw new Error(`Error setting score: ${exception}`)
        }
    }

    setLMSSuspendData = async (lmsSessionId: string, courseTrackerId: number, data: string) => {
        try {
            this.setLMSData<string>(lmsSessionId, courseTrackerId, {
                dataRequested: LMSDataKey.SuspendData,
                data
            })
        }
        catch (exception) {
            this.logger.error(exception)
            throw new Error(`Error setting suspend data: ${exception}`)
        }
    }

    setLMSCourseCompleted = async (lmsSessionId: string, courseTrackerId: number) => {
        try {
            this.setLMSData<string>(lmsSessionId, courseTrackerId, {
                dataRequested: LMSDataKey.CourseStatus,
                data: LMSLessonStatus.Completed
            })
        }
        catch (exception) {
            this.logger.error(exception)
            throw new Error(`Error setting course status:\n ${exception}`)
        }
    }
    sendConcedeControl = async (lmsSessionId: string, courseTrackerId: number) => {
        const response = this.ccApiClient.put(
            `${this.apiBase}${EndPoints.ConcedeControl}/${lmsSessionId}/${courseTrackerId.toString()}`
        )
        this.logger.info('ConcedeControl response:\n', response)
    }
}

export default class ContentControllerService {
    private ccApiClient: ICCApiClient
    logger: Consola

    constructor(apiClient: ICCApiClient) {
        this.ccApiClient = apiClient
        this.logger = apiClient.logger
    }

    Get = {
        LMSUser: async (lmsSessionId: string): Promise<LMSUser> =>
            this.ccApiClient.fetchStudentInfo(lmsSessionId),
        LMSSuspendedScore: async (lmsSessionId: string, courseTrackerId: number): Promise<number> =>
            this.ccApiClient.getLMSSuspendedScore(lmsSessionId, courseTrackerId),
        LMSCourseStatus: async (lmsSessionId: string, courseTrackerId: number): Promise<string> =>
            this.ccApiClient.getLMSCourseStatus(lmsSessionId, courseTrackerId)
    }

    Set = {
        LMSScore: (lmsSessionId: string, courseTrackerId: number, score: number) =>
            this.ccApiClient.setLMSScore(lmsSessionId, courseTrackerId, score),
        LMSSuspendData: async (lmsSessionId: string, courseTrackerId: number, data: string) =>
            this.ccApiClient.setLMSSuspendData(lmsSessionId, courseTrackerId, data),
        LMSCourseCompleted: async (lmsSessionId: string, courseTrackerId: number) =>
            this.ccApiClient.setLMSCourseCompleted(lmsSessionId, courseTrackerId)
    }
    concedeControl = async (lmsSessionId: string, courseTrackerId: number) =>
        this.ccApiClient.sendConcedeControl(lmsSessionId, courseTrackerId)
}