import { FC } from 'react'
import { Col, Row, Container } from 'react-bootstrap'

import { MasteryLevel, ScoreboardData, masteryLevels, ScoreboardDataPoint } from '../../interfaces'

import { Tooltip } from '../../components'

import { StarIcon } from '../../styles/Icons'
import './ScoreboardView.scss'

interface Props {
    data: ScoreboardData
    isCustomTest: boolean
}

const ScoreboardView: FC<Props> = ({
    data: {
        levelAchieved,
        overallScore,
        faultsAttempted,
        faultsSolved,
        faultPercentage,
        safetyScore,
        accuracyScore,
        efficiencyScore,
        skillRating
    },
    isCustomTest
}: Props) => (
    <Container fluid={true}>
        <Tooltip />
        <div className="pre-fault">
            <Row>
                <Col>
                    <div className={'title'}>
                        {isCustomTest ? `Overall Score` : `Your Scoreboard`}
                    </div>
                </Col>
            </Row>
            {
                !isCustomTest && <OverallScore overallScore={overallScore} />
            }

            <Row className={'userOverview'}>
                {
                    !isCustomTest && <Achievements levelAchieved={levelAchieved} />
                }
                <UserStats
                    faultsAttempted={faultsAttempted}
                    faultsSolved={faultsSolved}
                    faultPercentage={faultPercentage}
                    isCustomTest={isCustomTest}
                />
            </Row>
            <Row className={'trends'}>
                <Rating dataPoint={safetyScore} />
                <Rating dataPoint={accuracyScore} />
                <Rating dataPoint={efficiencyScore} />
                <Rating dataPoint={skillRating} />
            </Row>
        </div>
    </Container>
)
export default ScoreboardView

type MasteryStarProps = {
    levelAchieved: number
    masteryLevel: MasteryLevel
}

const MasteryStar = ({ levelAchieved, masteryLevel }: MasteryStarProps) => (
    <Col className={levelAchieved >= masteryLevel.level ? 'reached' : 'notYetReached'}>
        <Row>
            <StarIcon size="5x" />
        </Row>
        <Row className={'level'}>
            {masteryLevel.name}
        </Row>
    </Col>
)

const MasteryStars = ({ levelAchieved: { description, value } }: { levelAchieved: ScoreboardDataPoint }) => (
    <Row
        data-place={'right'}
        data-tip={description}
        className={'levels'}
    >
        {
            masteryLevels.map((level: MasteryLevel, key: number) => (
                <MasteryStar
                    levelAchieved={value}
                    masteryLevel={level}
                    key={'ml' + key}
                />
            ))
        }
    </Row>
)

const OverallScore = ({ overallScore: { description, value } }: { overallScore: ScoreboardDataPoint }) => (
    <Row className="overallScore">
        <Col>
            <div data-place={'bottom'} data-tip={description}>
                {value}
            </div>
        </Col>
    </Row>
)


const Achievements = ({ levelAchieved }: { levelAchieved: ScoreboardDataPoint }) => (
    <Col className={'userLevel'}>
        <Row className={'userTitle'}>
            {'Achievements'}
        </Row>
        <MasteryStars levelAchieved={levelAchieved} />
    </Col>
)


type UserStatsProps = {
    faultsAttempted: number
    faultsSolved: number
    faultPercentage: number
    isCustomTest: boolean
}

const UserStats = ({ faultsAttempted, faultsSolved, faultPercentage, isCustomTest }: UserStatsProps) => (
    <Col className={`userStats ${isCustomTest ? 'customTest' : ''}`}>
        <UserStat name={'Faults Attempted'} value={`${faultsAttempted}`} />
        <UserStat name={'Faults Solved'} value={`${faultsSolved}`} />
        <UserStat name={'Percentage Solved'} value={`${faultPercentage}%`} />
    </Col>
)

const UserStat = ({ name, value }: { name: string; value: string }) => (
    <Row className={'userStat'}>
        <Col className={'statTitle'}>
            {name}
        </Col>
        <Col className={'stat'}>
            {value}
        </Col>
    </Row>
)


const Rating = ({ dataPoint: { name, description, value } }: { dataPoint: ScoreboardDataPoint }) => (
    <Col
        className={'trendBox'}
        data-tip={description}
    >
        <Row className={'trendType'}>
            {name}
        </Row>
        <Row className={'innerTrendBox'}>
            <Col>
                <Row className={'trendTitle'}>
                    {'Overall'}
                </Row>
                <Row className={'trendPercentage'}>
                    {value + '%'}
                </Row>
            </Col>
            {/*<Col>*/}
            {/*    <Row className={"trendTitle"}>*/}
            {/*        {'Trend'}*/}
            {/*    </Row>*/}
            {/*    <Row>*/}
            {/*        <FontAwesomeIcon icon={faMinus} size="2x"/>*/}
            {/*    </Row>*/}
            {/*</Col>*/}
        </Row>
    </Col>
)
