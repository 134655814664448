import { useCallback } from 'react'

import { simMod } from '../configs'
import { useLMSContext, useModuleContext } from '../contexts'
import { ccService as ContentController } from '../Services'

import { lmsLogger as LMSLogger } from '../Loggers'

import { Section } from '../interfaces'
import { LMSLessonStatus } from '../common/types'

const useLMS = () => {
    const { moduleState: { user } } = useModuleContext()
    const { lmsState: { courseCompleted }, lmsActions: { setCourseCompleted } } = useLMSContext()
    const getStatus = useCallback(async () => {
        if (!user) throw new Error('Tried to get LMS status without a user.')

        return await ContentController.Get.LMSCourseStatus(user.lmsSessionId, user.courseTrackerId)
    }, [user])

    const getScore = useCallback(async (): Promise<number> => {
        if (!user) throw new Error('Tried to get LMS score without a user.')
        // const score = await getLMSScore(lmsSessionId)
        // LMSLogger.info('SCORE:', score)
        const data = await ContentController.Get.LMSSuspendedScore(user.lmsSessionId, user.courseTrackerId)
        return data
    }, [user])

    const setScore = useCallback(async (score: number) => {
        if (!user) throw new Error('Tried to set LMS score without a user.')
        const { lmsSessionId, courseTrackerId } = user
        await ContentController.Set.LMSScore(lmsSessionId, courseTrackerId, score)
        await ContentController.Set.LMSSuspendData(lmsSessionId, courseTrackerId, score.toString())
    }, [user])

    const isCourseComplete = useCallback(async (): Promise<boolean> => {
        const status = await getStatus()
        return status === LMSLessonStatus.Passed
            || Number(status) === 1
            || status.toLowerCase() === 'passed'
            || status === LMSLessonStatus.Completed
            || Number(status) === 2
            || status.toLowerCase() === 'completed'
    }, [getStatus])

    const setModulePassed = useCallback(async () => {
        if (!user) throw new Error('Tried to set LMS module as complete without a user.')

        const currentLMSStatus = await isCourseComplete()
        if (currentLMSStatus) {
            !courseCompleted && setCourseCompleted(true)
            return
        }

        // If not already Passed
        LMSLogger.info(`Setting LMS Lesson status to 'passed'.`)
        await ContentController.Set.LMSCourseCompleted(user.lmsSessionId, user.courseTrackerId)
        setCourseCompleted(true)
        if (simMod.isLab) await setScore(100)
        const newStatus = await getStatus()
        const score = await getScore()
        LMSLogger.info(`Your lesson status is:${newStatus}`)
        LMSLogger.info(`Your score is: ${score}`)
    }, [courseCompleted, getScore, getStatus, isCourseComplete, setCourseCompleted, setScore, user])

    const concedeControl = useCallback(() => {
        if (!user) return
        LMSLogger.info(`Goodbye ${user.userName}!`)
        ContentController.concedeControl(user.lmsSessionId, user.courseTrackerId)
    }, [user])

    // Learning Labs

    const getScoreIncrement = useCallback((sections: Section[]): number => {
        let count = 0
        if (!sections.length) return count

        sections.forEach((section: Section) => {
            count += section.lessons.length
        })
        return 100 / count
    }, [])

    const incrementScore = useCallback(async (scoreIncrement: number) => {
        try {
            const currentScore = await getScore()
            LMSLogger.info(`Updating score. Previous score:${currentScore}`)
            const newScore: number = currentScore + scoreIncrement

            if (newScore <= 0)
                throw new Error(`Score must be greater than zero. ${newScore}`)

            await setScore(Math.min(newScore, 100))
        } catch (error) {
            LMSLogger.error(error)
        }
    }, [getScore, setScore])

    return {
        getScore,
        setScore,
        getStatus,
        concedeControl,
        setModulePassed,
        isCourseComplete,
        getScoreIncrement,
        incrementScore
    }
}

export default useLMS