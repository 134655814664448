import { Consola } from 'consola'

import { IApiClient } from '../api/ApiClient'

import { EndPoints, RLU_PATH } from './constants'
import { sanitizePath } from '../../utils'
import { PartSpecsRequest } from '../../types'
import { IRLUApiClient } from './types'

export class RLUApiClient implements IRLUApiClient {
    private apiBase: string
    private rluApiClient: IApiClient
    logger: Consola

    constructor(rluApiClient: IApiClient, logger: Consola) {
        this.apiBase = sanitizePath(RLU_PATH)
        this.rluApiClient = rluApiClient
        this.logger = logger
    }

    getPartSpecsImageURL = async (partSpecsRequest: PartSpecsRequest) => {
        this.logger.info('Retrieving Part Specs image...\n', partSpecsRequest)
        try {

            const blob = await this.rluApiClient.get<Blob>(
                `${this.apiBase}${EndPoints.GetPartSpecs}?partData=${JSON.stringify(partSpecsRequest)}`,
                {
                    responseType: 'blob',
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            this.logger.success('Part Specs image retrieved.\n', blob)
            return URL.createObjectURL(new Blob([blob]))
        }
        catch (exception) {
            this.logger.error(exception)
            throw new Error(`Error retrieving part specs image:\n ${exception}`)
        }
    }
}

export default class ResourceLookupService {
    private rluApiClient: IRLUApiClient
    logger: Consola

    constructor(apiClient: IRLUApiClient) {
        this.rluApiClient = apiClient
        this.logger = apiClient.logger
    }

    getPartSpecsImageURL = async (partSpecsRequest: PartSpecsRequest) =>
        this.rluApiClient.getPartSpecsImageURL(partSpecsRequest)
}