import { FC, MouseEvent } from 'react'

import { simMod } from '../configs'
import { useModuleContext, useTroubleshootContext } from '../contexts'

import { ElectricalCode } from '../common/types'
import { ResourceImage } from '../interfaces'

import { Menu, MenuItem } from './'


type Props = {
    visible: boolean
    hideMenu: () => void
    showDiagram: (diagram: ResourceImage) => void
    showWorkOrder?: () => void
}

const FolderMenu: FC<Props> = ({ visible, hideMenu, showDiagram, showWorkOrder }: Props) => {

    const { moduleState: { user } } = useModuleContext()
    const { tsState: { workOrder } } = useTroubleshootContext()
    if (!user) return null
    if (simMod.diagrams.length === 0) throw new Error('This module has no diagrams configured.')

    const handleDiagramItemClick = (e: MouseEvent<HTMLElement>, diagram: ResourceImage) => {
        e.preventDefault()
        hideMenu()
        showDiagram(diagram)
    }
    const handleWorkOrderItemClick = (e: MouseEvent) => {
        e.preventDefault()
        hideMenu()
        showWorkOrder && showWorkOrder()
    }

    const diagramItems = simMod.diagrams.map((diagram: ResourceImage, idx: number) => (
        <MenuItem
            key={'d' + idx}
            text={
                diagram.nameIEC && user.electricalCode === ElectricalCode.NEC
                    ? diagram.nameIEC
                    : diagram.name
            }
            onClick={(e) => handleDiagramItemClick(e, diagram)}
        />
    ))

    const workOrderItem = workOrder && showWorkOrder ? (
        <MenuItem
            text={`Work Order`}
            onClick={handleWorkOrderItemClick}
        />
    ) : <></>

    return (
        <Menu
            title={'References'}
            visible={visible}
            onClickOutside={hideMenu}
        >
            {workOrderItem}
            {diagramItems}
        </Menu>
    )
}
export default FolderMenu

