import platform from 'platform'

import { SessionInfo } from '../types'

export const isEmpty = function(obj: Record<string, any>): boolean {
    if (Object.entries(obj).length === 0 && obj.constructor === Object) {
        return true
    }
    return false
}

export const sumProp = (array: any[], profileProperty: string): number => array.reduce((prev, cur) => prev + cur[`${profileProperty}`], 0)
export const isString = (value: unknown) => typeof value === 'string' || value instanceof String
export const toBoolean = (value: string | number | boolean | undefined | null): boolean =>
    (value === undefined || value === null) ? false : [true, 'true', '1', 1].includes(typeof value === 'string' ? value.toLowerCase() : value)

export const openInNewTab = (href: string) => window.open(href, '_blank')
export const simulateNetworkRequest = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds))

export const getUrlParameters = <T extends unknown>(object?: Window | Document): T | null => {
    const queryString = (object ? object.location.search : window.location.search)
    if (!queryString) return null

    const urlParams = new URLSearchParams(queryString)
    const params: { [key: string]: string } = {}

    for (const [key, value] of Array.from(urlParams.entries())) {
        params[key.toLowerCase()] = value
    }
    return params as T
}

export const retrieveParamByName = (name: string, url: string) => {
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getSessionInfo = (): Omit<SessionInfo, 'environment' | 'build-number' | 'commit-number'> => {
    const navUserAgent = navigator.userAgent
    const screenResolution = `${window.screen.width}x${window.screen.height}`
    const windowDimensions = `${window.innerWidth}x${window.innerHeight}`

    return {
        'user-agent': navUserAgent,
        browser: platform.name || 'unknown',
        'browser-version': platform.version || 'unknown',
        os: platform.os?.family || 'unknown',
        version: platform.os?.version || 'unknown',
        'screen-size': screenResolution,
        'browser-window-size': windowDimensions
    }
}

export const getDistinctObjectsFromArray = (array: any[], property: string) => Array.from(new Set(array.map((x: any) => x[property])))

export const sanitizeBaseUrl = (baseUrl: string) => baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`
export const sanitizePath = (path: string) => {
    const start = path.startsWith('/') ? path.substring(1) : path
    return start.endsWith('/') ? start : `${start}/`
}