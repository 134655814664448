import { useReducer, useContext, createContext, Dispatch, FC, ReactNode, useCallback } from 'react'
import { UnityContext } from 'react-unity-webgl'

import { unityConfig } from '../configs'
import { unityLogger as UnityLogger } from '../Loggers'

type State = {
    unityContext: UnityContext | null
    unityProgress: number
    unityLoaded: boolean
}

export const initialState: State = {
    unityContext: new UnityContext(unityConfig),
    unityProgress: 0,
    unityLoaded: false,
}

enum ActionType {
    SET_UNITY_PROGRESS,
    SET_UNITY_LOADED,
    SET_UNITY_CONTEXT,
}

type Action =
    {
        type: ActionType.SET_UNITY_LOADED
        payload: boolean
    } |
    {
        type: ActionType.SET_UNITY_PROGRESS
        payload: number
    } |
    {
        type: ActionType.SET_UNITY_CONTEXT
        payload: UnityContext | null
    }

const Context = createContext<{
    unityState: State
    dispatch: Dispatch<Action>
}>({ unityState: initialState, dispatch: () => null })

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.SET_UNITY_PROGRESS:
            if (action.payload - state.unityProgress > 3 || action.payload >= 89) {
                UnityLogger.info(`Unity loading progress: ${action.payload}%`)
                return { ...state, unityProgress: action.payload } as State
            }
            return state
        case ActionType.SET_UNITY_LOADED:
            return { ...state, unityLoaded: action.payload } as State
        case ActionType.SET_UNITY_CONTEXT:
            if (action.payload === null) {
                UnityLogger.log('Unity context destroyed')
                state.unityContext?.quitUnityInstance()
            }
            return { ...state, unityContext: action.payload } as State
        default:
            return state
    }
}

const UnityContextProvider: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const contextValue = { unityState: state, dispatch }
    return (
        <Context.Provider value={contextValue}>
            {children}
        </Context.Provider>
    )
}

const useUnityContext = () => {
    const context = useContext(Context)
    if (!context) {
        throw new Error(
            'useModuleContext must be used within a ModuleContextProvider. Wrap a parent component in <ModuleContextProvider> to fix this error.'
        )
    }

    const { unityState, dispatch } = context
    const actions = {
        setUnityProgress: useCallback((progress: number) =>
            dispatch({ type: ActionType.SET_UNITY_PROGRESS, payload: progress }), [dispatch]),
        setUnityLoaded: useCallback((loaded: boolean) =>
            dispatch({ type: ActionType.SET_UNITY_LOADED, payload: loaded }), [dispatch]),
        quitUnity: useCallback(() => {
            dispatch({ type: ActionType.SET_UNITY_CONTEXT, payload: null })
        }, [dispatch]),
    }

    return {
        unityState,
        unityActions: actions
    }
}
export default useUnityContext
export { UnityContextProvider }