import { useReducer, useContext, createContext, Dispatch, FC, ReactNode, useCallback } from 'react'
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

import { SOLVER_URL } from '../configs'
import { solverLogger as SolverLogger } from '../Loggers'


const createConnection = (): HubConnection => {
    const hubConnection: HubConnection = new HubConnectionBuilder()
        .withUrl(SOLVER_URL)
        .configureLogging(LogLevel.Information)
        .build()
    SolverLogger.info('SignalR Hub Connection:\n', hubConnection)
    return hubConnection
}

type State = {
    connection: HubConnection
    solverAvailable: boolean
    groupName: string
}

export const initialState: State = {
    connection: createConnection(),
    solverAvailable: false,
    groupName: '',
}

enum ActionType {
    INIT_COMPLETE,
}

type Action =
    {
        type: ActionType.INIT_COMPLETE
        payload: {
            solverAvailable: boolean
            groupName: string
        }
    }

const SolverContext = createContext<{
    solverState: State
    dispatch: Dispatch<Action>
}>({ solverState: initialState, dispatch: () => null })

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.INIT_COMPLETE:
            return {
                ...state,
                solverAvailable: action.payload.solverAvailable,
                groupName: action.payload.groupName,
            } as State
        default:
            return state
    }
}

const SolverContextProvider: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const contextValue = { solverState: state, dispatch }
    return (
        <SolverContext.Provider value={contextValue}>
            {children}
        </SolverContext.Provider>
    )
}

const useSolverContext = () => {
    const context = useContext(SolverContext)
    if (!context) {
        throw new Error(
            'useModuleContext must be used within a ModuleContextProvider. Wrap a parent component in <ModuleContextProvider> to fix this error.'
        )
    }

    const { solverState, dispatch } = context
    const actions = {
        doInitComplete: useCallback((groupName: string) =>
            dispatch({ type: ActionType.INIT_COMPLETE, payload: { solverAvailable: true, groupName } }), [dispatch]),
    }

    return {
        solverState,
        solverActions: actions
    }
}
export default useSolverContext
export { SolverContextProvider }