export const LRS_PATH = 'api/xapi'

export enum EndPoints {
    QueryLRSAgent = 'QueryLRSAgent',
    PostLRSStatement = 'PostLRSStatement',
    UpdateLRSAgent = 'UpdateLRSAgent',
    QueryLRSModuleSummary = 'QueryLRSModuleSummary',
    RunLRSQuery = 'RunLRSQuery',
}

export enum Query {
    GetModuleSummaryByUser = 'GetModuleSummaryByUser',
    GetFaultAttemptSummaryByUser = 'GetFaultAttemptSummaryByUser',
    GetFaultAttemptListByUser = 'GetFaultAttemptListByUser',
    GetFaultAttemptDetails = 'GetFaultAttemptDetailsByFaultAttemptId'
}