import { AchievementLevel, LabBookmark } from '../common/types'

export interface ModuleProfile {
    'module-bookmark'?: LabBookmark
    'module-complete-timestamp': string
    'module-first-used-timestamp': string
    'module-last-used-timestamp': string
    'module-registration-id': string
    'module-score': number
    'module-seat-time': number
    'module-start-timestamp': string
    'module-total-training-time': string
    'version': number
}

export interface TS_Profile extends ModuleProfile {
    'module-accuracy-score': number
    'module-efficiency-score': number
    'module-level-achieved': AchievementLevel
    'module-level-in-progress': number
    'module-number-faults-attempted': number
    'module-number-faults-solved': number
    'module-overall-score': number
    'module-percentage-faults-solved': number
    'module-safety-score': number
    'module-skill-rating': number
}

export enum SectionStatus {
    NotStarted = 'not started',
    Started = 'started',
    Completed = 'completed'
}

export interface LLTS1_Profile extends ModuleProfile {
    'lesson-arc-flash-hazard': SectionStatus
    'lesson-lockout-tagout-LD-three-phase': SectionStatus
    'lesson-lockout-tagout-LDL-single-phase': SectionStatus
    'lesson-safety-overview': SectionStatus
    'lesson-self-check': SectionStatus
    'lesson-shock-hazard': SectionStatus
    'section-lockout-tagout-LDL': SectionStatus
    'section-safety-status': SectionStatus
    'section-self-check': SectionStatus
}

export interface LLTF1_Profile extends ModuleProfile {
    'lesson-define-problem-area': SectionStatus
    'lesson-determining-test-locations': SectionStatus
    'lesson-finding-opens-with-a-voltmeter': SectionStatus
    'lesson-finding-opens-with-an-ammeter': SectionStatus
    'lesson-finding-opens-with-an-ohmmeter': SectionStatus
    'lesson-finding-shorts-with-an-ohmmeter': SectionStatus
    'lesson-follow-up': SectionStatus
    'lesson-guided-fault-1': SectionStatus
    'lesson-guided-fault-2': SectionStatus
    'lesson-identify-possible-probable-causes': SectionStatus
    'lesson-meter-safety': SectionStatus
    'lesson-observe': SectionStatus
    'lesson-overview': SectionStatus
    'lesson-prepare': SectionStatus
    'lesson-repair-replace': SectionStatus
    'lesson-test-probable-cause': SectionStatus
    'lesson-test-to-reduce-problem-area': SectionStatus
    'lesson-types-of-faults': SectionStatus
    'lesson-using-a-voltmeter': SectionStatus
    'lesson-using-an-ammeter': SectionStatus
    'lesson-using-an-ohmmeter': SectionStatus
    'section-exercises': SectionStatus
    'section-introduction': SectionStatus
    'section-systematic-troubleshooting': SectionStatus
    'section-testing-techniques': SectionStatus
    'section-using-a-meter': SectionStatus
}

export interface LLTCC_Profile extends ModuleProfile {
    'lesson-blown-fuses': SectionStatus
    'lesson-check-operating-specifications': SectionStatus
    'lesson-control-transformers': SectionStatus
    'lesson-fuses-and-breakers': SectionStatus
    'lesson-guided-fault-1': SectionStatus
    'lesson-guided-fault-2': SectionStatus
    'lesson-high-resistance-connections': SectionStatus
    'lesson-input-output-logic-power-supply': SectionStatus
    'lesson-intermittent-faults': SectionStatus
    'lesson-manually-operate-components': SectionStatus
    'lesson-multiple-component-failures': SectionStatus
    'lesson-relays': SectionStatus
    'lesson-switches': SectionStatus
    'lesson-the-neutral': SectionStatus
    'lesson-use-a-systematic-approach': SectionStatus
    'lesson-visual-indicators': SectionStatus
    'section-circuit-components': SectionStatus
    'section-exercises': SectionStatus
    'section-troubleshooting-tips': SectionStatus
}

export interface LLTMC_Profile extends ModuleProfile {
    'lesson-causes-of-failure-overview': SectionStatus
    'lesson-contactors': SectionStatus
    'lesson-controlling-a-3-phase-motor': SectionStatus
    'lesson-defective-motor': SectionStatus
    'lesson-guided-fault-1': SectionStatus
    'lesson-guided-fault-2': SectionStatus
    'lesson-loading-characteristics': SectionStatus
    'lesson-malfunction-in-the-control-circuit': SectionStatus
    'lesson-mechanical-problems-in-the-load': SectionStatus
    'lesson-motor-control-overloads-part2': SectionStatus
    'lesson-motor-control-overloads': SectionStatus
    'lesson-motor-overloads': SectionStatus
    'lesson-motor-windings-resistance': SectionStatus
    'lesson-motor-windings': SectionStatus
    'lesson-opens-in-the-motor-circuit': SectionStatus
    'lesson-overview-motor-behaviour': SectionStatus
    'lesson-overview-motor-windings': SectionStatus
    'lesson-overview-parts-of-a-motor-circuit': SectionStatus
    'lesson-reversing-a-motor': SectionStatus
    'lesson-shorts-in-the-motor-circuit': SectionStatus
    'lesson-starting-characteristics': SectionStatus
    'section-determining-causes-of-failure': SectionStatus
    'section-exercises': SectionStatus
    'section-motor-behaviour': SectionStatus
    'section-motor-control-components': SectionStatus
    'section-motor-windings-and-resistance': SectionStatus
}