import { FC, MouseEvent, ReactNode, useCallback } from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { useModuleContext, useTroubleshootContext, useUnityContext } from '../contexts'
import { useToggle } from '../hooks'

import { preventContextMenu } from '../utils'
import { FaultMode, ResourceImage } from '../interfaces'

import { DoorIcon, FolderIcon, HelpIcon, HomeIcon } from '../assets/icons'
import { FolderMenu, HelpMenu, Dialog } from './'
import { IconButton, PrimaryButton, SecondaryButton } from '../styles/Buttons'
import { SimutechBlue90 } from '../styles/Theme'
import DollarsIcon from '../assets/icons/dollars.svg'
import TimeIcon from '../assets/icons/time.svg'

type Props = {
    faultMode?: FaultMode
    pageTitle?: string
    onConfirmExit?: () => void
    onHomeClick?: () => void
    showDiagram?: (diagram: ResourceImage) => void
    showWorkOrder?: () => void
}

const NavigationBar: FC<Props> = ({
    faultMode,
    pageTitle,
    onConfirmExit,
    onHomeClick,
    showDiagram,
    showWorkOrder,
}: Props) => {
    const { moduleState: { cost, timeSpent, moduleContent } } = useModuleContext()
    const { tsState: { isCustomTest } } = useTroubleshootContext()
    const { unityState: { unityLoaded } } = useUnityContext()

    const [confirmExitShow, toggleConfirmExit] = useToggle(false)
    const [showFolderMenu, toggleFolderMenu] = useToggle(false)
    const [showHelpMenu, toggleHelpMenu] = useToggle(false)

    const navigate = useNavigate()
    const location = useLocation()

    const isInLab = location.pathname.includes('/lab/')
    const isTroubleshooting = location.pathname.includes('/troubleshoot')

    const title = isCustomTest ? `Custom Curriculum for ${moduleContent.title}` : pageTitle || moduleContent.title
    const { exitConfirmTitle, exitConfirmBody } = exitDialogValues(faultMode, isInLab)

    const doExit = useCallback((e: MouseEvent) => {
        e.preventDefault()
        toggleConfirmExit()
        onConfirmExit && onConfirmExit()
    }, [onConfirmExit, toggleConfirmExit])

    const handleHomeClick = useCallback((e: MouseEvent) => {
        e.preventDefault()
        navigate('/', { replace: true })
        onHomeClick && onHomeClick()
    }, [navigate, onHomeClick])

    const handleFolderClick = (e: MouseEvent) => {
        e.preventDefault()
        toggleFolderMenu()
    }

    const handleHelpClick = (e: MouseEvent) => {
        e.preventDefault()
        toggleHelpMenu()
    }

    const handleExitClick = (e: MouseEvent) => {
        e.preventDefault()
        toggleConfirmExit()
    }

    if (location.pathname === '/') return <></>

    const showNavItems = unityLoaded && ['/explore', '/troubleshoot', '/lab'].some(path => location.pathname.includes(path))

    return (
        <>
            <Wrapper onContextMenu={preventContextMenu}>
                <Container fluid={true}>
                    <Row>
                        <Col sm={{ span: 1 }}>
                            <Nav className="flex-nowrap">
                                {
                                    !isTroubleshooting ? (
                                        <Nav.Link>
                                            <IconButton onClick={handleHomeClick}>
                                                <HomeIcon />
                                            </IconButton>
                                        </Nav.Link>
                                    ) : <></>
                                }
                            </Nav>
                        </Col>
                        <Col sm={{ span: 7 }}>
                            <ModuleTitle>{title}</ModuleTitle>
                        </Col>
                        <Col>
                            <div className="d-flex align-items-center justify-content-end">
                                {
                                    showNavItems ? (
                                        <>
                                            <DollarsSpent /><Value>{cost}</Value>
                                            <TimeSpent /><Value>{timeSpent}</Value>
                                        </>
                                    ) : <></>
                                }
                            </div>
                        </Col>
                        <Col sm={{ span: 2 }}>
                            <Nav className="justify-content-end">
                                {
                                    showNavItems ? (
                                        <Nav.Link>
                                            <IconButton
                                                title={'Resource Folder'}
                                                onClick={handleFolderClick}
                                            >
                                                <FolderIcon />
                                            </IconButton>
                                        </Nav.Link>
                                    ) : <></>
                                }
                                {
                                    showDiagram ? (
                                        <FolderMenu
                                            visible={showFolderMenu}
                                            hideMenu={toggleFolderMenu}
                                            showDiagram={showDiagram}
                                            showWorkOrder={showWorkOrder}
                                        />) : <></>
                                }
                                <Nav.Link>
                                    <IconButton
                                        title={'Help'}
                                        onClick={handleHelpClick}
                                    >
                                        <HelpIcon />
                                    </IconButton>
                                </Nav.Link>

                                <HelpMenu
                                    visible={showHelpMenu}
                                    hideMenu={toggleHelpMenu}
                                />
                                {
                                    showNavItems ? (
                                        <Nav.Link>
                                            <IconButton
                                                title={exitConfirmTitle}
                                                onClick={handleExitClick}
                                            >
                                                <DoorIcon />
                                            </IconButton>
                                        </Nav.Link>
                                    ) : <></>
                                }

                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </Wrapper>
            <Dialog
                title={exitConfirmTitle + '?'}
                visible={confirmExitShow}
                buttons={[
                    <PrimaryButton key={`confBtn`} onClick={doExit}>{`Yes`}</PrimaryButton>,
                    <SecondaryButton key={`cancelBtn`} onClick={toggleConfirmExit}>{`No`}</SecondaryButton>
                ]}
                onHide={toggleConfirmExit}
            >
                {exitConfirmBody}
            </Dialog>
        </>
    )
}
export default NavigationBar

const exitDialogValues = (faultMode: FaultMode | undefined, isInLab: boolean | undefined) => {

    const exitConfirmTitle: string = faultMode === FaultMode.Troubleshoot
        ? 'Exit Fault'
        : isInLab
            ? 'Exit Lab'
            : 'Return to Home page'

    const exitConfirmBody: ReactNode = faultMode === FaultMode.Troubleshoot
        ? (
            <>
                <p>{`Before exiting you must verify that:`}</p>
                <ul>
                    <li>{`all aspects of the circuit are working correctly`}</li>
                    <li>{`all panels are closed`}</li>
                    <li>{`all wires are reconnected, and`}</li>
                    <li>{`the meter is disconnected`}.</li>
                </ul>
                <p>{`Are you sure you want to leave?`}</p>
            </>
        )
        : isInLab
            ? 'Are you sure you want to exit the lab?'
            : 'Are you sure you want to return to the Home page?'

    return { exitConfirmTitle, exitConfirmBody }
}

const Wrapper = styled.div`
    position: absolute !important;
    z-index: 100;
    width: 100%;
    min-width: 85.375em;
    top: 0;
    left: 0;
    margin: 0;
    color: white;
    background: ${SimutechBlue90};
    height: 3.375em;
    padding: 0.75em 0;
    user-select: none;    

    a {
        background-size: contain;
        
        &:hover {
            cursor: pointer;
        }
    }
    .nav-link {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
`

const ModuleTitle = styled.h1`
    font-size: 1.5em;
    font-weight: 500;
    margin: 0;
`

const DollarsSpent = styled.span`
    background: url(${DollarsIcon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 1.875em;
    height: 1.875em;
`

const TimeSpent = styled.span`
    background: url(${TimeIcon});    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 1.875em;
    height: 1.875em;
`

const Value = styled.span`
    display: inline-block;
    padding: 0 1em 0 0.5em;
`