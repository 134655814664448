import { FC, useState, MouseEvent, useEffect, } from 'react'
import { useModuleContext } from '../contexts'
import { ResourceImage } from '../interfaces'
import { DraggableDiagram } from './'
import { WorkOrderReference } from './troubleshoot'

type Props = {
    diagrams: ResourceImage[]
    onDiagramClose: (e: MouseEvent<HTMLElement>, diagram: ResourceImage) => void
    showWorkOrder: boolean
    hideWorkOrder: () => void
}

const ResourcesViewer: FC<Props> = ({ diagrams, onDiagramClose, showWorkOrder, hideWorkOrder }: Props) => {
    const { moduleState: { user } } = useModuleContext()
    const [activeDiagram, setActiveDiagram] = useState<ResourceImage | null>(null)
    const [activeWorkOrder, setActiveWorkOrder] = useState(false)

    // const workOrderShown = useRef(showWorkOrder)

    useEffect(() => {
        if (!showWorkOrder) return
        setActiveWorkOrder(true)
        setActiveDiagram(null)
    }, [showWorkOrder])

    useEffect(() => {
        if (!diagrams.length) return
        setActiveWorkOrder(false)
        setActiveDiagram(diagrams[diagrams.length - 1])
    }, [diagrams, diagrams.length]) // Ensure 'diagrams.length' is always here. We want to run this effect everytime the length of the array changes.

    const onDiagramMouseDown = (diagram: ResourceImage) => {
        setActiveWorkOrder(false)
        setActiveDiagram(diagram)
    }

    if (!user) throw new Error('Tried to show resources without a user.')
    const assetsPath = process.env.PUBLIC_URL + '/assets/images/' + user.electricalCode + '/'

    const draggableDiagrams = diagrams.map((diagram: ResourceImage, idx: number) => (
        <DraggableDiagram
            key={'ri' + idx}
            diagram={diagram}
            path={assetsPath}
            index={idx}
            onMouseDown={onDiagramMouseDown}
            onDiagramClose={onDiagramClose}
            cssClass={diagram === activeDiagram && !activeWorkOrder ? 'diagram-top-z-index' : ''}
        />
    ))

    return (
        <div className={'interactive'}>
            {draggableDiagrams}
            {
                showWorkOrder ? (
                    <WorkOrderReference
                        onWorkOrderClose={hideWorkOrder}
                        onMouseDown={() => setActiveWorkOrder(true)}
                        cssClass={activeWorkOrder ? 'top-z-index' : ''}
                    />
                ) : <></>
            }
        </div>
    )
}

export default ResourcesViewer