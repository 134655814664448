import { useEffect, useState } from 'react'

import { useModuleContext, useTroubleshootContext } from '../contexts'
import { useLRS } from '.'
import { generalLogger as GeneralLogger } from '../Loggers'

import { FaultAttemptItem } from '../common/types'

const useFaultHistory = () => {
    const { moduleState: { user } } = useModuleContext()
    const { tsState: { faultHistory }, tsActions: { saveFaultHistory } } = useTroubleshootContext()
    const [error, setError] = useState('')
    const { getFaultAttemptListByUser } = useLRS()

    useEffect(() => {
        if (!user || faultHistory) return
        GeneralLogger.log('Fault History data load effect.')

        const fetchData = async () => {
            const faultHistorySummary: FaultAttemptItem[] = await getFaultAttemptListByUser()
            saveFaultHistory(faultHistorySummary)
        }
        fetchData()
            .catch(error => setError(error.toString()))

    }, [faultHistory, getFaultAttemptListByUser, saveFaultHistory, user])

    return { faultHistory, error } as { faultHistory: FaultAttemptItem[]; error: string }
}

export default useFaultHistory