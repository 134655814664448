import { Consola } from 'consola'

import { IApiClient } from '../api/ApiClient'
import { EndPoints, FAULT_MANAGER_PATH } from './constants'
import { ModuleFault } from '../../types'
import { IFaultManagerApiClient } from './types'
import { sanitizePath } from '../../utils'

export class FaultManagerApiClient implements IFaultManagerApiClient {
    private apiBase: string
    private faultManagerApiClient: IApiClient
    logger: Consola

    constructor(apiClient: IApiClient, logger: Consola) {
        this.apiBase = sanitizePath(FAULT_MANAGER_PATH)
        this.faultManagerApiClient = apiClient
        this.logger = logger
    }

    getFaultList = async (moduleId: string, showDisabledFaults: boolean = false): Promise<ModuleFault[]> => {
        this.logger.log('Retrieving fault list...')
        try {
            const response = await this.faultManagerApiClient.get<ModuleFault[]>(
                `${this.apiBase}${EndPoints.GetFaultList}?moduleId=${moduleId}&includeDisabledFaults=${showDisabledFaults}`
            )
            this.logger.success('Fault list retrieved', response)
            return response
        }
        catch (exception) {
            this.logger.error(exception)
            throw new Error(`Error retrieving list of faults ${showDisabledFaults ? '(including disabled faults)' : ''} for ${moduleId}: ${exception}`)
        }
    }
}

export default class FaultManagerService {
    private faultManagerApiClient: IFaultManagerApiClient
    logger: Consola

    constructor(apiClient: IFaultManagerApiClient) {
        this.faultManagerApiClient = apiClient
        this.logger = apiClient.logger
    }

    getFaultList = async (moduleId: string, showDisabledFaults: boolean = false): Promise<ModuleFault[]> =>
        this.faultManagerApiClient.getFaultList(moduleId, showDisabledFaults)
}