import { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import { SimutechBlue } from '../styles/Theme'
import './Tooltip.scss'

// Usage: https://github.com/wwayne/react-tooltip#readme

type Props = {
    delayShow?: number
    backgroundColor?: string
    textColor?: string
}

const Tooltip: FC<Props> = ({ delayShow = 300, backgroundColor = 'white', textColor = SimutechBlue }: Props) => (
    <ReactTooltip
        type={'light'}
        effect={'solid'}
        delayShow={delayShow}
        backgroundColor={backgroundColor}
        textColor={textColor}
        className={'scoreboardTooltip'}
    />
)
export default Tooltip