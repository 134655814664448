import { FC } from 'react'

import { build, commitID, releaseDate, environmentName } from '../configs'
import { useModuleContext } from '../contexts'

import { ElectricalCodeName } from '../common/types'
import Dialog from './Dialog'

type Props = {
    visible: boolean
    onHide: () => void
}

const AboutDialog: FC<Props> = ({ visible, onHide }: Props) => {
    const { moduleState: { user } } = useModuleContext()
    if (!user) return null

    const { userName, userId, companyId, companyName, clientId, electricalCode } = user

    return (
        <Dialog
            title={'About'}
            visible={visible}
            onHide={onHide}
        >
            <p>
                <strong>{`Environment: `}</strong>&nbsp;{environmentName}<br />
                <strong>{`Build: `}</strong>&nbsp;{build}<br />
                <strong>{`Commit: `}</strong>&nbsp;{commitID}<br />
                <strong>{`Release Date: `}</strong>&nbsp;{releaseDate}<br />
                <strong>{`Student: `}</strong>&nbsp;{`${userName} (${userId})`}<br />
                <strong>{`Company: `}</strong>&nbsp;{`${clientId} - ${companyName} (${companyId})`}<br />
                <strong>{`EC: `}</strong>&nbsp;{ElectricalCodeName[electricalCode]}
            </p>
        </Dialog>
    )
}
export default AboutDialog