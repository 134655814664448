import { MouseEvent } from 'react'
import { AchievementLevel } from '../common/types'

import { isDebug } from '../configs'

export const addBodyClasses = (classes: string[]) => document.body.classList.add(...classes)
export const removeBodyClasses = (classes: string[]) => document.body.classList.remove(...classes)

export const preventContextMenu = (e: MouseEvent) => !isDebug && e.preventDefault()

export const valueToAchievementLevel = (value: any): AchievementLevel => {
    if (value === undefined || value === null) return AchievementLevel.NA
    const parsedLevel = value >> 0 // Bitwise shift to convert any value to INT
    if (parsedLevel < 0 || parsedLevel > 5) return AchievementLevel.NA
    return parsedLevel as AchievementLevel
}