import {
    getFromSession, getUrlParameters, isEmpty,
    storeInSession, toBoolean
} from '../common/utils'
import { valueToAchievementLevel } from '../utils'
import { EnvironmentVariableKey, EnvironmentVariableMappings } from '.'
import { AchievementLevel, ElectricalCode } from '../common/types'

// These parameters are case insensitive.
type SimUrlParamStrings = {
    // Electrical Code
    ec?: string

    // A boolean value indicating whether the simulation is a Custom Test or not.
    // Can use any truthy/falsy value.
    iscustomtest?: string

    // The maximum number of attempts per fault in a Custom Test.
    customtestmaxtries?: string

    // A boolean value indicating whether the user should be shown the Post Assessment
    // page after exiting a fault in a Custom Test. Setting this to false will also not 
    // allow them to review Fault Attempts from the Fault History page. Can use any 
    // truthy/falsy value.    
    customtestshowresults?: string

    // A boolean value that overrides the environment variable when outside of an LMS
    // indicating whether the user can select faults. Enables the shortcut key that shows
    // the fault selector. Can use any truthy/falsy value.    
    canselectfaults?: string

    // A boolean value that overrides the environment variable when outside of an LMS
    // indicating whether to show faults that have been disabled in the backend in the
    // Fault Selector. Can use any truthy/falsy value.
    showdisabledfaults?: string

    // A boolean value that overrides the environment variable when outside of an LMS
    // indicating whether the user -- from the fault selector page -- can use faults
    // that have been disabled in the backend. Can use any truthy/falsy value.
    // Ignored if 'canselectfaults' is false.
    canusedisabledfaults?: string

    // A boolean value that overrides the environment variable BOTH INSIDE AND OUTSIDE 
    // of an LMS indicating whether hints are enabled and accessible in the Help menu.
    // Can use any truthy/falsy value.
    hintsenabled?: string

    // A boolean value that overrides the environment variable BOTH INSIDE AND OUTSIDE 
    // of an LMS indicating whether the Replace Part confirmation dialog should show 
    // the cost and time of replacing that part. Can use any truthy/falsy value.
    showreplacecosttime?: string

    // A boolean value that overrides the environment variable when outside of an LMS
    // indicating whether the user's course in the LMS will be automatically marked
    // as complete as soon as they Exit/Complete their first fault. 
    // Can use any truthy/falsy value.
    // THIS HAS NO EFFECT OUTSIDE OF AN LMS AND USED FOR DEBUG PURPOSES ONLY.
    completeonfirstfault?: string

    // A integer from 0 to 5 that overrides the environment variable when outside of an 
    // LMS indicating the Achievement level at which the user's course in the LMS will be
    // automatically marked as complete.
    // THIS HAS NO EFFECT OUTSIDE OF AN LMS AND USED FOR DEBUG PURPOSES ONLY.
    completeonlevel?: string

    // An integer from 0 to 5 that overrides the environment variable when outside of an 
    // LMS indicating the Achievement level to treat this as a "Mini Sim".
    minisimlevel?: string

    // User ID. Can use any string value that has a length greater than 2.
    userid?: string

    // User Name. Can use any string value that has a length greater than 2.
    username?: string

    // Company ID
    companyid?: string

    // Show debug tools menu section in Help menu
    showdebugtools?: string
}

export type SimParameters = {
    electricalCode?: ElectricalCode
    isCustomTest?: boolean
    maxNumberOfAttempts?: number
    showResults?: boolean
    canSelectFaults?: boolean
    showDisabledFaults?: boolean
    canUseDisabledFaults?: boolean
    hintsEnabled?: boolean
    showReplaceCostTime?: boolean
    completeOnFirstFault?: boolean
    completeOnLevel?: AchievementLevel
    miniSimLevel?: AchievementLevel
    userId?: string
    userName?: string
    companyId?: string
    showDebugTools?: boolean
}
export type SimParameterKey = keyof SimParameters

type QueryStringOverrides = ReturnType<typeof getQueryStringOverrides>
type OverrideValue = ReturnType<typeof getOverrideValue>

const parseSimUrlParameters = (queryParams: SimUrlParamStrings): QueryStringOverrides => {
    const {
        ec, iscustomtest, customtestmaxtries,
        customtestshowresults, canselectfaults, canusedisabledfaults,
        showdisabledfaults, hintsenabled, showreplacecosttime,
        completeonfirstfault, completeonlevel, minisimlevel,
        userid, username, companyid, showdebugtools
    } = queryParams

    const maxTriesParsed = customtestmaxtries === undefined
        ? undefined
        : parseInt(customtestmaxtries, 10)

    const simParams: SimParameters = {}

    if (ec !== undefined && Object.keys(ElectricalCode).includes(ec))
        simParams.electricalCode = ec.toUpperCase() as ElectricalCode
    if (iscustomtest !== undefined)
        simParams.isCustomTest = toBoolean(iscustomtest)
    if (maxTriesParsed !== undefined && !isNaN(maxTriesParsed))
        simParams.maxNumberOfAttempts = maxTriesParsed
    if (customtestshowresults !== undefined)
        simParams.showResults = toBoolean(customtestshowresults)
    if (canselectfaults !== undefined)
        simParams.canSelectFaults = toBoolean(canselectfaults)
    if (showdisabledfaults !== undefined)
        simParams.showDisabledFaults = toBoolean(showdisabledfaults)
    if (canusedisabledfaults !== undefined)
        simParams.canUseDisabledFaults = toBoolean(canusedisabledfaults)
    if (hintsenabled !== undefined)
        simParams.hintsEnabled = toBoolean(hintsenabled)
    if (showreplacecosttime !== undefined)
        simParams.showReplaceCostTime = toBoolean(showreplacecosttime)
    if (completeonfirstfault !== undefined)
        simParams.completeOnFirstFault = toBoolean(completeonfirstfault)
    if (completeonlevel !== undefined)
        simParams.completeOnLevel = valueToAchievementLevel(completeonlevel)
    if (minisimlevel !== undefined)
        simParams.miniSimLevel = valueToAchievementLevel(minisimlevel)
    if (userid !== undefined && userid.length >= 3)
        simParams.userId = userid
    if (username !== undefined && username.length >= 3)
        simParams.userName = username
    if (companyid !== undefined)
        simParams.companyId = companyid
    if (showdebugtools !== undefined)
        simParams.showDebugTools = toBoolean(showdebugtools)

    if (isEmpty(simParams)) return null
    return simParams
}

const getQueryStringOverrides = (): SimParameters | null => {
    const queryParams = getUrlParameters<SimUrlParamStrings>(document)
    const urlParameters = queryParams ? parseSimUrlParameters(queryParams) : null
    const paramsInSession = getFromSession('simUrlParams')
    const sessionParams = paramsInSession ? JSON.parse(paramsInSession) as SimParameters : null

    // Let's take the URL parameters saved in session and combine them with the URL parameters
    // that were passed in the URL. The URL parameters passed in the URL will override the ones
    // saved in session.
    const queryStringOverrides: SimParameters = {
        ...sessionParams,
        ...urlParameters
    }

    if (isEmpty(queryStringOverrides)) return null

    storeInSession('simUrlParams', JSON.stringify(queryStringOverrides))
    return queryStringOverrides
}

export const getOverrideValue = (key: SimParameterKey, overrides: QueryStringOverrides) => {
    if (!overrides) return null
    const value = overrides[key]
    if (value === undefined || value === null || value === '') return null
    return value
}

export const isEnvVarOverridden = (override: OverrideValue, key: EnvironmentVariableKey) => {
    const envVar = process.env[key]
    if (envVar === undefined || envVar === null || envVar === '') return false
    return override !== undefined && override !== null && override !== ''
        && envVar !== override.toString()
}

export const hasActiveOverrides = (overrides: QueryStringOverrides) => {
    if (!overrides) return false

    return (Object.keys(overrides) as Array<SimParameterKey>)
        .some(key => {
            const enVarKey = EnvironmentVariableMappings
                .find(mapping => mapping.queryStringOverride === key)?.environmentVariableName
            if (!enVarKey) return false
            return isEnvVarOverridden(getOverrideValue(key, overrides), enVarKey)
        })
}

export default getQueryStringOverrides