import Axios, { AxiosInstance } from 'axios'
import { sanitizeBaseUrl } from '../../utils'
import { axiosErrorHandler, logError } from './ApiErrorHandler'
import { ApiConfiguration, RequestConfig } from './types'

export interface IApiClient {
    post<TRequest, TResponse>(
        path: string,
        object: TRequest,
        config?: RequestConfig
    ): Promise<TResponse>
    patch<TRequest, TResponse>(
        path: string,
        object: TRequest
    ): Promise<TResponse>
    put<TRequest, TResponse>(
        path: string,
        object?: TRequest
    ): Promise<TResponse>
    get<TResponse>(
        path: string,
        config?: RequestConfig
    ): Promise<TResponse>
}

export default class ApiClient implements IApiClient {
    private client: AxiosInstance

    protected createAxiosClient = (
        apiConfiguration: ApiConfiguration
    ): AxiosInstance =>
        Axios.create({
            baseURL: sanitizeBaseUrl(apiConfiguration.baseURL),
            responseType: 'json' as const,
            headers: {
                'Content-Type': 'application/json',
                ...(apiConfiguration.accessToken && {
                    Authorization: `Token ${apiConfiguration.accessToken}`,
                }),
            },
            timeout: 10 * 1000,
        })

    constructor(apiConfiguration: ApiConfiguration) {
        this.client = this.createAxiosClient(apiConfiguration)
    }

    post = async <TRequest, TResponse>(
        path: string,
        payload: TRequest,
        config?: RequestConfig
    ): Promise<TResponse> =>

        await this.client.post(path, payload, config)
            .then(response => response.data)
            .catch(axiosErrorHandler<TResponse>(res => logError(res)))

    patch = async <TRequest, TResponse>(
        path: string,
        payload: TRequest
    ): Promise<TResponse> =>
        await this.client.patch(path, payload)
            .then(response => response.data)
            .catch(axiosErrorHandler<TResponse>(res => logError(res)))

    put = async <TRequest, TResponse>(
        path: string,
        payload?: TRequest
    ): Promise<TResponse> =>
        await this.client.put(path, payload)
            .then(response => response.data)
            .catch(axiosErrorHandler<TResponse>(res => logError(res)))

    get = async <TResponse>(
        path: string,
        config?: RequestConfig
    ): Promise<TResponse> =>
        await this.client.get(path, config)
            .then(response => response.data)
            .catch(axiosErrorHandler<TResponse>(res => logError(res)))
}