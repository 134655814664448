import { NIL as NIL_UUID } from 'uuid'

import {
    debugCanSelectFault, debugCanUseDisabledFaults, debugCompanyId,
    debugCompanyName, debugUserId, debugUserName,
    defaultElectricalCode, debugCompleteOnFirstFault, debugCompleteOnLevel,
    debugMiniSimLevel, simMod, debugCustomTestId
} from './configs'

import { ccService as ContentController } from './Services'
import { LMSInfo } from './hooks'
import { generalLogger as GeneralLogger } from './Loggers'

import { AchievementLevel, CanSelectFault, ElectricalCode } from './common/types'
import { masteryLevels, User } from './interfaces'

export const getUser = async (lmsInfo?: LMSInfo): Promise<User> => {
    GeneralLogger.log('Initializing user...')

    if (lmsInfo) {
        const { level, sessionId, courseTrackerId, completeCourseOn, electricalCode, customTestId } = lmsInfo

        const {
            id, firstName, lastName,
            userTenant: { lrsCompanyId, clientName, clientId, canSelectFaults }
        } = await ContentController.Get.LMSUser(sessionId)

        const moduleId = level
            ? `${simMod.moduleId}_${masteryLevels.filter(lvl => lvl.level === level)[0]?.name}`.toUpperCase()
            : customTestId
                ? `${simMod.moduleId}_CUSTOMTEST_${customTestId}`
                : simMod.moduleId

        const user: User = {
            userId: id.toString(),
            userName: `${lastName}, ${firstName}`,
            companyId: lrsCompanyId,
            companyName: clientName,
            clientId,
            moduleId: moduleId,
            moduleLevel: !level || simMod.isLab ? null : level,
            electricalCode: electricalCode.toUpperCase() as ElectricalCode,
            completeOnFirstFault: !level && completeCourseOn === AchievementLevel.NA,
            completeOnLevel: !level && completeCourseOn !== AchievementLevel.NA && completeCourseOn !== null
                ? completeCourseOn
                : null,
            lmsSessionId: sessionId,
            courseTrackerId,
            customTestId: customTestId ? customTestId : null,
            canSelectFaults: canSelectFaults === CanSelectFault.Development || canSelectFaults === CanSelectFault.Yes,
            canUseDisabledFaults: canSelectFaults === CanSelectFault.Development
        }

        console.table(user)
        return user
    }

    const debugModuleId = debugMiniSimLevel !== AchievementLevel.NA
        && debugMiniSimLevel !== null
        && debugMiniSimLevel !== undefined
        ? `${simMod.moduleId}_${masteryLevels.filter(lvl => lvl.level === debugMiniSimLevel)[0]?.name}`.toUpperCase()
        : debugCustomTestId
            ? `${simMod.moduleId}_CUSTOMTEST_${debugCustomTestId}`
            : simMod.moduleId

    const user: User = {
        userId: debugUserId,
        userName: debugUserName,
        companyId: debugCompanyId,
        companyName: debugCompanyName,
        clientId: 'NOLMS',
        moduleId: debugModuleId,
        moduleLevel: !debugMiniSimLevel ? null : debugMiniSimLevel,
        electricalCode: defaultElectricalCode as ElectricalCode,
        completeOnFirstFault: debugCompleteOnFirstFault,
        completeOnLevel: !debugCompleteOnLevel ? null : debugCompleteOnLevel,
        lmsSessionId: NIL_UUID,
        courseTrackerId: 0,
        customTestId: debugCustomTestId,
        canSelectFaults: debugCanSelectFault,
        canUseDisabledFaults: debugCanUseDisabledFaults
    }
    console.table(user)
    return user
}