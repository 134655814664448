import { useState, useMemo, FC, MouseEvent, useCallback } from 'react'
import moment from 'moment'
import DataTable, { TableColumn, TableStyles } from 'react-data-table-component'

import { useNavigate } from 'react-router-dom'

import { simMod } from '../../configs'
import { useModuleContext, useTroubleshootContext } from '../../contexts'
import { useCustomTestSettings, useFaultHistory } from '../../hooks'

import { FaultAttemptItem, FaultState } from '../../common/types'
import { CustomTestFault } from '../../interfaces'

import { NavigationBar, LoadingOverlay, NoDataComponent, ErrorBox } from '../'

import { PrimaryButton, ButtonGroup, SecondaryButton } from '../../styles/Buttons'
import { TableTitle, Wrapper, customDataTableStyles } from '../../styles/Theme'
import { SortDownIcon } from '../../styles/Icons'


const FaultHistory: FC = () => {
    const navigate = useNavigate()
    const { moduleState: { user } } = useModuleContext()
    const {
        tsState: { customTestState, isCustomTest },
        tsActions: { setSelectedCustomTestFault }
    } = useTroubleshootContext()
    const { showFaultAttempts } = useCustomTestSettings()

    if (simMod.isLab) navigate('/', { replace: true })

    const { faultHistory, error } = useFaultHistory()

    const [selectedFaultAttempt, setSelectedFaultAttempt] = useState<null | FaultAttemptItem>(null)
    const { isFaultAvailable } = useCustomTestSettings()

    const goBack = () => navigate('/scoreboard')
    const rowSelected = useCallback((row: FaultAttemptItem) => row._id === selectedFaultAttempt?._id, [selectedFaultAttempt?._id])

    const onRowClick = useCallback((row: FaultAttemptItem, event: MouseEvent) => {
        event.preventDefault()
        if (!rowSelected(row)) setSelectedFaultAttempt(row)
    }, [rowSelected])

    const customTestFault = customTestState?.faultList.find((fault) => fault.faultId === selectedFaultAttempt?.faultId)
    const canRetry = isCustomTest && customTestFault ? isFaultAvailable(customTestFault) : !!selectedFaultAttempt

    const showReview = !isCustomTest || (isCustomTest && showFaultAttempts)

    const handleRetry = (e: MouseEvent) => {
        e.preventDefault()
        if (!selectedFaultAttempt) throw new Error('Oops... attempted to retry a fault without selecting one first.')
        if (!canRetry) throw new Error('Oops... attempted to retry a fault that is not available.')
        if (customTestFault) setSelectedCustomTestFault(customTestFault)
        navigate(`/workorder/${selectedFaultAttempt.faultId}`)
    }

    const handleReview = (e: MouseEvent) => {
        e.preventDefault()
        if (!selectedFaultAttempt) throw new Error('Oops... attempted to review a fault attempt without selecting one first.')
        navigate(`/faultattempt/${selectedFaultAttempt._id}`)
    }

    const columns: TableColumn<FaultAttemptItem>[] = useMemo(() => [
        {
            name: 'ID',
            omit: true,
            selector: (row) => row._id,

        },
        {
            name: 'Date',
            selector: (row) => moment(row.timestamp).format('YYYY-MM-DD'),
            sortable: true,
        },
        {
            name: 'Start Time',
            selector: (row) => moment(row.timestamp).format('HH:mm:ss'),
            sortable: false,
        },
        {
            name: 'Fault Number',
            selector: (row) => {
                const ctFaultIndex = customTestState?.faultList.findIndex((fault: CustomTestFault) => fault.faultId === row.faultId)
                const faultNumber = isCustomTest && ctFaultIndex !== undefined ? `Fault ${ctFaultIndex + 1}` : row.faultId
                return faultNumber
            },
            sortable: true,
        },
        {
            name: 'State',
            selector: (row) => row.faultState === FaultState.NotRepaired ? 'Not Repaired' : row.faultState,
            sortable: true,
        },
        {
            name: 'Skill Rating',
            selector: (row) => row.skillRating ? `${row.skillRating}%` : '',
            sortable: true,
            center: true,
        }
    ], [customTestState?.faultList, isCustomTest])

    if (error) return <ErrorBox message={error} />

    return (
        <div className="module-content">
            <NavigationBar />

            <LoadingOverlay
                active={!user || !faultHistory}
                text={`Loading your fault history`}
            />

            {user && faultHistory && (
                <Wrapper>
                    <TableTitle className={'title'}>
                        {'Your History'}
                    </TableTitle>
                    <div>
                        <DataTable
                            noHeader={true}
                            columns={columns}
                            data={faultHistory}
                            highlightOnHover={true}
                            pointerOnHover={true}
                            onRowClicked={(row, event) => onRowClick(row, event)}
                            selectableRowsHighlight={true}
                            selectableRowsNoSelectAll={true}
                            selectableRowsSingle={true}
                            selectableRowSelected={rowSelected}
                            striped={true}
                            sortIcon={<SortDownIcon />}
                            pagination={true}
                            paginationPerPage={10}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            customStyles={customStyles}
                            dense={true}
                            noDataComponent={<NoDataComponent text={`You have no history.`} />}
                        />
                    </div>
                    <ButtonGroup>
                        <PrimaryButton onClick={goBack}>
                            {'Back'}
                        </PrimaryButton>
                        {
                            faultHistory.length ? (
                                <>
                                    <SecondaryButton
                                        disabled={!canRetry}
                                        onClick={handleRetry}
                                    >
                                        {'Retry Fault'}
                                    </SecondaryButton>
                                    {
                                        showReview && (
                                            <SecondaryButton
                                                disabled={!selectedFaultAttempt}
                                                onClick={handleReview}
                                            >
                                                {'Review Attempt'}
                                            </SecondaryButton>
                                        )
                                    }
                                </>
                            ) : <></>

                        }
                    </ButtonGroup>

                </Wrapper>)}
        </div>
    )
}
export default FaultHistory

const customStyles: TableStyles = {
    ...customDataTableStyles,
    tableWrapper: {
        style: {
            minWidth: '50rem',
            maxWidth: '56.25rem',
            width: '100%',
            height: '100%',
            margin: 'auto',
        },
    },
}
