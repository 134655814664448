import { useCallback } from 'react'

import { DefaultFaultId, simMod } from '../configs'
import { useModuleContext, useLMSContext, useLearningLabContext } from '../contexts'
import { useLMS, useLRS, useSolver } from '.'

import { isEmpty } from '../common/utils'
import { generalLogger as GeneralLogger } from '../Loggers'

import { LabBookmark } from '../common/types'
import {
    ModuleProfile, SectionStatus, Section,
    Lesson, Availability, Quiz, QuizAnswerType
} from '../interfaces'

const assetsPath = process.env.PUBLIC_URL + '/assets/learning-lab/'

enum LRSState {
    Loading,
    Disabled,
    Ready,
    Completed
}

const validateLesson = (lesson: Lesson) => {
    if (lesson.faultID && !lesson.moduleId) throw new Error(`Lesson '${lesson.LRSID}' is misconfigured! This lesson has a faultID, but is missing a lessonModuleId.`)
}

const isLRSStatus = (userProfile: any, LRSID: string, status: SectionStatus): boolean | null => {
    if (!userProfile || (!(LRSID in userProfile) && simMod.isLab)) return null
    return userProfile[LRSID] === status && simMod.isLab
}

const useLearningLab = () => {
    const {
        moduleState: {
            user,
            userProfile,
            moduleContent: { sections }
        }
    } = useModuleContext()
    const { loadScene } = useSolver()

    const { setScore } = useLMS()
    const { lmsState: { isLMSAvailable } } = useLMSContext()
    const { llActions: { setSectionIndex } } = useLearningLabContext()
    const { updateUserProfile } = useLRS()

    const section = useCallback((index: number): Section => {
        if (!sections.length)
            throw new Error('Attempting to get a learning lab section when no sections are defined.')
        if (index > sections.length - 1)
            throw new Error(`Attempting to get a learning lab section at index ${index} when only ${sections.length} sections are defined.`)
        return sections[index]
    }, [sections])

    const lesson = useCallback((sectionIndex: number, lessonIndex: number): Lesson => {
        if (lessonIndex > section(sectionIndex).lessons.length - 1)
            throw new Error(`Attempting to get a learning lab lesson at index ${lessonIndex} when only ${section(sectionIndex).lessons.length} lessons are defined.`)
        const newLesson = section(sectionIndex).lessons[lessonIndex]
        validateLesson(newLesson)
        return newLesson
    }, [section])

    const lrsID = useCallback((sectionIndex: number, lessonIndex?: number): string => {
        if (lessonIndex !== undefined && lessonIndex < 0)
            throw new Error(`Attempting to get a lesson LRSID with an invalid lessonIndex of ${lessonIndex}.`)
        if (lessonIndex === undefined) return section(sectionIndex).LRSID
        return lesson(sectionIndex, lessonIndex).LRSID
    }, [lesson, section])

    const loadLesson = useCallback(async (sectionIndex: number, lessonIndex: number) => {
        setSectionIndex(sectionIndex)
        const currentLesson = lesson(sectionIndex, lessonIndex)

        GeneralLogger.log('Loading lesson...', currentLesson.LRSID)
        currentLesson.moduleId && GeneralLogger.info('Lesson Module ID:', currentLesson.moduleId)
        currentLesson.faultID && GeneralLogger.info('Lesson FaultID:', currentLesson.faultID)

        if (currentLesson.moduleId) await loadScene(currentLesson.moduleId, currentLesson.faultID || DefaultFaultId)

    }, [lesson, loadScene, setSectionIndex])

    const allSectionLessonsCompleted = useCallback((sectionIndex: number, profile: any): boolean =>
        section(sectionIndex).lessons.every((lesson: Lesson) =>
            isLRSStatus(profile, lesson.LRSID, SectionStatus.Completed)
        ), [section])

    const updateLRSStatus = useCallback(async (lrsID: string, status: SectionStatus) => {
        const statusUpdate: Partial<ModuleProfile> = { [lrsID]: status }
        await updateUserProfile(statusUpdate)
    }, [updateUserProfile])

    const getLRSState = useCallback((sectionIndex: number, lessonIndex?: number): LRSState => {

        let LRSID = lrsID(sectionIndex, lessonIndex)
        if (LRSID === Availability.Disabled) return LRSState.Disabled

        let isCompleted = isLRSStatus(userProfile, LRSID, SectionStatus.Completed)

        if (isCompleted === null) return LRSState.Loading

        if (isCompleted) return LRSState.Completed

        if (lessonIndex && lessonIndex === 0 && sectionIndex > 0) {
            // If this is the first lesson of a new section (not first section), check if the previous section is completed
            LRSID = lrsID(sectionIndex - 1)
            const isPreviousSectionComplete = isLRSStatus(userProfile, LRSID, SectionStatus.Completed)
            return isPreviousSectionComplete || sections[sectionIndex].debugOnly
                ? LRSState.Ready
                : LRSState.Disabled
        }

        if (lessonIndex && lessonIndex > 0) {
            //If we're past the first lesson, check to see if the previous lesson is complete
            LRSID = lrsID(sectionIndex, lessonIndex - 1)
            isCompleted = isLRSStatus(userProfile, LRSID, SectionStatus.Completed)
            return isCompleted ? LRSState.Ready : LRSState.Disabled
        }

        if (sectionIndex > 0) {
            //If we're past the first section, check to see if the previous section is complete            
            LRSID = lrsID(sectionIndex - 1, lessonIndex)
            const isPreviousSectionComplete = isLRSStatus(userProfile, LRSID, SectionStatus.Completed)
            return isPreviousSectionComplete ? LRSState.Ready : LRSState.Disabled
        }

        return LRSState.Ready
    }, [lrsID, userProfile, sections])

    const setSectionAsStarted = useCallback(async (sectionIndex: number) => {
        const LRSID = lrsID(sectionIndex)
        const sectionNotStarted = isLRSStatus(userProfile, LRSID, SectionStatus.NotStarted)

        if (sectionNotStarted) {
            GeneralLogger.log('Setting section as started...')
            await updateLRSStatus(LRSID, SectionStatus.Started)
        }
    }, [lrsID, updateLRSStatus, userProfile])

    const setLessonAsStarted = useCallback(async (sectionIndex: number, lessonIndex: number) => {
        const LRSID = lrsID(sectionIndex, lessonIndex)
        const lessonNotStarted = isLRSStatus(userProfile, LRSID, SectionStatus.NotStarted)

        if (lessonNotStarted) {
            GeneralLogger.log('Setting lesson as started...')
            await updateLRSStatus(LRSID, SectionStatus.Started)
        }
    }, [lrsID, updateLRSStatus, userProfile])

    const calculateScore = useCallback((userProfile: ModuleProfile): number => {
        if (!sections.length) throw new Error('Module content does not have any Sections.')

        GeneralLogger.log('Recalculating score...')
        const availableLessons: Lesson[] = []
        sections.forEach(section => {
            if (section.LRSID === Availability.Disabled || section.debugOnly) return

            // If there were content changes, let's make sure that the section statuses are correct in the LRS.
            // If any of the lessons are not completed (because of new lessons), the section should not be
            // marked as completed, and if it is, then we must mark it as Started instead.
            section.lessons.forEach(lesson => {
                lesson.LRSID !== Availability.Disabled && availableLessons.push(lesson)
                const lessonIsCompleted = isLRSStatus(userProfile, lesson.LRSID, SectionStatus.Completed)

                if (!lessonIsCompleted && isLRSStatus(userProfile, section.LRSID, SectionStatus.Completed)) {
                    GeneralLogger.warn(`Lesson '${lesson.LRSID}' is not completed, but its section '${section.LRSID}' is completed`)
                    GeneralLogger.warn('Resetting section as started...')
                    updateLRSStatus(section.LRSID, SectionStatus.Started)
                }
            })
        })

        if (availableLessons.length === 0)
            throw new Error('There are no lessons available to calculate score.')

        let totalCompleted = 0
        availableLessons.forEach(lesson =>
            isLRSStatus(userProfile, lesson.LRSID, SectionStatus.Completed)
            && ++totalCompleted)
        const recalculatedScore = totalCompleted === 0 ? 0 : totalCompleted / availableLessons.length * 100

        GeneralLogger.info('AVAILABLE LESSONS', availableLessons)
        GeneralLogger.info('# OF COMPLETED LESSONS', totalCompleted)
        GeneralLogger.info('RECALCULATED SCORE', recalculatedScore)
        return recalculatedScore
    }, [sections, updateLRSStatus])


    const setLessonAsCompleted = useCallback(async (sectionIndex: number, lessonIndex: number) => {
        if (!userProfile) throw new Error('Tried to set lesson as completed without a user profile.')
        if (lessonIndex < 0) throw new Error('Tried to set lesson as completed with a negative lesson index.')

        const lessonLRSId = lrsID(sectionIndex, lessonIndex)
        const lessonIsStarted = isLRSStatus(userProfile, lessonLRSId, SectionStatus.Started)

        const sectionLRSID = lrsID(sectionIndex)
        const sectionIsStarted = isLRSStatus(userProfile, sectionLRSID, SectionStatus.Started)

        // Don't do an update if it's a debug only section and only if the current status is 'started'.
        if ((!lessonIsStarted && !sectionIsStarted) || sections[sectionIndex].debugOnly) return

        GeneralLogger.log('Setting lesson as completed...')
        const lessonUpdate: Partial<ModuleProfile> = lessonIsStarted
            ? { [lessonLRSId]: SectionStatus.Completed }
            : {}

        const bookmarkUpdate: Partial<ModuleProfile> = isEmpty(lessonUpdate)
            ? {}
            : { 'module-bookmark': '' as unknown as LabBookmark } // Need to do this so that it can be set to a string

        const allLessonsDone = allSectionLessonsCompleted(sectionIndex, { ...userProfile, ...lessonUpdate })
        const sectionUpdate: Partial<ModuleProfile> = sectionIsStarted && allLessonsDone
            ? { [sectionLRSID]: SectionStatus.Completed }
            : {}

        const moduleScore = calculateScore({ ...userProfile, ...sectionUpdate, ...lessonUpdate })
        const scoreUpdate: Partial<ModuleProfile> = moduleScore !== userProfile['module-score'] ? { 'module-score': moduleScore } : {}
        const profileUpdate: Partial<ModuleProfile> = {
            ...sectionUpdate,
            ...lessonUpdate,
            ...scoreUpdate,
            ...bookmarkUpdate
        }
        if (isEmpty(profileUpdate)) return
        await updateUserProfile(profileUpdate)
            .then(() => {
                isLMSAvailable && setScore(moduleScore)
            })

    }, [allSectionLessonsCompleted, calculateScore, isLMSAvailable, lrsID, setScore, updateUserProfile, userProfile, sections])

    const saveBookmark = useCallback((labBookmark: LabBookmark) => {
        if (!user || !userProfile) throw new Error('Tried to save bookmark without a user.')

        GeneralLogger.info('Saving bookmark...\n', labBookmark)
        updateUserProfile<Partial<ModuleProfile>>({ 'module-bookmark': labBookmark })
    }, [updateUserProfile, user, userProfile])

    const validateQuiz = useCallback((quiz: Quiz) => {
        // Since quizzes come from an external source, we need to validate them before we can use them.
        GeneralLogger.log('Validating quiz...')
        if (!quiz.type) throw new Error('Quiz does not have a type.')
        if (quiz.type !== QuizAnswerType.MultipleChoice && quiz.type !== QuizAnswerType.SingleChoice) throw new Error('Quiz has an invalid type.')

        if (!quiz.choices?.length) throw new Error('Quiz does not have any questions.')
        if (quiz.choices.length < 2) throw new Error('Quiz does not have enough questions.')
        if (quiz.choices.some(choice => !choice.id || typeof choice.id !== 'number')) throw new Error('Quiz has an invalid choice ID.')
        if (quiz.choices.some(choice => typeof choice.isCorrect !== 'boolean')) throw new Error('Quiz has a choice that does not have a valid isCorrect value.')
        if (!quiz.choices.some(choice => choice.isCorrect === true)) throw new Error('Quiz does not have any correct answers.')

        if (!quiz.feedback) throw new Error('Quiz does not have any feedback.')
        if (quiz.feedback.correct?.length < 9) throw new Error('Quiz does not have any correct feedback.')

        if (quiz.type === QuizAnswerType.SingleChoice) {
            const correctChoices = quiz.choices.filter(choice => choice.isCorrect === true)
            if (correctChoices.length !== 1) throw new Error('Single Choice Quiz does not have exactly one correct answer.')
            if (quiz.choices.some(choice => choice.isCorrect === false && choice.feedback.incorrect.length < 9)) throw new Error('Single Choice Quiz does not have incorrect feedback for a false choice.')

        }
        if (quiz.type === QuizAnswerType.MultipleChoice) {
            if (quiz.choices.some(choice => choice.isCorrect === true && !quiz.feedback.correct.length)) throw new Error('Multiple Choice Quiz does not have any correct feedback for answering correctly.')
            if (!quiz.feedback.incomplete.length) throw new Error('Multiple Choice Quiz does not have any feedback for an incomplete answer.')
        }

    }, [])

    return {
        section,
        setLessonAsStarted,
        setSectionAsStarted,
        setLessonAsCompleted,
        getLRSState,
        calculateScore,
        LRSState,
        updateLRSStatus,
        isLRSStatus,
        lrsID,
        assetsPath,
        loadLesson,
        saveBookmark,
        validateQuiz,
    }
}
export default useLearningLab