import { useEffect, useState } from 'react'
import { useModuleContext } from '../contexts'
import { useLRS } from '.'

import { FullFaultAttempt } from '../common/types'

const useFaultAttempt = (id: string | undefined) => {
    const { moduleState: { user } } = useModuleContext()
    const [faultAttempt, setFaultAttempt] = useState<FullFaultAttempt | null>(null)
    const { getFaultAttemptDetails } = useLRS()
    const [error, setError] = useState('')

    useEffect(() => {
        if (!user || faultAttempt || !id) return
        const fetchData = async () => {
            const faultAttemptDetails = await getFaultAttemptDetails(id)
            if (!faultAttemptDetails) throw new Error('Invalid fault attempt')
            setFaultAttempt(faultAttemptDetails)
        }
        fetchData()
            .catch(error => setError(error.toString()))
    }, [faultAttempt, getFaultAttemptDetails, id, user])

    return { faultAttempt, error } as { faultAttempt: typeof faultAttempt; error: typeof error }
}
export default useFaultAttempt