import { useState, useEffect, FC, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { HubConnectionState } from '@microsoft/signalr'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'

import { simMod } from '../configs'
import { useModuleContext, useUnityContext, useSolverContext } from '../contexts'
import { useLearningLab } from '../hooks'

import { SectionStatus } from '../interfaces'

import { CheckmarkIcon } from '../assets/icons'
import { SimutechOrange, SimutechOrangeActive } from '../styles/Theme'
import { ToolsIcon } from '../styles/Icons'

interface Props {
    label: string
    path?: string
    sectionIndex?: number
    lessonIndex?: number
    clickHandler?: (event: MouseEvent, lessonIndex: number) => void
    className?: string
}

const LRSPath: FC<Props> = ({ label, path = '', sectionIndex, lessonIndex, clickHandler, className }: Props) => {
    const { moduleState: { userProfile, moduleContent: { sections } } } = useModuleContext()
    const { solverState: { connection } } = useSolverContext()
    const { unityState: { unityLoaded } } = useUnityContext()
    const { LRSState, lrsID, isLRSStatus, updateLRSStatus, getLRSState } = useLearningLab()
    const [currentState, setCurrentState] = useState(LRSState.Loading)

    const isDebugOnlySection = sectionIndex !== undefined && sections.length
        ? sections[sectionIndex].debugOnly || false
        : false

    const isReady = currentState !== LRSState.Loading
        && unityLoaded
        && connection.state === HubConnectionState.Connected

    const canClick = simMod.isTS || isDebugOnlySection
        ? isReady
        : isReady && currentState !== LRSState.Disabled

    const handleLinkClick = async (e: MouseEvent) => {
        if (clickHandler !== undefined && clickHandler !== null) {
            e.preventDefault()
        }

        if (!canClick) return

        const LRSID = lrsID(sectionIndex ?? 0, lessonIndex)
        if (isLRSStatus(userProfile, LRSID, SectionStatus.NotStarted)) {
            await updateLRSStatus(LRSID, SectionStatus.Started)
        }

        if (clickHandler !== undefined && clickHandler !== null) {
            clickHandler(e, Number(lessonIndex ?? 0))
        }
    }

    useEffect(() => {
        const state = getLRSState(sectionIndex ?? 0, lessonIndex)
        setCurrentState(state)
    }, [getLRSState, lessonIndex, sectionIndex])

    return (
        <Container className={className}>
            <div className={'lrs-status'}>
                {
                    (!isReady && <ClipLoader size={16} color={'white'} />)
                    ||
                    (isDebugOnlySection && isReady && <ToolsIcon />)
                    ||
                    (currentState !== LRSState.Completed && <></>)
                    ||
                    (currentState === LRSState.Completed && <CheckmarkIcon />)
                }
            </div>

            <PathLink
                to={path}
                className={canClick ? '' : ' disabled'}
                onClick={canClick ? async (e: MouseEvent) => await handleLinkClick(e) : () => null}>
                {label}
            </PathLink>

        </Container>
    )
}

export default LRSPath

const Container = styled.div`
    display: flex;
    align-items: center;
`

const PathLink = styled(Link)`
    color: white;    
    color: rgba(255, 255, 255, 35);
    text-decoration: none;
    text-transform: uppercase !important;
    &:link,
    &:visited {
        color: #fff;
    }
    &:hover {
        color: ${SimutechOrange};
        text-decoration: none;
    }
    &:active {
        color: ${SimutechOrangeActive};
    }
    &.disabled, &:disabled {
      color: rgba(255, 255, 255, 0.35) !important;
      pointer-events: none;
    }
`