import { ChangeEvent, FC, useState, useEffect, useRef, FormEvent } from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import ReactHtmlParser from 'html-react-parser'

import { usePrevious, useToggle } from '../../hooks'
import { QuizAnswerType, Quiz, QuizChoice } from '../../interfaces'

import Dialog from '../Dialog'

type Props = {
    quiz: Quiz
    setQuizComplete: (isComplete: boolean) => void
    quizAlreadyComplete: boolean
}

const PanelQuiz: FC<Props> = ({ quiz, setQuizComplete, quizAlreadyComplete }: Props) => {
    const previousQuiz = usePrevious(quiz)

    const [radioSelected, setRadioSelected] = useState('')
    const [checkedItems, setCheckedItems] = useState<string[]>([])
    const [formValid, setFormValid] = useState(true)
    const [showDialog, toggleDialog] = useToggle(false)

    const dialogContent = useRef({ title: '', body: '' })

    useEffect(() => {
        if (quiz === previousQuiz) return
        setRadioSelected('')
        setCheckedItems([])
        setQuizComplete(false)
    }, [previousQuiz, quiz, setQuizComplete])

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // A failsafe in case the button is enabled when it shouldn't be
        if ((quiz.type === QuizAnswerType.SingleChoice && radioSelected === '')
            || (quiz.type === QuizAnswerType.MultipleChoice && checkedItems.length === 0)) {
            setFormValid(false)
            return
        }

        !formValid && setFormValid(true)
        verifyAnswers()
    }

    const getSelectedChoices = () => {
        const selectedChoices = []
        for (const i of checkedItems) {
            const choice = quiz.choices.find((item) => item.id === +i)
            choice && selectedChoices.push(choice)
        }
        return selectedChoices
    }

    const verifyAnswers = () => {
        const correctAnswers = getAnswers(true)

        if (quiz.type === QuizAnswerType.SingleChoice) {
            const isCorrect = radioSelected === correctAnswers[0].id.toString()
            handleAnswer(isCorrect, false)
            return
        }

        const selectedChoices = getSelectedChoices()
        const allOf: boolean = correctAnswers.length === quiz.choices.length

        if (allOf) {
            handleAnswer(selectedChoices.length === correctAnswers.length, selectedChoices.length !== correctAnswers.length)
            return
        }

        const incorrectAnswers = getAnswers(false)
        let incorrects = 0

        for (const i of selectedChoices) {
            if (incorrectAnswers.includes(i)) {
                ++incorrects
                handleAnswer(false, false, getIncorrectAnswerFeedback(i.id.toString()))
                break
            }
        }

        incorrects === 0 && handleAnswer(selectedChoices.length === correctAnswers.length, selectedChoices.length !== correctAnswers.length)
    }

    const handleAnswer = (isCorrect: boolean, isIncomplete: boolean, incorrectFeedback?: string) => {

        if (isCorrect) {
            setQuizComplete(true)
            showFeedback(isCorrect)
            return
        }

        setQuizComplete(false)
        showFeedback(isCorrect, isIncomplete ? quiz.feedback.incomplete : incorrectFeedback)
    }

    const getIncorrectAnswerFeedback = (choiceID: string): string => {
        const selectedChoice = quiz.choices.find((choice: QuizChoice) => choice.id === +choiceID)
        return selectedChoice ? selectedChoice.feedback.incorrect : ''
    }

    const getAnswers = (isCorrect: boolean) => quiz.choices.filter((i) => i.isCorrect === isCorrect)

    const showFeedback = (isCorrect: boolean, incorrectFeedback?: string) => {
        const { type } = quiz

        const title = isCorrect
            ? "<div class='font-weight-bold text-success'>You are correct!</div>"
            : incorrectFeedback === quiz.feedback.incomplete
                ? "<div class='font-weight-bold text-warning'>Incomplete answer.</div>"
                : "<div class='font-weight-bold text-danger'>This is not correct.</div>"
        const body = isCorrect
            ? quiz.feedback.correct || "<div class='alert alert-danger'>Undefined correct feedback message.</div>"
            : type === QuizAnswerType.SingleChoice
                ? getIncorrectAnswerFeedback(radioSelected)
                : incorrectFeedback ? incorrectFeedback : "<div class='alert alert-danger'>Undefined feedback message.</div>"
        dialogContent.current = { title, body }
        toggleDialog()
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target

        if (element.type === 'checkbox') {
            const items = [...checkedItems]

            element.checked
                ? items.push(element.value)
                : items.splice(items.indexOf(element.value), 1)

            setCheckedItems(items)
            checkedItems.length > 0 && setFormValid(true)
            return
        }
        setRadioSelected(element.value)
        element.value !== '' && !formValid && setFormValid(true)
    }

    const type = quiz.type === QuizAnswerType.SingleChoice ? 'radio' : 'checkbox'

    const buttonDisabled: boolean =
        type === 'radio'
            ? radioSelected === ''
            : checkedItems.length === 0

    const quizChoices = quiz.choices.map((choice) => {
        const isSelected: boolean = type === 'checkbox'
            ? checkedItems.filter((i) => i === choice.id.toString()).length === 1
            : radioSelected === choice.id.toString()
        const { id, text } = choice
        return (
            <Form.Check
                id={id.toString()}
                key={id}
                type={type}
                name={text}
                label={text}
                value={id.toString()}
                checked={isSelected}
                onChange={onChange}
            />
        )
    })

    return (
        <div className="panel-quiz">
            <Form onSubmit={onSubmit}>
                {ReactHtmlParser(quiz.text)}
                <div className="mb-3 mt-3">
                    <div className='checkbox-list'>
                        {quizChoices}
                    </div>
                </div>
                {
                    !formValid && (
                        <Alert variant="danger">
                            {`You must select at least one answer.`}
                        </Alert>
                    )
                }
                <div className='button-bar'>
                    <Button
                        className="button primary"
                        type="submit"
                        disabled={buttonDisabled}
                    >
                        {`OK`}
                    </Button>
                </div>
            </Form>
            {
                quizAlreadyComplete && (
                    <Alert variant='success'>
                        {`You have previously answered this question correctly. You may try again or skip it.`}
                    </Alert>
                )
            }

            <Dialog
                title={dialogContent.current?.title}
                visible={showDialog}
                onHide={toggleDialog}
            >
                {ReactHtmlParser(dialogContent.current?.body)}
            </Dialog>
        </div>
    )
}
export default PanelQuiz