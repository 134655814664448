import { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { WarningIcon } from '../styles/Icons'

interface Props {
    title?: ReactNode
    message: ReactNode
    children?: ReactNode
}

const ErrorBox: FC<Props> = ({ title = '', message, children }: Props) => {
    return (
        <div style={{ margin: '2em' }}>
            <ErrorMessage className="alert alert-danger text-center">
                <h2>
                    <WarningIcon />{`  `}{title}
                </h2>
                <p>{message}</p>
                {children && <div>{children}</div>}
            </ErrorMessage>
        </div>
    )
}
export default ErrorBox

const ErrorMessage = styled.div({
    padding: '2em',
    maxWidth: '31.25em'
})