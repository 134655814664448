import DataTable, { TableColumn, ConditionalStyles, TableStyles } from 'react-data-table-component'

import {
    EnvironmentVariableMappings, EnvironmentVariableMapping, getOverrideValue,
    getQueryStringOverrides, hasActiveOverrides, isEnvVarOverridden,
} from '../configs'

import { NoDataComponent } from '.'
import { SortDownIcon } from '../styles/Icons'
import { customDataTableStyles } from '../styles/Theme'

type OverrideTableValues = {
    displayText: string
    isOverridden: boolean
}

const queryStringOverrides = getQueryStringOverrides()

const overridesActive = hasActiveOverrides(queryStringOverrides)

const getOverrideTableValues = (row: EnvironmentVariableMapping): OverrideTableValues => {
    const qsKey = row.queryStringOverride
    if (qsKey === undefined) return { displayText: '', isOverridden: false }

    const override = getOverrideValue(qsKey, queryStringOverrides)

    return {
        displayText: `${qsKey}: ${override !== undefined && override !== null ? override.toString() : 'N/A'}`,
        isOverridden: isEnvVarOverridden(override, row.environmentVariableName),
    }
}

const caseInsensitiveSort = (rowA: EnvironmentVariableMapping, rowB: EnvironmentVariableMapping) => {
    const a = getOverrideTableValues(rowA).displayText.toLowerCase()
    const b = getOverrideTableValues(rowB).displayText.toLowerCase()

    if (a > b) return 1
    if (b > a) return -1
    return 0
}

const customSort = (row1: EnvironmentVariableMapping, row2: EnvironmentVariableMapping) => {
    const override1 = getOverrideTableValues(row1)
    const override2 = getOverrideTableValues(row2)
    if (override1.isOverridden === override2.isOverridden) return 0
    if (override1.isOverridden) return -1
    return 1
}

const overridesSort = (row1: EnvironmentVariableMapping, row2: EnvironmentVariableMapping, hasActiveOverrides: boolean) => {
    if (hasActiveOverrides) return customSort(row1, row2)
    return caseInsensitiveSort(row1, row2)
}

const columns: TableColumn<EnvironmentVariableMapping>[] = [
    {
        id: 'name',
        name: 'Name',
        selector: (row) => row.environmentVariableName,
        sortable: true,
    },
    {
        id: 'vgName',
        name: 'Name in Variable Group',
        selector: (row) => row.nameInVariableGroup ? row.nameInVariableGroup : '',
        sortable: true,
        omit: true,
    },
    {
        id: 'value',
        name: 'Value',
        selector: (row) => {
            const envVar = process.env[row.environmentVariableName]
            return envVar ? envVar : ''
        },
        conditionalCellStyles: [
            {
                when: (row) => getOverrideTableValues(row).isOverridden,
                style: { textDecoration: 'line-through', }
            }
        ],
        wrap: true,
    },
    {
        id: 'override',
        name: 'Query String Override',
        selector: (row) => getOverrideTableValues(row).displayText,
        sortFunction: (a, b) => overridesSort(a, b, overridesActive),
        wrap: true,
    }
]

const conditionalRowStyles: ConditionalStyles<EnvironmentVariableMapping>[] = [
    {
        when: (row) => getOverrideTableValues(row).isOverridden,
        style: { color: 'red', }
    }
]

const EnvironmentVariablesTable = () => (
    <div>
        <DataTable
            noHeader={true}
            columns={columns}
            data={EnvironmentVariableMappings}
            highlightOnHover={true}
            pointerOnHover={false}
            striped={true}
            sortIcon={<SortDownIcon />}
            defaultSortFieldId={overridesActive ? 'override' : 'name'}
            pagination={true}
            paginationPerPage={15}
            paginationRowsPerPageOptions={[10, 15, 20]}
            customStyles={dataTableStyles}
            conditionalRowStyles={conditionalRowStyles}
            dense={true}
            noDataComponent={<NoDataComponent text={`There are no Environment Variables available.`} />}
        />
    </div>
)

export default EnvironmentVariablesTable

const dataTableStyles: TableStyles = {
    ...customDataTableStyles,
    tableWrapper: {
        style: {
            minWidth: '70rem',
            maxWidth: '90rem',
            width: '100%',
            height: '100%',
            margin: 'auto',
        },
    },
}