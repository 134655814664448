import { toBoolean } from '../common/utils'
import { valueToAchievementLevel } from '../utils'
import { ElectricalCode, SimutechModule } from '../common/types'
import { getQueryStringOverrides } from '.'

export const isDebug = toBoolean(process.env.REACT_APP_IS_DEBUG)

export const simulationModuleId = process.env.REACT_APP_MODULE_ID as SimutechModule

if (!simulationModuleId)
    throw new Error(`Simulation module ID is not defined. ${isDebug
        ? `If you are running this locally, in your '.env.development.local' environment file, set the 
        REACT_APP_MODULE_ID environment variable to the simulation's module ID. If you are running
           this from another environment, configure the release pipeline's 'SimulationModuleId' 
           environment variable.`
        : 'Please contact your system administrator.'}`)

if (!Object.values(SimutechModule).includes(simulationModuleId))
    throw new Error(`Simulation module ID ${simulationModuleId} is invalid.`)

const queryStringOverrides = getQueryStringOverrides()

if (isDebug && queryStringOverrides) {
    console.log('Query string overrides:')
    console.table(queryStringOverrides)
}

// Don't allow setting only the username without the userid
if (queryStringOverrides?.userName && !queryStringOverrides.userId)
    throw Error(`Query string 'userName' is set but 'userId' is not.`)

const webBuild = process.env.REACT_APP_WEB_BUILD_NUMBER
const unityBuild = process.env.REACT_APP_UNITY_BUILD_NUMBER
const webCommitID = process.env.REACT_APP_WEB_COMMIT_ID.substring(0, 8)
const unityCommitID = process.env.REACT_APP_UNITY_COMMIT_ID.substring(0, 8)

export const build = `${unityBuild} : ${webBuild}`
export const commitID = `${unityCommitID} : ${webCommitID}`
export const releaseDate = process.env.REACT_APP_RELEASE_DATE
export const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME
export const showDebugMenu = queryStringOverrides?.showDebugTools ?? toBoolean(process.env.REACT_APP_SHOW_DEBUG_MENU)


export const showReplaceCostTime = queryStringOverrides?.showReplaceCostTime ?? toBoolean(process.env.REACT_APP_SHOW_REPLACE_COST_TIME)
export const hintsEnabled = queryStringOverrides?.hintsEnabled ??
    process.env.REACT_APP_HINTS_ENABLED === undefined
    ? true
    : toBoolean(process.env.REACT_APP_HINTS_ENABLED)

export const debugUserId = queryStringOverrides?.userId ?? process.env.REACT_APP_USERID
export const debugUserName = queryStringOverrides?.userName ?? process.env.REACT_APP_USERNAME
export const debugCompanyId = queryStringOverrides?.companyId ?? process.env.REACT_APP_COMPANY_ID
export const debugCompanyName = process.env.REACT_APP_COMPANY_NAME

export const debugCanSelectFault = queryStringOverrides?.canSelectFaults ?? toBoolean(process.env.REACT_APP_CAN_SELECT_FAULT)
export const debugShowDisabledFaults = queryStringOverrides?.showDisabledFaults ?? toBoolean(process.env.REACT_APP_SHOW_DISABLED_FAULTS)
export const debugCanUseDisabledFaults = queryStringOverrides?.canUseDisabledFaults ?? toBoolean(process.env.REACT_APP_CAN_USE_DISABLED_FAULTS)
export const debugCompleteOnFirstFault = queryStringOverrides?.completeOnFirstFault ?? toBoolean(process.env.REACT_APP_COMPLETE_ON_FIRST_FAULT)

const envCompleteOnLevel = process.env.REACT_APP_COMPLETE_ON_LEVEL
export const debugCompleteOnLevel = queryStringOverrides?.completeOnLevel ?? envCompleteOnLevel === undefined
    ? undefined
    : valueToAchievementLevel(envCompleteOnLevel)

const envMiniSimLevel = process.env.REACT_APP_MINI_SIM_LEVEL
export const debugMiniSimLevel = queryStringOverrides?.miniSimLevel ?? envMiniSimLevel === undefined
    ? undefined
    : valueToAchievementLevel(envMiniSimLevel)

export const debugCustomTestId = queryStringOverrides?.isCustomTest ? '000000' : null
export const debugCustomTestMaxTries = queryStringOverrides?.maxNumberOfAttempts
export const debugCustomTestShowResults = queryStringOverrides?.showResults

export const helpURL: string = process.env.REACT_APP_DEFAULT_HELP_URL
export const lmsRequired: boolean = toBoolean(process.env.REACT_APP_LMS_REQUIRED)

export const defaultElectricalCode = queryStringOverrides?.electricalCode ?? process.env.REACT_APP_DEFAULT_ELECTRICAL_CODE as ElectricalCode

if (!(Object.keys(ElectricalCode).includes(defaultElectricalCode)))
    throw new Error(`Invalid electrical code: ${defaultElectricalCode}`)