import { ReactNode } from 'react'
import {
    AchievementLevel, Component, ElectricalCode,
    ModuleClass, SimutechModule,
} from '../common/types'

export enum FaultMode {
    Explore = 'Explore',
    Troubleshoot = 'Troubleshoot',
}

export type User = {
    userId: string
    userName: string
    companyId: string
    companyName: string
    clientId: string
    moduleId: string
    moduleLevel?: AchievementLevel | null
    electricalCode: ElectricalCode
    completeOnLevel: AchievementLevel | null
    completeOnFirstFault: boolean
    lmsSessionId: string
    courseTrackerId: number
    customTestId: string | null
    canSelectFaults: boolean
    canUseDisabledFaults: boolean
}

export type SimulationModule = {
    type: ModuleClass
    moduleId: SimutechModule
    moduleHelpURL: string
    company: string
    ldlInfos: LDLItem[]
    diagrams: ResourceImage[]
    faultModeMessage?: {
        explore: string
        troubleshoot: string
        customTest: string
    }
    isLab: boolean
    isTS: boolean
}

export type CustomTestFault = {
    faultId: string
    currentNumberOfAttempts: number
    faultIsSelectable: boolean
    userCanAttemptFault: boolean
}

export type CustomTestFaultWithNumber = CustomTestFault & { faultNumber: string }

export type CustomTestState = {
    maxNumberOfAttempts: number
    faultList: CustomTestFault[]
    displayFaultAttemptResults: boolean
}


export type Hint = {
    id: string
    text: string
}

export type ResourceImage = {
    name: string
    nameIEC?: string
    filename: string
}

export type LDLItem = {
    labs: string[]
    title: string
    tableHeaders: string[]
    steps: Array<{
        step: number
        test: string
        result: {
            [electricalCode: string]: string
        }
    }>
}

export type Part = {
    solverPartID: string
    displayPartID: string
    partType: string
    cost?: number
    time?: number
}

export enum PartAction {
    Replace = 'Replace',
    ViewSpecs = 'View Specs'
}

export enum SafetyExceptionType {
    Zap = 'Zap',
    Stop = 'Stop',
    Caution = 'Caution',
    SafetyTip = 'SafetyTip',
    MeterTip = 'MeterTip',
    ScrewdriverTip = 'ScrewdriverTip',
    Warning = 'Warning'
}

export enum SoundEffect {
    Cash = 'Cash',
    Zap = 'Zap',
    Caution = 'Caution',
    Notify = 'Notify'
}

export type SafetyMessage = {
    ExceptionType: SafetyExceptionType
    title: string
    message: string
}

export type DialogContent = {
    title: string
    body: string
    buttons?: ReactNode[] | null
    onHide: () => void
}


export type InitialState = {
    Model: {
        InitialState?: any
        InitialParameterState?: InitialParameterState
    }
}
export type InitialParameterState = {
    Hints?: {
        MaxHints?: number | null
    }
}

// Learning Lab Types

export type ModuleContent = {
    title: string
    description: string
    customTestDescription?: string
    sections: Section[]
}

export enum Availability {
    Disabled = 'section-under-construction'
}

export type Section = {
    LRSID: string
    debugOnly?: boolean
    name: string
    description: string
    lessons: Lesson[]
}

export type Lesson = {
    LRSID: string
    moduleId?: SimutechModule
    faultID?: string
    name: string
    panels: LessonPanel[]
}

export type LessonPanel = {
    title: string
    text: string
    image?: PanelImage
    video?: PanelVideo
    quiz?: Quiz
}

export type PanelImage = {
    filename: string
}

export type PanelVideo = {
    filename: string
}

export type Quiz = {
    type: QuizAnswerType
    text: string
    choices: QuizChoice[]
    feedback: {
        correct: string
        incomplete: string
    }
}

export type QuizChoice = {
    id: number
    text: string
    isCorrect: boolean
    feedback: {
        correct: string
        incorrect: string
    }
}

export enum QuizAnswerType {
    MultipleChoice = 'multiple-choice',
    SingleChoice = 'single-choice',
}

export type WorkOrder = {
    Name: string
    Date: string
    OrderNumber: string
    StatusOfRepair: string
    ReportedProblem: string
    Comment: string
}

export type FaultAttempt = {
    skillRating: number
    efficiencyScore: number
    efficiencyRating: number
    accuracyScore: number
    accuracyRating: number
    safetyScore: number
    safetyRating: number
    baseScore: number
    overallScore: number
    faultFixed: boolean
    faultOperational: boolean
    efficiencyPenalties: EfficiencyPenalty
    accuracyPenalties: AccuracyPenalty
    safetyPenalties: SafetyPenalty
    performanceSummary: PerformanceSummary
    achievementSummary: {
        bumpNextLevel: boolean
    }
}

type EfficiencyPenalty = {
    seatTimeBonusScore: number
    seatTimePenaltyScore: number
    meterReadingsPenaltyScore: number
    enclosurePenaltyScore: number
    inspectionPenaltyScore: number
    benchTestPenaltyScore: number
    meterTipPenaltyScore: number
    hintPenaltyScore: number
    lockoutsPenaltyScore: number
}

type AccuracyPenalty = {
    numberUnnecessaryReplacedComponents: number
    numberUnnecessaryRepairedComponents: number
    numberUnnecessaryAdjustments: number
    numberUnnecessaryCalibrations: number
    allowedReplacementPenaltyScore: number
    allowedFusePenaltyScore: number
    allowedPLCDownloadsPenaltyScore: number
    allowedPLCSettingsPenaltyScore: number
    allowedRepairsPenaltyScore: number
    allowedAdjustmentsPenaltyScore: number
    allowedCalibrationsPenaltyScore: number
}

type SafetyPenalty = {
    stopPenalties: number
    cautionPenalties: number
    safetyTipPenalties: number
    warningPenalties: number
}

type PerformanceSummary = {
    seatTimeMilliseconds: number
    seatTimeMinutes: number
    voltmeterReadingsTaken: number
    ohmmeterReadingsTaken: number
    ammeterReadingsTaken: number
    totalMeterReadings: number
    enclosuresOpened: number
    lockoutsPerformed: number
    inspectionsPerformed: number
    benchTestsPerformed: number
    meterTipsIssued: number
    hintsTaken: number
    componentsReplaced: Component[]
    componentsRepaired: string[]
    componentsAdjusted: string[]
    componentsCalibrated: string[]
    fusesReplaced: number
    PLCProgramsDownloaded: number
    PLCProgramSettingChanges: number
    stopLiveCircuitErrorOccurrences: number
    cautionSafeWorkplacePracticeOccurrences: number
    safetyTipMessagesReceived: number
    warningMessagesReceived: number
    PLCNotInRunModeLeaveAttempt: boolean
    RepairedNotOperationalLeaveAttempt: boolean
    exitWithoutSolvingFault: boolean
    exitWithoutCircuitOperational: boolean
    exitByZapPersonalInjuryError: boolean
    exitByProcedureError: boolean
    totalTimeMinutes: number
    totalCost: number
}

enum MasteryLevelName {
    Basic = 'Basic',
    Intermediate = 'Intermediate',
    Advanced = 'Advanced',
    Genius = 'Genius',
    Master = 'Master',
}

export type MasteryLevel = {
    level: AchievementLevel
    name: MasteryLevelName
}

export const masteryLevels: MasteryLevel[] = [
    { level: AchievementLevel.Basic, name: MasteryLevelName.Basic },
    { level: AchievementLevel.Intermediate, name: MasteryLevelName.Intermediate },
    { level: AchievementLevel.Advanced, name: MasteryLevelName.Advanced },
    { level: AchievementLevel.Genius, name: MasteryLevelName.Genius },
    { level: AchievementLevel.Master, name: MasteryLevelName.Master }
]

export type ScoreboardDataPoint = {
    name: string
    description: string
    value: number
}

export type ScoreboardData = {
    overallScore: ScoreboardDataPoint
    levelAchieved: ScoreboardDataPoint
    faultsAttempted: number
    faultsSolved: number
    faultPercentage: number
    accuracyScore: ScoreboardDataPoint
    efficiencyScore: ScoreboardDataPoint
    safetyScore: ScoreboardDataPoint
    skillRating: ScoreboardDataPoint
}