import { useState, useEffect, FC, CSSProperties, MouseEvent, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import ReactHtmlParser from 'html-react-parser'

import { useModuleContext } from '../../contexts'
import { useLearningLab, useToggle } from '../../hooks'

import { preventContextMenu } from '../../utils'
import { generalLogger as GeneralLogger } from '../../Loggers'
import { Availability } from '../../interfaces'

import { ReactComponent as PanelSwitchOpen } from '../../assets/icons/panel-switch-open.svg'
import { ReactComponent as PanelSwitchClose } from '../../assets/icons/panel-switch-close.svg'
import { PanelQuiz } from './'
import { ImageViewer, LessonButton, VideoPlayer } from '.'
import './LearningPanel.scss'
import { isEmpty } from '../../common/utils'

const LearningPanel: FC = () => {
    const { moduleState: { userProfile, moduleContent: { sections } } } = useModuleContext()
    const {
        loadLesson,
        setSectionAsStarted,
        setLessonAsStarted,
        setLessonAsCompleted,
        lrsID,
        assetsPath,
        saveBookmark,
        getLRSState,
        LRSState,
        validateQuiz,
    } = useLearningLab()

    const navigate = useNavigate()
    const goHome = () => navigate(`/`, { replace: true })

    const { sectionIndex, lessonIndex } = useParams()
    const currentSectionIndex = Number(sectionIndex || 0)
    const currentLessonIndex = Number(lessonIndex || 0)

    const isDebugOnlySection = sections[currentSectionIndex].debugOnly

    const panels = sections[currentSectionIndex].lessons[currentLessonIndex].panels

    const bookmark = userProfile?.['module-bookmark']
    const bookmarkedPanel = bookmark
        && bookmark.moduleVersion === userProfile.version
        && getLRSState(currentSectionIndex, currentLessonIndex) !== LRSState.Completed // Don't use the bookmark if the user has already completed the lesson        
        && bookmark.section === currentSectionIndex
        && bookmark.lesson === currentLessonIndex
        && !!(panels[bookmark.panel])
        ? bookmark.panel
        : 0

    const [currentPanelIndex, setCurrentPanelIndex] = useState(bookmarkedPanel)

    const [showPanel, togglePanel] = useToggle(true)
    const [showPlayer, togglePlayer] = useToggle(false)
    const [showImage, toggleImage] = useToggle(false)

    const currentPanel = panels[currentPanelIndex]
    const isFirstLesson = currentLessonIndex === 0
    const isLastPanel = panels.length - 1 === currentPanelIndex
    const isFirstPanel = currentPanelIndex === 0
    const isLastLesson = sections[currentSectionIndex].lessons.length - 1 === currentLessonIndex
    const isLastSection = sections.length - 1 === currentSectionIndex
        || sections[currentSectionIndex + 1]?.debugOnly // The next section is a debug only section
        || (isDebugOnlySection && sections.length - 1 === currentSectionIndex) //TODO: Figure this out.

    const hasQuiz = !!currentPanel.quiz && !isEmpty(currentPanel.quiz)

    const [quizComplete, setQuizComplete] = useState(false)

    const shouldConsiderQuizComplete: boolean = hasQuiz
        && (
            getLRSState(currentSectionIndex) === LRSState.Completed
            || getLRSState(currentSectionIndex, currentLessonIndex) === LRSState.Completed
            || (!!bookmark
                && (currentSectionIndex === bookmark.section && currentLessonIndex === bookmark.lesson && currentPanelIndex < bookmark.panel)
            )
        )

    useEffect(() => {
        // Quiz validation
        if (!hasQuiz || quizComplete || shouldConsiderQuizComplete) return

        try {
            if (hasQuiz) validateQuiz(currentPanel.quiz!) // We do a check above to make sure that a quiz exists, but Typescript does not know that.
        }
        catch (error) {
            throw new Error(`Invalid quiz object for panel ${currentPanelIndex} in lesson ${currentLessonIndex} in section ${currentSectionIndex}. ${error}`)
        }
    }, [currentLessonIndex, currentPanel.quiz, currentPanelIndex, currentSectionIndex, hasQuiz, quizComplete, shouldConsiderQuizComplete, validateQuiz])

    useEffect(() => {
        // Bookmark saving

        // Don't save the bookmark if the user is in a Debug Only section or on the first or last panel
        if (isFirstPanel || isLastPanel || isDebugOnlySection || !userProfile || getLRSState(currentSectionIndex, currentLessonIndex) === LRSState.Completed) return

        if (!bookmark
            || (currentSectionIndex > bookmark.section
                || (currentSectionIndex === bookmark.section && currentLessonIndex > bookmark.lesson)
                || (currentSectionIndex === bookmark.section && currentLessonIndex === bookmark.lesson && currentPanelIndex > bookmark.panel))
        )
            saveBookmark({ moduleVersion: userProfile.version, section: currentSectionIndex, lesson: currentLessonIndex, panel: currentPanelIndex })

    }, [LRSState.Completed, bookmark, currentLessonIndex, currentPanelIndex, currentSectionIndex, getLRSState, isDebugOnlySection, isFirstPanel, isLastPanel, saveBookmark, userProfile])

    useEffect(() => {
        // TODO: Verify this.
        setQuizComplete(false)
    }, [])

    const closeMedia = useCallback(() => {
        if (showPlayer) togglePlayer()
        if (showImage) toggleImage()
    }, [showImage, showPlayer, toggleImage, togglePlayer])

    const handleNextLesson = () => {
        loadLesson(currentSectionIndex, currentLessonIndex + 1)
        navigate(`/lab/${sectionIndex}/${(currentLessonIndex + 1).toString()}`, { replace: true })
        setCurrentPanelIndex(0)
    }

    const handleNextSection = useCallback(() => {
        setCurrentPanelIndex(0)
        loadLesson(currentSectionIndex + 1, 0)
        navigate(`/lab/${(currentSectionIndex + 1).toString()}/0`, { replace: true })
    }, [currentSectionIndex, loadLesson, navigate])

    const handlePanelSwitch = useCallback((e: MouseEvent) => {
        e?.preventDefault()
        togglePanel()
        if (showPlayer) togglePlayer()
        if (showImage) toggleImage()
    }, [showImage, showPlayer, toggleImage, togglePanel, togglePlayer])

    const previousLearningPanel = useCallback((e: MouseEvent) => {
        e && e.preventDefault()
        closeMedia()
        !isFirstPanel && setCurrentPanelIndex(currentPanelIndex - 1)
    }, [closeMedia, currentPanelIndex, isFirstPanel])

    const nextLearningPanel = useCallback((e: MouseEvent) => {
        e && e.preventDefault()
        closeMedia()
        !isLastPanel && setCurrentPanelIndex(currentPanelIndex + 1)
    }, [closeMedia, currentPanelIndex, isLastPanel])

    useEffect(() => {
        if (!isFirstPanel) return

        isFirstLesson && setSectionAsStarted(currentSectionIndex)
        setLessonAsStarted(currentSectionIndex, currentLessonIndex)
    }, [currentLessonIndex, currentSectionIndex, isFirstLesson, isFirstPanel, setLessonAsStarted, setSectionAsStarted])

    useEffect(() => {
        if (!isLastPanel || isDebugOnlySection || getLRSState(currentSectionIndex, currentLessonIndex) === LRSState.Completed) return

        GeneralLogger.log('Handling last panel...')
        try {
            setLessonAsCompleted(currentSectionIndex, currentLessonIndex)
        }
        catch (error) {
            GeneralLogger.error('Error setting lesson as completed:\n', error)
        }
    }, [LRSState.Completed, currentLessonIndex, currentSectionIndex, getLRSState, isDebugOnlySection, isLastPanel, setLessonAsCompleted, userProfile])

    if (!panels.length) throw new Error('This lesson is misconfigured! No panels found!')

    const isNextLessonDisabled = !isLastLesson &&
        lrsID(currentSectionIndex, currentLessonIndex + 1) === Availability.Disabled
    const isNextSectionDisabled = isLastLesson && !isLastSection &&
        lrsID(currentSectionIndex + 1, undefined) === Availability.Disabled

    const videoPath = currentPanel.video && !isEmpty(currentPanel.video)
        ? assetsPath + 'videos/' + currentPanel.video.filename : ''
    const imagePath = currentPanel.image && !isEmpty(currentPanel.image)
        ? assetsPath + 'images/' + currentPanel.image.filename : ''

    const nextPanelButtonDisabled: boolean = isLastPanel
        ? true
        : shouldConsiderQuizComplete ? false : hasQuiz && !quizComplete

    return (
        <>
            <div onContextMenu={preventContextMenu} className="learning-area">
                <div className="learning-panel interactive" style={panelStyle(showPanel)}>
                    <div className="learning-content">
                        <h4 className="panel-title">{currentPanel.title}</h4>
                        <div className="panel-text">
                            {ReactHtmlParser(currentPanel.text)}
                        </div>

                        <LessonButton
                            cssClass={showImage ? 'primary' : 'secondary'}
                            text={showImage ? 'Close Image' : 'Show Image'}
                            onButtonClick={toggleImage}
                            disabled={showPlayer}
                            visible={currentPanel.image && !isEmpty(currentPanel.image)}
                        />

                        <LessonButton
                            cssClass={showPlayer ? 'primary' : 'secondary'}
                            text={showPlayer ? 'Close Video' : 'Play Video'}
                            onButtonClick={togglePlayer}
                            disabled={showImage}
                            visible={currentPanel.video && !isEmpty(currentPanel.video)}
                        />

                        {
                            hasQuiz
                                ? (
                                    <PanelQuiz
                                        quiz={currentPanel.quiz!}
                                        setQuizComplete={setQuizComplete}
                                        quizAlreadyComplete={shouldConsiderQuizComplete}
                                    />
                                )
                                : <></>
                        }

                        <LessonButton
                            cssClass='secondary'
                            text='Leave Lesson'
                            onButtonClick={goHome}
                            visible={isLastPanel && !isLastLesson}
                        />

                        <LessonButton
                            cssClass='secondary'
                            text='Continue to Next Lesson'
                            onButtonClick={handleNextLesson}
                            visible={isLastPanel && !isLastLesson && !isNextLessonDisabled}
                        />

                        <LessonButton
                            cssClass='secondary'
                            text='Continue to Next Section'
                            onButtonClick={handleNextSection}
                            visible={isLastPanel && isLastLesson && !isLastSection && !isNextSectionDisabled}
                        />

                        <LessonButton
                            cssClass='secondary'
                            text='Go to Home Page'
                            onButtonClick={goHome}
                            visible={(isLastPanel && isLastLesson) || (isLastPanel && isNextLessonDisabled)}
                        />

                    </div>
                    <div className="panel-nav">
                        <div className="navbuttons">
                            <Button
                                className="previous-panel"
                                disabled={isFirstPanel}
                                onClick={previousLearningPanel}
                            />
                            <p>{currentPanelIndex + 1} / {panels.length}</p>
                            <Button
                                className="next-panel"
                                disabled={nextPanelButtonDisabled}
                                onClick={nextLearningPanel}
                            />
                        </div>
                    </div>
                    <div className="panel-switch" onClick={handlePanelSwitch}>
                        {showPanel ? <PanelSwitchClose /> : <PanelSwitchOpen />}
                    </div>
                </div>
                <VideoPlayer
                    visible={showPlayer && !!videoPath}
                    onClose={togglePlayer}
                    videoPath={videoPath}
                    containerCSS={mediaAreaStyle(showPanel)}
                />
                <ImageViewer
                    visible={showImage && !!imagePath}
                    onClose={toggleImage}
                    imagePath={imagePath}
                    containerCSS={mediaAreaStyle(showPanel)}
                />

            </div>
        </>
    )
}
export default LearningPanel

const panelStyle = (showPanel: boolean) => ({
    animation: `${showPanel ? 'show' : 'hide'}-learning-panel ease .5s alternate`,
    left: `${showPanel ? '0' : '-21.875em'}`,
    boxShadow: `${showPanel ? '4px 4px 6px -4px rgba(0, 0, 0, 0.47)' : 'none'}`
} as CSSProperties)

const mediaAreaStyle = (showPanel: boolean) => ({
    animation: `${!showPanel ? 'shrink' : 'grow'}-video-area ease 0s alternate`,
    width: `${!showPanel ? '73.75%' : '100%'}`,
    left: `${!showPanel ? '-21.875em' : '0'}`,
} as CSSProperties)