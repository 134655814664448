import { useState, useCallback, useEffect } from 'react'

import { resourceLookupService as RLUService } from '../Services'
import { simMod } from '../configs'
import { useLearningLabContext, useModuleContext } from '../contexts'

import { ModuleClass, PartSpecsRequest } from '../common/types'

const usePartSpecsImage = (partType: string) => {
    const { moduleState: { user } } = useModuleContext()
    const { llState: { lessonModuleId } } = useLearningLabContext()
    const [imgURL, setImgURL] = useState('')

    const fetchSpecs = useCallback(async () => {
        if (!user) throw new Error('Tried to fetch part specs without a user.')

        const partSpecRequest: PartSpecsRequest = {
            electricalCode: user.electricalCode,
            moduleId: simMod.type === ModuleClass.LL ? lessonModuleId : simMod.moduleId,
            partName: partType
        }
        const url = await RLUService.getPartSpecsImageURL(partSpecRequest)

        if (url) setImgURL(url)
    }, [lessonModuleId, user, partType])

    useEffect(() => {
        if (!partType || imgURL !== '') return
        fetchSpecs()

    }, [fetchSpecs, imgURL, partType])

    return imgURL
}
export default usePartSpecsImage