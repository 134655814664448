import { SimutechModule } from './ModuleTypes'

export enum ElectricalCode {
    NEC = 'NEC',
    IEC = 'IEC'
}

export const ElectricalCodeName = {
    [ElectricalCode.NEC]: 'North American',
    [ElectricalCode.IEC]: 'International'
}

export type User = {
    userId: string
    userName: string
    companyId: string
    companyName: string
    clientId: string
    moduleId: string
    moduleLevel?: AchievementLevel | null
    electricalCode: ElectricalCode
    completeOnLevel: AchievementLevel | null
    completeOnFirstFault: boolean
    lmsSessionId: string
    courseTrackerId: number
    canSelectFaults: boolean
    canUseDisabledFaults: boolean
}

export type ReportingUser =
    Omit<User, 'electricalCode' | 'completeOnLevel' | 'completeOnFirstFault' | 'canSelectFaults' | 'canUseDisabledFaults'>
    & {
        lrsDbName: string
    }

export type LMSUser = {
    id: number
    firstName: string
    lastName: string
    langKey: string
    userTenant: {
        clientName: string
        clientId: string
        lrsCompanyId: string
        environment: string
        canSelectFaults: CanSelectFault
        lrsDbName: string
    }
}

export enum AchievementLevel {
    NA,
    Basic,
    Intermediate,
    Advanced,
    Genius,
    Master
}

export enum FaultState {
    Repaired = 'Repaired',
    NotRepaired = 'NotRepaired',
    Abandoned = 'Abandoned'
}

export type ScoringResults = {
    'overall-score': number
    'skill-rating': number
    'safety-rating': number
    'accuracy-rating': number
    'efficiency-rating': number
    'seat-time-bonus-score': number
    'seat-time-penalty-score': number
    'meter-readings-penalty-score': number
    'enclosure-penalty-score': number
    'inspection-penalty-score': number
    'bench-test-penalty-score': number
    'meter-tip-penalty-score': number
    'hint-penalty-score': number
    'lockouts-penalty-score': number
    'allowed-replacement-penalty-score': number
    'allowed-fuse-penalty-score': number
    'allowed-plc-downloads-penalty-score': number
    'allowed-plc-settings-penalty-score': number
    'allowed-repairs-penalty-score': number
    'allowed-adjustments-penalty-score': number
    'allowed-calibrations-penalty-score': number
    'stop-penalties-score': number
    'caution-penalties-score': number
    'safety-tip-penalties-score': number
    'warning-penalties-score': number
    DefectType: string
    Defect: string
}

export type Component = {
    Name: string
    PartType: string
    Cost: number
    Time: number
}

export type PerformanceMetrics = {
    RusticiCCAccountID: string
    RusticiCCAccountName: string
    LearningSessionID: string
    faultAttemptID: string
    ModuleIDLong: string
    ModuleIDShort: string
    ModuleFaultID: string
    'seat-time': number
    'voltmeter-readings': number
    'ohmmeter-readings': number
    'ammeter-readings': number
    lockouts: number
    'meter-tips-received': number
    'hints-taken': number
    'replaced-components': Component[]
    repairs: Component[]
    enclosures: string[]
    inspections: number
    'bench-tests': number
    adjustments: any[]
    calibrations: any[]
    'replaced-fuses': number
    'plc-program-downloads': number
    'plc-program-setting-changes': number
    'tightened-connections': string[]
    'stop-live-circuit-error-occurrences': number
    'caution-safe-workplace-practice-occurrences': number
    'safety-tip-messages-received': number
    'warning-messages-received': number
    'exit-without-solving-fault': boolean
    'exit-by-zap-personal-injury-error': boolean
    'attempt-to-leave-fault-with-plc-not-in-run-mode': boolean
    'attempt-to-leave-fault-with-fault-repaired-but-circuit-not-operational': boolean
    'exit-without-circuit-being-operational': boolean
    'exit-by-procedure-failure': boolean
    DefectType: string
    Defect: string
}

export type SessionInfo = {
    environment: string
    'build-number': string
    'commit-number': string
    'user-agent': string
    browser: string
    'browser-version': string
    os: string
    version: string
    'screen-size': string
    'browser-window-size': string

}

export type LabBookmark = {
    moduleVersion: number
    section: number
    lesson: number
    panel: number
}

export type FaultAttemptItem = {
    _id: string
    faultId: string
    timestamp: string
    faultState: FaultState
    skillRating: null | number
}

export type FullFaultAttempt = {
    _id: string
    faultId: string
    timestamp: string
    faultState: FaultState
    scoringResults: ScoringResults
    performanceMetrics: PerformanceMetrics
    parValuesMetrics?: {
        'allowed-replaced-components': string[]
        'allowed-repairs': string[]
    }
}

export enum CanSelectFault {
    Yes = 1,
    No,
    Development
}

export enum LMSLessonStatus {
    Passed = 'PASSED',
    Completed = 'COMPLETED',
    Failed = 'FAILED',
    Incomplete = 'INCOMPLETE',
    Browsed = 'BROWSED',
    NotAttempted = 'NOTATTEMPTED'
}

export type ModuleFault = {
    faultId: string
    isFaultSelectable: boolean
}

export interface PartSpecsRequest {
    'moduleId': SimutechModule
    'partName': string
    'electricalCode': ElectricalCode
}

export type ModuleSummaryResponse = {
    _id: string
    userName: string
    learningLabsTotalTime: number
    learningLabsPercentComplete: number
    troubleshootingSimsTotalTime: number
    troubleshootingSimsTotalFaultsAttempted: number
    troubleshootingSimsTotalFaultsSolved: number
    troubleshootingSimsOverallSafety: number
    troubleshootingSimsOverallAccuracy: number
    troubleshootingSimsOverallEfficiency: number
}

export type FaultAttemptSummary = {
    _id: string
    faultDescription: string
    faultDefectType: string
    faultDifficultyLevel: string
    numberOfAttempts: number
    totalTrainingTime: number
    averageSafetyScore: number
    averageAccuracyScore: number
    averageEfficiencyScore: number
    averageSkillRating: number
    firstAttemptTimestamp: string
    lastAttemptTimestamp: string
}