import { useMemo, FC, MouseEvent, useCallback } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import DataTable, { TableColumn, ConditionalStyles } from 'react-data-table-component'

import { simMod } from '../../configs'
import { useModuleContext, useTroubleshootContext } from '../../contexts'
import { useCustomTestSettings } from '../../hooks'

import { CustomTestFaultWithNumber } from '../../interfaces'

import { NavigationBar, LoadingOverlay, NoDataComponent, ErrorBox } from '../'
import { customDataTableStyles, TableTitle, Wrapper } from '../../styles/Theme'
import { PrimaryButton, SecondaryButton, ButtonGroup } from '../../styles/Buttons'
import { SortDownIcon } from '../../styles/Icons'

const CustomTestFaultSelector: FC = () => {
    const navigate = useNavigate()
    const { moduleState: { user } } = useModuleContext()
    const {
        tsState: {
            customTestState,
            isCustomTest,
            selectedCustomTestFault
        },
        tsActions: { setSelectedCustomTestFault }
    } = useTroubleshootContext()

    if (simMod.isLab || !isCustomTest) navigate('/', { replace: true })

    const { isFaultAvailable } = useCustomTestSettings()

    const isBeginDisabled = !selectedCustomTestFault || !isFaultAvailable(selectedCustomTestFault)
    const rowSelected = useCallback((row: CustomTestFaultWithNumber) =>
        row.faultId === selectedCustomTestFault?.faultId, [selectedCustomTestFault])

    const faultList: CustomTestFaultWithNumber[] | null | undefined =
        customTestState?.faultList.map((fault, index) => {
            const faultWithNumber: CustomTestFaultWithNumber = {
                ...fault,
                faultNumber: `Fault ${index + 1}`,
                // Uncomment and modify these to override values from solver for testing.
                // faultIsSelectable: index === 2 ? false : true,
                // currentNumberOfAttempts: index === 3 ? 1 : 0,
            }
            return faultWithNumber
        })

    const goBack = () => navigate('/scoreboard')
    const onRowClick = useCallback((row: CustomTestFaultWithNumber, event: MouseEvent) => {
        event.preventDefault()
        if (!isFaultAvailable(row)) return
        setSelectedCustomTestFault(row)
    }, [isFaultAvailable, setSelectedCustomTestFault])

    const onStart = useCallback((e: MouseEvent) => {
        e.preventDefault()
        if (isBeginDisabled) return
        navigate(`/workorder/${selectedCustomTestFault.faultId}`)
    }, [isBeginDisabled, navigate, selectedCustomTestFault?.faultId])

    const columns: TableColumn<CustomTestFaultWithNumber>[] = [
        {
            name: 'Fault Number',
            selector: (row) => row.faultNumber,
            cell: (row) => `${row.faultNumber}${row.faultIsSelectable ? '' : ' (Disabled)'}`
        }
    ]

    const conditionalRowStyles: ConditionalStyles<CustomTestFaultWithNumber>[] = useMemo(() => [
        {
            when: (row) => !isFaultAvailable(row),
            style: {
                color: '#CCC',
                pointerEvents: 'none',
            },
        }
    ], [isFaultAvailable])

    if (!customTestState || !faultList || !faultList.length)
        return <ErrorBox message={'Configuration Error: No faults found in this Custom Curriculum.'} />

    if (!user)
        return (
            <div className="module-content">
                <NavigationBar />
                <Container className="pre-fault" fluid={true}>
                    <LoadingOverlay
                        active={!faultList}
                        text={`Loading list of faults`}
                    />
                </Container>
            </div>
        )

    return (
        <div className="module-content">
            <NavigationBar />
            <Wrapper>
                <TableTitle className={'title'}>
                    {'Fault Selection'}
                </TableTitle>
                <div>
                    <DataTable
                        noHeader={true}
                        columns={columns}
                        data={faultList}
                        highlightOnHover={true}
                        pointerOnHover={true}
                        onRowClicked={(row, event) => onRowClick(row, event)}
                        selectableRowsHighlight={true}
                        selectableRowsNoSelectAll={true}
                        selectableRowsSingle={true}
                        selectableRowSelected={rowSelected}
                        striped={true}
                        sortIcon={<SortDownIcon />}
                        pagination={true}
                        paginationPerPage={10}
                        paginationComponentOptions={{ noRowsPerPage: true }}
                        customStyles={customDataTableStyles}
                        conditionalRowStyles={conditionalRowStyles}
                        dense={true}
                        noDataComponent={<NoDataComponent text={`There are no faults available.`} />}
                    />
                </div>
                <ButtonGroup>
                    <PrimaryButton onClick={goBack}>
                        {'Back'}
                    </PrimaryButton>
                    {
                        faultList.length ? (
                            <SecondaryButton
                                disabled={isBeginDisabled}
                                onClick={onStart}
                            >
                                {
                                    selectedCustomTestFault && selectedCustomTestFault.faultId && !selectedCustomTestFault.faultIsSelectable && !user.canUseDisabledFaults
                                        ? 'FAULT DISABLED'
                                        : 'Begin Fault'
                                }
                            </SecondaryButton>
                        ) : <></>
                    }
                </ButtonGroup>
            </Wrapper>
        </div>
    )
}
export default CustomTestFaultSelector
