import { useCallback, useEffect, useRef } from 'react'
import { HubConnectionState } from '@microsoft/signalr'
import { v4 as uuid } from 'uuid'
import { nanoid } from 'nanoid'

import { simMod } from '../configs'
import {
    useModuleContext, useLMSContext, useTroubleshootContext,
    useLearningLabContext, useUnityContext, useSolverContext,
    useHintsContext
} from '../contexts'
import { useLMS, useLRS } from '.'
import { storeInSession } from '../common/utils'
import { solverLogger as SolverLogger, unityLogger as UnityLogger } from '../Loggers'

import { SimutechModule } from '../common/types'
import {
    CustomTestState, FaultAttempt, Hint,
    Part, WorkOrder
} from '../interfaces'

import { PrimaryButton } from '../styles/Buttons'

export enum SolverCommand {
    JoinGroup = 'JoinGroup',
    LeaveGroup = 'LeaveGroup',
    ProcessMessage = 'ProcessMessage',
    LoadScene = 'LoadScene',
    LoadFault = 'LoadFault',
    ExitFault = 'ExitFault',
    BeginFault = 'BeginFault',
    CompleteFault = 'CompleteFault',
    ReplaceItem = 'ReplaceItem',
    VerifyDeadPowerSupply = 'VerifyDeadPowerSupply',
    GetNextHint = 'GetNextHint',
    GetReplaceCostTime = 'GetReplaceCostTime'
}

export type SolverAcknowledgment = {
    CommandName: SolverCommand
    Id: string
    RequestReceived: string
    RequestAcknowledged: string
    ResponseSent: string
}

const useSolver = () => {
    const {
        moduleState: { user, errorDialog, criticalErrorMessage },
        moduleActions: {
            setCost,
            setTimeSpent,
            setCriticalErrorMessage,
            setErrorDialog,
        }
    } = useModuleContext()

    const {
        solverState: { connection, groupName, solverAvailable },
        solverActions: { doInitComplete }
    } = useSolverContext()

    const {
        tsActions: {
            saveWorkOrder,
            clearWorkOrder,
            saveFaultAttempt,
            resetTSContext,
            saveScoreboard,
            saveCustomTestState,
        }
    } = useTroubleshootContext()

    const { unityState: { unityLoaded, unityContext } } = useUnityContext()
    const { lmsState: { courseCompleted, isLMSAvailable } } = useLMSContext()
    const { llActions: { setLessonModuleId } } = useLearningLabContext()
    const { hintsActions: { setSelectedHint, addHintTaken } } = useHintsContext()

    const { concedeControl, incrementScore, setModulePassed } = useLMS()
    const { initUserProfile, getAndSetUserProfile } = useLRS()

    const initialized = useRef(false)
    const notConnected = connection.state !== HubConnectionState.Connected

    const resetTimeCost = useCallback(() => {
        setTimeSpent('00:00')
        setCost('0000.00')
    }, [setCost, setTimeSpent])

    const joinGroup = useCallback(async () => {
        if (connection.state === HubConnectionState.Disconnected || !user) return

        SolverLogger.log('Joining Solver Group...')

        const payload = JSON.stringify({
            Id: uuid(),
            GroupName: '',
            CommandName: SolverCommand.JoinGroup,
            CommandArguments: {
                groupName: '',
                user,
                moduleId: user.moduleId,
            }
        })
        await connection.invoke('ProcessMessage', payload)
    }, [connection, user])

    const simulationStarted = useCallback((connectionId: string, clientConnectionId: string, groupName: string) => {
        SolverLogger.success(`Simulation ${connectionId} has started and joined group ${groupName}`)
        try {
            storeInSession('groupName', groupName)
            const sessionStartTime = new Date().getTime()
            storeInSession('sessionStart', JSON.stringify(sessionStartTime))
            doInitComplete(groupName)
            initUserProfile()
        }
        catch (error) {
            if (error instanceof Error)
                setCriticalErrorMessage(error.toString())
        }

    }, [initUserProfile, setCriticalErrorMessage, doInitComplete])

    const workOrderUpdate = useCallback((data: string) => {
        try {
            const workOrder: WorkOrder = JSON.parse(data)
            workOrder.Date = new Date().toLocaleDateString(navigator.language)
            workOrder.OrderNumber = '00' + (Math.round(Math.random() * 9000) + 1000).toFixed(0)
            SolverLogger.info('Work Order update:\n', workOrder)
            saveWorkOrder(workOrder)
        }
        catch (error) {
            setErrorDialog({
                title: 'Error retrieving work order',
                body: 'There was a problem retrieving a work order.',
                onHide: () => setErrorDialog(null)
            })
            SolverLogger.error('Error parsing Work Order from Solver.\n', error)
        }

    }, [saveWorkOrder, setErrorDialog])

    const faultAttemptUpdate = useCallback((data: string) => {
        const faultAttempt: FaultAttempt = JSON.parse(data)
        SolverLogger.info(`Solver sent Fault State Update:\n`, faultAttempt)
        saveFaultAttempt(faultAttempt)
        if (courseCompleted || !user?.moduleLevel || !faultAttempt.achievementSummary.bumpNextLevel)
            return
        setModulePassed()
    }, [courseCompleted, saveFaultAttempt, setModulePassed, user])

    const hintUpdate = useCallback((data: string) => {
        if (data === 'null' || !data) return
        const hint: Hint = { ...JSON.parse(data), id: nanoid(10) }
        if (!hint.text) return
        addHintTaken(hint)
        setSelectedHint(hint)
    }, [addHintTaken, setSelectedHint])

    const handleSolverError = useCallback((data: string) => {
        SolverLogger.error(`Client Solver Error:\n ${data}`)

        if (data.includes(`Solver[GetNextHint]`)) {
            setErrorDialog({
                title: 'Error: Problem retrieving hint',
                body: `There was an error trying to retrieve the next hint. The error says: ${data}`,
                onHide: () => setErrorDialog(null)
            })
        }
    }, [setErrorDialog])

    const processAck = useCallback((ack: SolverAcknowledgment) => {
        SolverLogger.info(ack)
        switch (ack.CommandName) {
            case SolverCommand.CompleteFault:
                getAndSetUserProfile()
                break
            default: break
        }
    }, [getAndSetUserProfile])

    const handleSolverDisconnect = useCallback(() => {
        SolverLogger.warn('Handling solver disconnection...')
        setErrorDialog(null)
        isLMSAvailable && concedeControl()
        !isLMSAvailable && setCriticalErrorMessage(`Server connection problem. Check console.`)
    }, [isLMSAvailable, concedeControl, setCriticalErrorMessage, setErrorDialog])

    const handleConnectionClosed = useCallback(() => {
        SolverLogger.warn(`SignalR connection closed.`)
        SolverLogger.warn('SignalR Hub Connection:\n', connection)
        setErrorDialog({
            title: 'Lost connection',
            body: `Oops... it seems we lost the connection to the server. Please try loading the module again.`,
            onHide: handleSolverDisconnect,
            buttons: [<PrimaryButton key={'exitBtn'} onClick={handleSolverDisconnect}> {`Exit Module`} </PrimaryButton>]
        })
    }, [connection, handleSolverDisconnect, setErrorDialog])

    const customTestStateUpdate = useCallback((data: string) => {
        if (!data || data === 'null') return
        const customTestState: CustomTestState = JSON.parse(data)
        SolverLogger.info(`Custom Test State Update:\n`, customTestState)
        saveCustomTestState(customTestState)
    }, [saveCustomTestState])

    const RegisterSolverCallbacks = useCallback(() => {
        SolverLogger.log('Registering General Solver Callbacks...')

        connection.on('clientSolverError', (data: string) => handleSolverError(data))
        connection.on('clientSimulationStarted', simulationStarted)
        connection.on('clientTimeUpdate', (data: string) => {
            SolverLogger.info(`Received Time Update from Solver.\n ${data}`)
            setTimeSpent(data)
        })
        connection.on('clientCostUpdate', (data: string) => {
            SolverLogger.info(`Received Cost Update from Solver.\n ${data}`)
            setCost(data)
        })
        connection.on('clientConnectionUpdate', (msg: string) => {
            SolverLogger.info('Client Connection Update received from Solver:\n', msg)
        })
        connection.on('clientMessageReceived', (data: string) => {
            const msg = JSON.parse(data)
            SolverLogger.info(`Received Ack: ${msg.CommandName}\n`, msg)
        })
        connection.on('clientMessageUpdate', (data: string) => {
            SolverLogger.info('Message Update received from Solver:\n', data)
        })
        connection.on('clientWorkOrderUpdate', workOrderUpdate)
        connection.on('clientFaultStateUpdate', faultAttemptUpdate)
        connection.on('clientHintUpdate', hintUpdate)
        connection.on('clientCustomTestStateUpdate', customTestStateUpdate)
        connection.onclose(handleConnectionClosed)

    }, [connection, customTestStateUpdate, faultAttemptUpdate, handleConnectionClosed, handleSolverError, hintUpdate, setCost, setTimeSpent, simulationStarted, workOrderUpdate])

    useEffect(() => {
        if (initialized.current || errorDialog || criticalErrorMessage || !user || solverAvailable) return
        if (connection.state === HubConnectionState.Connected || connection.state === HubConnectionState.Connecting) return

        SolverLogger.log('Initializing solver connection...')

        initialized.current = true
        const init = async () => {
            RegisterSolverCallbacks()
            await connection.start()
                .then(() => {
                    SolverLogger.success('Connection started.')
                    joinGroup()
                })
        }
        init()
            .catch((error) => {
                SolverLogger.fatal(error)
                setErrorDialog({
                    title: 'Connection failed',
                    body: 'Oops... it seems the connection to the server has failed. Please try loading the module again. If it persists, contact your administrator.',
                    onHide: handleSolverDisconnect,
                    buttons: [<PrimaryButton key={'exitBtn'} onClick={handleSolverDisconnect}>{`Exit Module`}</PrimaryButton>]
                })
            })

        SolverLogger.success('Solver connection initialized.')

    }, [RegisterSolverCallbacks, connection, handleSolverDisconnect, joinGroup, errorDialog, setErrorDialog, solverAvailable, user, criticalErrorMessage])

    const stopConnection = useCallback(() => {
        if (connection.state !== HubConnectionState.Connected || !user) return

        SolverLogger.warn('Stopping solver connection...')
        const payload = JSON.stringify({
            Id: uuid(),
            GroupName: groupName,
            CommandName: SolverCommand.LeaveGroup,
            CommandArguments: {
                groupName: groupName,
                userName: user.userName
            }
        })
        connection.invoke('ProcessMessage', payload)
            .then(async () => {
                await connection.stop()
            })
    }, [connection, groupName, user])

    useEffect(() => {
        if (!criticalErrorMessage || connection.state !== HubConnectionState.Connected) return
        stopConnection()
    }, [connection.state, criticalErrorMessage, stopConnection])

    const sendMessage = useCallback(async (command: SolverCommand) => {
        if (notConnected) return
        SolverLogger.info('Sending Message: ', command)
        const payload = JSON.stringify({
            Id: uuid(),
            GroupName: groupName,
            CommandName: command,
            CommandArguments: {
                groupName: groupName,
            }
        })

        await connection.invoke('ProcessMessage', payload)
    }, [connection, groupName, notConnected])

    const getNextHint = useCallback(() => {
        if (connection.state !== HubConnectionState.Connected || !user) return
        const payload = JSON.stringify({
            Id: uuid(),
            GroupName: groupName,
            CommandName: SolverCommand.GetNextHint,
        })
        connection.invoke('ProcessMessage', payload)
    }, [connection, groupName, user])

    const resetUnityScene = useCallback((moduleId: SimutechModule) => {
        if (!unityLoaded) return
        UnityLogger.info(`Resetting Unity scene ${moduleId}...`)
        unityContext?.send('WebFacade', 'ResetScene', moduleId)
    }, [unityContext, unityLoaded])

    const loadFault = useCallback(async (faultId: string, lessonModuleId?: SimutechModule) => {
        if (notConnected || !user) return
        if (!faultId) throw new Error('Fault ID is required.')
        const moduleId = simMod.isLab && lessonModuleId
            ? lessonModuleId
            : user.moduleId as SimutechModule

        SolverLogger.info(`Telling solver to load fault ${faultId} for ${moduleId} in group ${groupName}.`)
        const payload = JSON.stringify({
            Id: uuid(),
            GroupName: groupName,
            CommandName: SolverCommand.LoadFault,
            CommandArguments: {
                moduleId,
                faultId: faultId,
                groupName: groupName,
                electricalCode: user.electricalCode
            }
        })
        await connection.invoke('ProcessMessage', payload)
        resetTimeCost()
        resetUnityScene(moduleId)
    }, [connection, groupName, notConnected, resetTimeCost, resetUnityScene, user])

    const loadScene = useCallback(async (sceneId: SimutechModule, faultId: string) => {
        if (notConnected || !sceneId) return
        setLessonModuleId(sceneId)
        SolverLogger.info(`Telling solver to load SCENE ${sceneId}`)
        const payload = JSON.stringify({
            Id: uuid(),
            GroupName: groupName,
            CommandName: SolverCommand.LoadScene,
            CommandArguments: {
                sceneId: sceneId
            }
        })
        await connection.invoke('ProcessMessage', payload)
        await loadFault(faultId, sceneId)
    }, [connection, groupName, loadFault, notConnected, setLessonModuleId])

    const exitFault = useCallback(() => {
        SolverLogger.log(`Telling solver to Exit Fault.`)
        clearWorkOrder()
        sendMessage(SolverCommand.ExitFault)
    }, [clearWorkOrder, sendMessage])

    const beginFault = useCallback(async () => {
        SolverLogger.log(`Telling solver to Begin Fault.`)
        await sendMessage(SolverCommand.BeginFault)
        isLMSAvailable && incrementScore(1)
        resetTSContext()
    }, [sendMessage, isLMSAvailable, incrementScore, resetTSContext])

    const completeFault = useCallback(async () => {
        clearWorkOrder()
        saveScoreboard(null)
        SolverLogger.log(`Telling solver to Complete Fault.`)
        await sendMessage(SolverCommand.CompleteFault)
    }, [clearWorkOrder, saveScoreboard, sendMessage])

    const getReplaceCostTime = useCallback((part: Part) => {
        const msg = JSON.stringify(
            {
                Id: uuid(),
                GroupName: groupName,
                CommandName: SolverCommand.GetReplaceCostTime,
                CommandArguments: { itemId: part.solverPartID }
            })
        connection.invoke('ProcessMessage', msg)
    }, [connection, groupName])

    return {
        loadFault,
        beginFault,
        exitFault,
        completeFault,
        loadScene,
        stopConnection,
        processAck,
        getNextHint,
        getReplaceCostTime,
    }
}

export default useSolver