import { FC, ReactNode, useRef, MouseEvent } from 'react'
import styled from 'styled-components'

import { useOnClickOutside } from '../hooks'
import { SimutechBlue, SimutechOrange, SimutechOrangeActive } from '../styles/Theme'

type Props = {
    title?: string
    visible: boolean
    children: ReactNode
    onClickOutside: () => void
}

const Menu: FC<Props> = ({ title = '', visible, children, onClickOutside }: Props) => {
    const menuRef = useRef<HTMLDivElement>(null)
    useOnClickOutside(menuRef, onClickOutside)

    if (!visible) return <></>

    return (
        <MenuComponent ref={menuRef}>
            <MenuTitle title={title} />
            {children}
        </MenuComponent>
    )
}
export default Menu

const MenuTitle = ({ title }: { title: string }) => {
    if (!title) return <></>
    return (
        <>
            <MenuItem text={title} isTitle={true} />
            <MenuItemSeparator />
        </>
    )
}

type MenuItemProps = {
    text: string
    onClick?: (e: MouseEvent<HTMLElement>) => void
    disabled?: boolean
    isTitle?: boolean
}

export const MenuItem: FC<MenuItemProps> = ({ text, onClick = () => { }, disabled = false, isTitle = false }: MenuItemProps) => (
    <MenuOption
        className={`${disabled && !isTitle && 'disabled'} ${isTitle && 'menu-title'} `}
        onClick={disabled ? () => { } : onClick}
    >
        {text}
    </MenuOption>
)

export const MenuComponent = styled.div`
    color: ${SimutechBlue};
    pointer-events: auto;
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px rgba(75, 75, 75, 0.75);
    z-index: 100;
    top: 3em;
    right: 2em;
    width: fit-content;
`

export const MenuItemSeparator = styled.div`
    width: 100%;
    height: 3px;
    background: ${SimutechOrange};
    margin: 0;
`

const MenuOption = styled.div`
    padding: 0.25em 1em;
    min-width: 12.5em;
    cursor: default;
    font-size: 1.25em;

    &:hover {
        background: ${SimutechBlue};
        color: white;
        cursor: pointer;
    }

    &:active {
        color: ${SimutechOrangeActive};
        background: ${SimutechBlue};
    }

    &.menu-title {
        background-color: #eee;
    }
    &.menu-title,
    &.disabled {
        pointer-events: none;
    }
    &.disabled {
        color: #ccc;
    }
`