import { RefObject, useEffect, useRef } from 'react'

type AnyEvent = MouseEvent | TouchEvent

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
    elRef: RefObject<T>,
    handler: (event: AnyEvent) => void
) => {
    const handlerRef = useRef(handler)
    handlerRef.current = handler

    useEffect(() => {

        const listener = (event: AnyEvent) => {
            const el = elRef?.current
            if (!el || el.contains(event.target as Node) || !handlerRef.current)
                return

            handlerRef.current(event)
        }

        document.addEventListener('mousedown', listener, { capture: true })
        document.addEventListener('touchstart', listener, { capture: true })

        return () => {
            document.removeEventListener('mousedown', listener, { capture: true })
            document.removeEventListener('touchstart', listener, { capture: true })
        }

    }, [handlerRef, elRef])
}

export default useOnClickOutside