import { CSSProperties, FC, MouseEvent } from 'react'
import ReactPlayer from 'react-player'

import { generalLogger as GeneralLogger } from '../../Loggers'
import { preventContextMenu } from '../../utils'

type Props = {
    visible: boolean
    onClose: (event: MouseEvent) => void
    videoPath: string
    containerCSS: CSSProperties
}

const VideoPlayer: FC<Props> = ({ visible, onClose, videoPath, containerCSS }: Props) => {

    if (visible && !videoPath) GeneralLogger.error('Video path is not defined.')
    if (!visible || !videoPath) return <></>

    return (

        <div
            onContextMenu={preventContextMenu}
            className='media-area'
            style={containerCSS}
        >
            <div className='close-button' onClick={onClose} />
            <div className='media-area-container' style={playerContainerStyle(visible)}>
                <div className="interactive player-wrapper">
                    <ReactPlayer
                        id='vid'
                        playing={true}
                        controls={true}
                        width="100%"
                        height="100%"
                        pip={false}
                        className='react-player'
                        config={{
                            file: {
                                attributes: {
                                    disablePictureInPicture: true,
                                    controlsList: 'nodownload'
                                }
                            }
                        }}
                        url={videoPath}
                        onError={(e: any) => GeneralLogger.error(e)}
                    />
                </div>
            </div>
        </div>

    )
}

export default VideoPlayer

const playerContainerStyle = (showPlayer: boolean) => {
    return {
        animation: `${showPlayer ? 'show' : 'hide'}-videoplayer ease ${showPlayer ? '.5' : '0'}s alternate`,
        opacity: `${showPlayer ? '1' : '0'}`
    } as CSSProperties
}