import { FC, useRef, MouseEvent } from 'react'
import styled from 'styled-components'
import Draggable from 'react-draggable'
import { Img } from 'react-image'

import { ResourceImage } from '../interfaces'

import { ExitMediaIcon } from '../assets/icons'

import { SimutechOrangeActive } from '../styles/Theme'

type Props = {
    diagram: ResourceImage
    path: string
    index: number
    onMouseDown: (diagram: ResourceImage) => void
    onDiagramClose: (e: MouseEvent<HTMLElement>, diagram: ResourceImage) => void
    cssClass: string
}

const DraggableDiagram: FC<Props> = ({ diagram, path, index, onMouseDown, onDiagramClose, cssClass }: Props) => {
    const width = window.outerWidth
    const height = window.outerHeight
    const nodeRef = useRef(null)

    return (
        <Draggable
            nodeRef={nodeRef}
            bounds={{ top: -height, left: -width, right: width, bottom: height }}
            positionOffset={{ x: `${25 + (index * 5)}vw`, y: `-${90 - (index * 3)}vh` }}
            onMouseDown={() => onMouseDown(diagram)}
        >
            <DiagramWrapper
                ref={nodeRef}
                className={cssClass}
            >
                <Img
                    alt=''
                    key={diagram.filename}
                    src={path + diagram.filename}
                    container={(children) => (
                        <Diagram>
                            <ExitButton onClick={(e: MouseEvent<HTMLElement>) => onDiagramClose(e, diagram)}>
                                <ExitMediaIcon />
                            </ExitButton>
                            {children}
                        </Diagram>
                    )}
                />
            </DiagramWrapper>
        </Draggable>
    )
}
export default DraggableDiagram

const DiagramWrapper = styled.div`
    z-index: 0;
    &.diagram-top-z-index {
        position: relative;
        z-index: 1;
    }
`

const Diagram = styled.div`
    position: fixed;
    -webkit-user-drag: none;
    pointer-events: auto;

    img {
        box-shadow: 0px 2px 15px rgba(75, 75, 75, 0.75);
        max-width: inherit;
        max-height: 80vh;
        -webkit-user-drag: none;
    }

    &:hover {
        cursor: grab;
    }

    &:active {
        box-shadow: 0px 2px 25px rgba(75, 75, 75, 0.75);
        cursor: grabbing;
    }
`

const ExitButton = styled.div`
    border: 1px solid rgba(75, 75, 75, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    box-shadow: 0px 2px 5px rgba(75, 75, 75, 0.75);
    width: 2.188em;
    height: 2.188em;
    top: -1em;
    right: -1em;
    z-index: 2;

    svg {
        height: 100%;
        width: 50%;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 10px rgba(75, 75, 75, 0.75);
        .svg-exit-media-btn {
            stroke: ${SimutechOrangeActive};
        }
    }
`