import styled from 'styled-components'
import { simMod } from '../configs'
import { SimutechBlue90, SimutechOrange } from '../styles/Theme'

const BottomBar = styled.div({
    position: 'fixed',
    bottom: 0,
    margin: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 210,
    padding: '0.5em',
    color: simMod.isLab ? SimutechBlue90 : SimutechOrange,
    background: simMod.isLab ? 'white' : SimutechBlue90,
    borderTop: '1px solid rgba(255, 255, 255, 0.25)',
    borderTopLeftRadius: '0.3125em',
    borderTopRightRadius: '0.3125em',
})

export default BottomBar