import { useCallback, useEffect, useState } from 'react'

import { debugShowDisabledFaults, environmentName, simMod } from '../configs'
import { useLMSContext } from '../contexts'
import { lmsLogger as LMSLogger, generalLogger as GeneralLogger } from '../Loggers'

import { AchievementLevel } from '../common/types'
import { User } from '../interfaces'

type LMSInfoEvent = {
    sid: string
    ctid: number
    level: AchievementLevel | null
    ec: string
    cco: AchievementLevel | null
    ctestid?: number
}

export type LMSInfo = {
    sessionId: string
    courseTrackerId: number
    level?: AchievementLevel | null
    electricalCode: string
    completeCourseOn: AchievementLevel | null
    customTestId?: string | null
}

const useLMSInfo = () => {
    const [lmsInfo, setLMSInfo] = useState<LMSInfo | null>(null)
    const { lmsActions: { setLMSAvailable } } = useLMSContext()

    const handleCrossOriginMessage = useCallback((event: MessageEvent<LMSInfoEvent>) => {
        // Must check your origin!
        if (!['.tpcfusion.com', '.simutechmultimedia.net'].some(origin => event.origin.includes(origin))
            && (environmentName === 'development' && !event.origin.includes('//localhost'))
        ) return

        if (!event.data) return
        if (event.data.sid && !lmsInfo) {
            LMSLogger.info('Cross Origin message received:\n', event)
            const newLMSInfo: LMSInfo = {
                sessionId: event.data.sid,
                courseTrackerId: event.data.ctid,
                level: event.data.level,
                electricalCode: event.data.ec,
                completeCourseOn: event.data.cco,
                customTestId: event.data.ctestid ? event.data.ctestid.toString() : null
            }

            if (!newLMSInfo.sessionId) return
            LMSLogger.info('LMS Info received:\n', newLMSInfo)
            setLMSInfo(newLMSInfo)
            setLMSAvailable(true)
        }
    }, [lmsInfo, setLMSAvailable])

    useEffect(() => {
        window.addEventListener('message', handleCrossOriginMessage, false)
        return () => {
            window.removeEventListener('message', handleCrossOriginMessage)
        }
    }, [handleCrossOriginMessage])

    const validateCourseConfig = useCallback((debugUser?: User) => {
        const { isLab } = simMod
        let error: string | null = null
        let message: string | null = null
        let instructions: string | null = null

        if (lmsInfo) {
            const { level, completeCourseOn, customTestId } = lmsInfo
            message = `LMS Course configuration error!`
            instructions = `Please check the LMS course configuration.`

            if (isLab && level)
                error = `Learning Lab course is incorrectly configured with a "Fault Level".`

            if (isLab && customTestId)
                error = `Learning Lab course is incorrectly configured as a "Custom Test" course.`

            if (customTestId && completeCourseOn !== null)
                error = `Custom Test course is incorrectly configured with a "Complete Course On" setting.`

            if (customTestId && level)
                error = `Custom Test course is incorrectly configured with a "Fault Level".`

            if (level && completeCourseOn)
                error = `Mini Sim course is incorrectly configured with a "Complete Course On" setting.`
        }

        if (debugUser) {
            const {
                customTestId, moduleLevel, completeOnFirstFault,
                completeOnLevel, canSelectFaults, canUseDisabledFaults,
            } = debugUser
            message = `Query string or environment variable configuration error!`
            instructions = `Please check your query string values or your environment variables.`

            if (canUseDisabledFaults && !canSelectFaults)
                GeneralLogger.warn('Warning: canUseDisabledFaults is true, but canSelectFaults is false.')

            if (canUseDisabledFaults && !debugShowDisabledFaults)
                GeneralLogger.warn(`Warning: debugShowDisabledFaults is false, but canUseDisabledFaults is true. Cannot use disabled faults that you can't see.`)

            if (!canSelectFaults && (canUseDisabledFaults || debugShowDisabledFaults))
                GeneralLogger.warn(`Warning: canUseDisabledFaults or debugShowDisabledFaults is true, but canSelectFaults is false.`)

            if (isLab && moduleLevel)
                error = `Learning Lab course is incorrectly configured with a "Mini Sim Level".`

            if (isLab && customTestId)
                error = `Learning Lab course is incorrectly configured as a "Custom Test" course.`

            if (customTestId && (completeOnFirstFault || completeOnLevel))
                error = `Custom Test course is incorrectly configured with a "Complete Course On" setting.`

            if (customTestId && moduleLevel)
                error = `Custom Test course is incorrectly configured with a "Mini Sim Level".`

            if (moduleLevel && (completeOnFirstFault || completeOnLevel))
                error = `Mini Sim course is incorrectly configured with a "Complete Course On" setting.`
        }

        if (error !== null) {
            LMSLogger.error(error)
            throw new Error(`${message} ${error} ${instructions}`)
        }

    }, [lmsInfo])

    return { lmsInfo, validateCourseConfig }
}

export default useLMSInfo