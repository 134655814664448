import { createContext, Dispatch, FC, ReactNode, useReducer, useContext, useCallback } from 'react'

import { Hint } from '../interfaces'

type State = {
    maxHints: number
    hints: Hint[]
    selectedHint: Hint | null
}

const initialState: State = {
    maxHints: 0,
    hints: [],
    selectedHint: null
}

enum ActionType {
    SET_MAX_HINTS,
    SET_HINTS_TAKEN,
    SET_SELECTED_HINT,
    ADD_HINT,
}

type Action =
    {
        type: ActionType.SET_MAX_HINTS
        payload: number
    } |
    {
        type: ActionType.SET_HINTS_TAKEN
        payload: Hint[]
    } |
    {
        type: ActionType.SET_SELECTED_HINT
        payload: Hint | null
    } |
    {
        type: ActionType.ADD_HINT
        payload: Hint
    }

const HintsContext = createContext<{
    hintsState: State
    dispatch: Dispatch<Action>
}>({ hintsState: initialState, dispatch: () => null })

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.SET_MAX_HINTS:
            return { ...state, maxHints: action.payload } as State
        case ActionType.SET_HINTS_TAKEN:
            return { ...state, hints: action.payload } as State
        case ActionType.SET_SELECTED_HINT:
            return { ...state, selectedHint: action.payload } as State
        case ActionType.ADD_HINT:
            return { ...state, hints: [...state.hints, action.payload] } as State

        default:
            return state
    }
}

const HintsProvider: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const contextValue = { hintsState: state, dispatch }
    return (
        <HintsContext.Provider value={contextValue}>
            {children}
        </HintsContext.Provider>
    )
}

const useHintsContext = () => {
    const context = useContext(HintsContext)
    if (!context) {
        throw new Error(
            'useHintsContext must be used within a HintsProvider. Wrap a parent component in <HintsProvider> to fix this error.'
        )
    }

    const { hintsState, dispatch } = context

    const actions = {
        setMaxHints: useCallback((maxHints: number) =>
            dispatch({ type: ActionType.SET_MAX_HINTS, payload: maxHints }), [dispatch]),
        clearHintsTaken: useCallback(() =>
            dispatch({ type: ActionType.SET_HINTS_TAKEN, payload: [] }), [dispatch]),
        setSelectedHint: useCallback((hint: Hint | null) =>
            dispatch({ type: ActionType.SET_SELECTED_HINT, payload: hint }), [dispatch]),
        addHintTaken: useCallback((hint: Hint) =>
            dispatch({ type: ActionType.ADD_HINT, payload: hint }), [dispatch]),
    }

    return { hintsState, hintsActions: actions }
}

export default useHintsContext
export { HintsProvider }