import axios, { AxiosError } from 'axios'
import { IAxiosError, IStockError } from './types'

export const axiosErrorHandler = <T>(
    callback: (err: IAxiosError<T> | IStockError<T>) => void
) => {
    return (error: Error | AxiosError<T>) => {
        if (axios.isAxiosError(error)) {
            callback({
                error: error,
                type: 'axios-error'
            } as IAxiosError<T>)
        } else {
            callback({
                error: error,
                type: 'stock-error'
            })
        }
    }
}

export const logError = <TResponse>(error: IAxiosError<TResponse> | IStockError<TResponse>) => {
    if (error.type === 'axios-error') {
        const err = error.error
        if (err.response) {
            // The request was made and the server responded with a status code
            console.error(`${err.code}: ${err.response.status}`)
        }
        else if (err.request) {
            // The request was made but no response was received
            console.error(err.request)
        }
        else {
            // Something happened in setting up the request that triggered an Error
            console.error(err.message)
        }

        console.error(err.config)
        throw new Error(err.message)
    }
    if (error.type === 'stock-error') {
        const err = error.error
        console.error('Stock error:', err)
        throw new Error(err.message)
    }
}