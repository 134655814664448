import { useReducer, useContext, createContext, Dispatch, FC, ReactNode, useCallback } from 'react'

import { ContentIEC, ContentNEC } from '../configs'
import { ElectricalCode } from '../common/types'
import { User, ModuleProfile, ModuleContent, DialogContent } from '../interfaces'


type State = {
	user: User | null
	userProfile: ModuleProfile | null
	criticalErrorMessage: string
	cost: string
	timeSpent: string
	moduleContent: ModuleContent
	errorDialog: DialogContent | null
}

export const initialState: State = {
	user: null,
	userProfile: null,
	criticalErrorMessage: '',
	cost: '0000.00',
	timeSpent: '00:00',
	moduleContent: ContentNEC,
	errorDialog: null
}

enum ActionType {
	SET_USER,
	SET_USER_PROFILE,
	SET_CRITICAL_ERROR_MESSAGE,
	SET_COST,
	SET_TIME_SPENT,
	SET_MODULE_CONTENT,
	SET_ERROR_DIALOG,
}

type Action =

	{
		type: ActionType.SET_USER
		payload: User
	} |
	{
		type: ActionType.SET_USER_PROFILE
		payload: ModuleProfile
	} |
	{
		type: ActionType.SET_CRITICAL_ERROR_MESSAGE
		payload: string
	} |
	{
		type: ActionType.SET_COST
		payload: string
	} |
	{
		type: ActionType.SET_TIME_SPENT
		payload: string
	} |
	{
		type: ActionType.SET_ERROR_DIALOG
		payload: DialogContent | null
	}

const ModuleContext = createContext<{
	moduleState: State
	dispatch: Dispatch<Action>
}>({ moduleState: initialState, dispatch: () => null })

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case ActionType.SET_USER:
			return {
				...state,
				user: action.payload,
				moduleContent: action.payload.electricalCode === ElectricalCode.IEC
					? (ContentIEC)
					: (ContentNEC)
			} as State
		case ActionType.SET_USER_PROFILE:
			return { ...state, userProfile: action.payload }
		case ActionType.SET_CRITICAL_ERROR_MESSAGE:
			return { ...state, criticalErrorMessage: action.payload } as State
		case ActionType.SET_COST:
			return { ...state, cost: action.payload } as State
		case ActionType.SET_TIME_SPENT:
			return { ...state, timeSpent: action.payload } as State
		case ActionType.SET_ERROR_DIALOG:
			return { ...state, errorDialog: action.payload } as State
		default:
			return state
	}
}

const ModuleContextProvider: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const contextValue = { moduleState: state, dispatch }
	return (
		<ModuleContext.Provider value={contextValue}>
			{children}
		</ModuleContext.Provider>
	)
}

const useModuleContext = () => {
	const context = useContext(ModuleContext)
	if (!context) {
		throw new Error(
			'useModuleContext must be used within a ModuleContextProvider. Wrap a parent component in <ModuleContextProvider> to fix this error.'
		)
	}

	const { moduleState, dispatch } = context
	const actions = {
		setUser: useCallback((user: User) =>
			dispatch({ type: ActionType.SET_USER, payload: user }), [dispatch]),
		setUserProfile: useCallback((userProfile: ModuleProfile) =>
			dispatch({ type: ActionType.SET_USER_PROFILE, payload: userProfile }), [dispatch]),
		setCriticalErrorMessage: useCallback((errorMessage: string) =>
			dispatch({ type: ActionType.SET_CRITICAL_ERROR_MESSAGE, payload: errorMessage }), [dispatch]),
		setCost: useCallback((cost: string) =>
			dispatch({ type: ActionType.SET_COST, payload: cost }), [dispatch]),
		setTimeSpent: useCallback((time: string) =>
			dispatch({ type: ActionType.SET_TIME_SPENT, payload: time }), [dispatch]),
		setErrorDialog: useCallback((dialogContent: DialogContent | null) =>
			dispatch({ type: ActionType.SET_ERROR_DIALOG, payload: dialogContent }), [dispatch]),
	}

	return {
		moduleState,
		moduleActions: actions
	}
}
export default useModuleContext
export { ModuleContextProvider }