import { FC, useState, MouseEvent, useCallback } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { simMod, helpURL, hintsEnabled, showDebugMenu as enableToolsMenu } from '../configs'
import { useHintsContext, useLearningLabContext } from '../contexts'
import { useSolver } from '../hooks'

import { openInNewTab } from '../common/utils'
import { generalLogger as GeneralLogger } from '../Loggers'
import { Hint } from '../interfaces'

import { AboutDialog, Dialog, Menu, MenuItem, MenuItemSeparator } from './'
import { HintsMenuItems } from './troubleshoot'
import { PrimaryButton, SecondaryButton } from '../styles/Buttons'

type Props = {
    visible: boolean
    hideMenu: () => void
}

const HelpMenu: FC<Props> = ({ visible, hideMenu }: Props) => {
    const {
        hintsState: { hints, maxHints, selectedHint },
        hintsActions: { setSelectedHint }
    } = useHintsContext()
    const { getNextHint } = useSolver()

    const [showAbout, setShowAbout] = useState(false)
    const [showHintConfirm, setShowHintConfirm] = useState(false)
    const navigate = useNavigate()

    const {
        llActions: { setAltBg }
    } = useLearningLabContext()

    const toggleAbout = () => setShowAbout(prev => !prev)
    const toggleHintConfirm = () => {
        hideMenu()
        setShowHintConfirm(prev => !prev)
    }

    const closeHint = () => setSelectedHint(null)

    const getNewHint = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        toggleHintConfirm()
        if (hints.length >= maxHints) {
            GeneralLogger.error(`Attempted to retrieve more than ${maxHints} available hints`)
            return
        }
        getNextHint()
    }

    const showHint = useCallback((hint: Hint) => {
        visible && hideMenu()
        setSelectedHint(hint || hints[hints.length + 1])
    }, [hideMenu, hints, setSelectedHint, visible])

    const helpClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        openInNewTab(simMod.moduleHelpURL ? simMod.moduleHelpURL : helpURL)
        hideMenu()
    }

    const aboutClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setShowAbout(true)
        hideMenu()
    }

    const goToEnvironmentVariables = useCallback(() => navigate('/environment-variables'), [navigate])

    const handleVariablesClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        hideMenu()
        goToEnvironmentVariables()
    }

    const altBgClick = (e: MouseEvent<HTMLElement>, alt: number) => {
        e.preventDefault()
        setAltBg(alt)
        hideMenu()
    }

    return (
        <>
            <Menu
                title={'Help Options'}
                visible={visible}
                onClickOutside={hideMenu}
            >
                {
                    hintsEnabled ? (
                        <Routes>
                            <Route path={'/troubleshoot'} element={(
                                <HintsMenuItems
                                    openHintConfirm={toggleHintConfirm}
                                    openHint={showHint}
                                />
                            )} />
                        </Routes>
                    ) : <></>
                }
                <MenuItem text='Program Help' onClick={helpClick} />
                <MenuItem text='About this module' onClick={aboutClick} />
                {

                    enableToolsMenu && (
                        <>
                            <MenuItemSeparator />
                            <MenuItem text='Debug Tools' isTitle={true} />
                            <MenuItem
                                text='Environment Variables'
                                onClick={handleVariablesClick}
                            />
                            {simMod.isLab && (
                                <>
                                    <MenuItem text='Original Background' onClick={(e: MouseEvent<HTMLElement>) => altBgClick(e, 0)} />
                                    {
                                        [1, 2, 3].map((bg: number) =>
                                        (
                                            <MenuItem
                                                key={`bg${bg}`}
                                                text={`Alternate Background                                                 ${bg}`}
                                                onClick={(e: MouseEvent<HTMLElement>) => altBgClick(e, bg)}
                                            />
                                        ))
                                    }
                                </>
                            )}
                        </>
                    )


                }
            </Menu>
            <AboutDialog visible={showAbout} onHide={toggleAbout} />
            <Dialog
                visible={showHintConfirm}
                title={`Get a Fault Hint`}
                onHide={toggleHintConfirm}
                buttons={[
                    <PrimaryButton key={`confBtn`} onClick={getNewHint}>{`Yes`}</PrimaryButton>,
                    <SecondaryButton key={`cancelBtn`} onClick={toggleHintConfirm}>{`No`}</SecondaryButton>
                ]}
            >
                <p>
                    {`Each fault hint you view will significantly reduce your
                    Efficiency and Skill Ratings for this fault attempt.`}
                </p>
                <p>
                    {`You have `}<strong>{maxHints - hints.length}</strong>{` remaining hints available
                    for this fault. Are you sure you want to view a new hint?`}
                </p>
            </Dialog>

            <Dialog
                title={selectedHint ? `Fault Hint #${hints.findIndex(h => h.id === selectedHint.id) + 1}` : ''}
                visible={!!selectedHint}
                onHide={closeHint}
            >
                {
                    selectedHint ? (
                        <>
                            <p>{selectedHint.text}</p>
                        </>
                    ) : <></>
                }
            </Dialog>
        </>
    )
}

export default HelpMenu