import { useNavigate } from 'react-router-dom'

import { ButtonGroup, PrimaryButton } from '../styles/Buttons'
import { TableTitle, Wrapper } from '../styles/Theme'
import { EnvironmentVariablesTable, NavigationBar } from './'

const EnvironmentVariables = () => {

    const navigate = useNavigate()
    const goBack = () => navigate(-1)

    return (
        <div className="module-content">
            <NavigationBar />
            <Wrapper>
                <TableTitle className={'title'}>
                    {'Environment Variables'}
                </TableTitle>
                <EnvironmentVariablesTable />
                <ButtonGroup>
                    <PrimaryButton onClick={goBack}>
                        {'Back'}
                    </PrimaryButton>
                </ButtonGroup>
            </Wrapper>

        </div>
    )
}

export default EnvironmentVariables