import { useEffect, useState } from 'react'
import { debugShowDisabledFaults } from '../configs'

import { useModuleContext, useTroubleshootContext } from '../contexts'
import { faultManagerService as FaultManager } from '../Services'

const useFaultList = () => {
    const { moduleState: { user } } = useModuleContext()
    const { tsState: { faultList }, tsActions: { saveFaultList } } = useTroubleshootContext()
    const [error, setError] = useState('')

    useEffect(() => {
        if (!user || (faultList && faultList.length)) return

        const fetchData = async () => {
            const faultList = await FaultManager.getFaultList(user.moduleId, debugShowDisabledFaults)
            saveFaultList(faultList)
        }
        fetchData()
            .catch(error => setError(error.toString()))

    }, [faultList, saveFaultList, user])

    return { faultList, error } as { faultList: typeof faultList; error: string }
}
export default useFaultList