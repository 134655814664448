import { FC } from 'react'
import { useHintsContext } from '../../contexts'
import { Hint } from '../../interfaces'
import { MenuItem } from '../Menu'

type Props = {
    openHintConfirm: () => void
    openHint: (hint: Hint) => void
}

const HintsMenuItems: FC<Props> = ({ openHint, openHintConfirm }: Props) => {
    const { hintsState: { hints, maxHints } } = useHintsContext()

    if (!maxHints) return <MenuItem disabled={true} text={`No Hints Available`} />

    const hintsTaken = hints.map((hint: Hint, idx: number) => (
        <MenuItem
            key={hint.id}
            text={`Review Fault Hint #${idx + 1}`}
            onClick={() => openHint(hint)}
        />
    ))

    const GetFaultHintItem = () => maxHints === hints.length
        ? <></>
        : (
            <MenuItem
                text={`Get ${hints.length === 0 ? 'a' : 'Another'} Fault Hint`}
                onClick={openHintConfirm}
            />
        )

    return (
        <>
            {hintsTaken}
            <GetFaultHintItem />
        </>
    )
}

export default HintsMenuItems
