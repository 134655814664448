/* eslint-disable no-eval */
import { useState, useRef, FC, useEffect, useCallback, CSSProperties, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { HubConnectionState } from '@microsoft/signalr'
import Unity from 'react-unity-webgl'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { DefaultFaultId, isDebug, showReplaceCostTime, simMod } from '../configs'

import {
    useModuleContext, useLearningLabContext, useHintsContext,
    useUnityContext, useSolverContext
} from '../contexts'

import { useSolver, SolverCommand, SolverAcknowledgment } from '../hooks'
import {
    solverLogger as SolverLogger,
    unityLogger as UnityLogger,
    generalLogger as GeneralLogger
} from '../Loggers'

import { ElectricalCode } from '../common/types'
import {
    Part, LDLItem, SafetyMessage,
    SafetyExceptionType, SoundEffect,
    InitialState, InitialParameterState
} from '../interfaces'

import { Dialog, LDL, PartSpecsViewer } from './'
import { PrimaryButton, SecondaryButton } from '../styles/Buttons'

type Props = { onZapandStop: (exception: SafetyExceptionType) => void }

const UnityComponent: FC<Props> = ({ onZapandStop }: Props) => {
    const { moduleState: { user, cost } } = useModuleContext()
    const { solverState: { connection, solverAvailable, groupName, } } = useSolverContext()
    const {
        unityState: { unityLoaded, unityContext },
        unityActions: { setUnityLoaded, setUnityProgress }
    } = useUnityContext()

    const { llState: { lessonModuleId } } = useLearningLabContext()
    const { hintsState: { hints }, hintsActions: { setMaxHints, clearHintsTaken } } = useHintsContext()

    const { loadFault, processAck, getReplaceCostTime } = useSolver()

    const [msgDialogShow, setMsgDialogShow] = useState(false)
    const [msgDialog, setMsgDialog] = useState({ title: '', body: '' })
    const [showLDL, setShowLDL] = useState(false)
    const toggleLDL = () => setShowLDL(prev => !prev)

    const replacedPart = useRef<Part | Partial<Part> | null>(null)
    const [replaceConfirmShow, setReplaceConfirmShow] = useState(false)
    const hideReplaceConfirm = () => {
        setReplaceConfirmShow(false)
        replacedPart.current = null
    }

    const [receivedSafetyException, setReceivedSafetyException] = useState<SafetyExceptionType | null>(null)
    const [specsPart, setSpecsPart] = useState('')

    const location = useLocation()
    const callbacksLoaded = useRef(false)
    const runningCurrentFunction = useRef('')
    const functionsToRun = useRef<string[]>([])

    const playSound = useCallback((soundEffect: SoundEffect) => {
        UnityLogger.info(`Playing ${soundEffect} sound...`)
        unityContext?.send('SoundManager', 'PlayWebSound', soundEffect)
    }, [unityContext])

    const activePaths = ['/explore', '/troubleshoot', '/lab']
    const isInScene = activePaths.some(path => location.pathname.includes(path))
    const sceneActive = useRef(false)

    useEffect(() => {
        if (isInScene && !sceneActive.current) {
            UnityLogger.log('Telling Unity to START scene...')
            unityContext?.send('SoundManager', 'EnableSounds', 1)
            sceneActive.current = true
            return
        }
        if (sceneActive.current) {
            UnityLogger.log('Telling Unity to STOP scene...')
            unityContext?.send('SoundManager', 'EnableSounds', 0)
            sceneActive.current = false
        }
    }, [isInScene, unityContext])

    useEffect(() => {
        if (cost === '0000.00') return
        playSound(SoundEffect.Cash)
    }, [cost, playSound])

    const waitForTick = (functionStr: string) => {
        SolverLogger.warn('Wait For Tick.\n' + functionStr)
        functionsToRun.current.push(functionStr)
    }

    const SendMessageToSolver = useCallback((message: string) => {
        if (runningCurrentFunction.current === '') {
            if (connection.state !== HubConnectionState.Connected) return
            const payload = JSON.parse(message)
            SolverLogger.info(`Payload being sent to solver: ${payload.CommandName}\n`, payload)

            SolverLogger.info(`Connection state is ${connection.state}`)
            connection.invoke(SolverCommand.ProcessMessage, message)
                .catch((err: any) => {
                    return SolverLogger.error(err.toString())
                })

            if (message.indexOf('UpdatePushbutton') >= 0) {
                runningCurrentFunction.current = 'SendMessageToSolver:UpdatePushbutton'
            }
            else if (message.indexOf('DepressDevice') >= 0) {
                runningCurrentFunction.current = 'SendMessageToSolver:DepressDevice'
            }

            if (message.indexOf('PlaceLead') >= 0) {
                runningCurrentFunction.current = 'SendMessageToSolver:PlaceLead'
            }
            else if (message.indexOf('RemoveLead') >= 0) {
                runningCurrentFunction.current = 'SendMessageToSolver:RemoveLead'
            }
        }
        else {
            waitForTick(`SendMessageToSolver('${message}')`)
        }
    }, [connection])

    const replaceItem = (solverPartID: string) => {
        const msg = JSON.stringify(
            {
                Id: uuid(),
                GroupName: groupName,
                CommandName: SolverCommand.ReplaceItem,
                CommandArguments: { ItemId: solverPartID }
            })
        SendMessageToSolver(msg)
    }

    const updateProgress = useCallback((progression: number) => {
        const percent = Math.trunc(progression * 100)
        setUnityProgress(percent)
    }, [setUnityProgress])

    const parsePart = (partData: string): Part => {
        const data = JSON.parse(partData)
        const part: Part = {
            solverPartID: data.partID || data.solverPartID,
            displayPartID: data.partID || data.displayPartID,
            partType: data.partType
        }
        if (!part.solverPartID) throw new Error(`Invalid part received: ${part}. 'solverPartID' is missing.`)
        return part
    }

    const RegisterUnityCallbacks = useCallback(() => {
        UnityLogger.log('Registering UNITY Callbacks...')
        unityContext?.on('progress', (progression: number) => updateProgress(progression))
        unityContext?.on('SendMessageToSolver', (msg: string) => {
            UnityLogger.info('Unity sending message to solver...')
            SendMessageToSolver(msg)
        })
        //unityContext?.on('ChangeScene', (scene: SimutechModule) => handleSceneChange(scene))
        unityContext?.on('SendReplaceInfo', (data: string) => {
            const partInfo = parsePart(data)
            setReplacedPart(partInfo, !showReplaceCostTime)
            showReplaceCostTime && getReplaceCostTime(partInfo)
        })
        unityContext?.on('InformPart', (partType: string) => setSpecsPart(partType))
        unityContext?.on('SetLockout', (msg: string) => setShowLDL((msg === 'True')))
        unityContext?.on('GetElectricalCode', (): ElectricalCode => {
            if (!user) throw new Error(`Unity tried to get the user's EC, but the user hasn't been instantiated yet.`)
            return user.electricalCode
        })

        //unityContext?.on('GetLaptopAvailability', () => 'False') // Deprecated.
        unityContext?.on('loaded', () => {
            UnityLogger.success('Unity is loaded.')
            setUnityLoaded(true)
        })
        unityContext?.on('quitted', () => {
            UnityLogger.warn('Unity has quitted.')
            setUnityLoaded(false)
        })
        unityContext?.on('error', (message: any) => UnityLogger.error('Unity had an error:\n', message))
    }, [SendMessageToSolver, getReplaceCostTime, setUnityLoaded, unityContext, updateProgress, user])

    const handleSafetyException = useCallback((safetyMessage: SafetyMessage) => {
        SolverLogger.warn(`Safety Exception: ${safetyMessage.ExceptionType}`)
        switch (safetyMessage.ExceptionType) {
            case SafetyExceptionType.Zap:
                playSound(SoundEffect.Zap)
                break
            case SafetyExceptionType.Stop:
            case SafetyExceptionType.Caution:
                playSound(SoundEffect.Caution)
                break
            default:
                playSound(SoundEffect.Notify)
        }
        handleErrorDialog(safetyMessage.ExceptionType + ': ' + safetyMessage.title, safetyMessage.message)
        setReceivedSafetyException(safetyMessage.ExceptionType)
    }, [playSound])

    const unregisterCallbacks = useCallback(() => {
        SolverLogger.log('UNREGISTER Unity Solver callbacks...')
        connection.off('clientSolverUpdate')
        connection.off('clientMessageComplete')
        connection.off('clientSafetyException')
        connection.off('clientInitialState')
        connection.off('clientPartState')
        connection.off('clientReplaceRepairUpdate')
    }, [connection])

    const handleMaxHints = useCallback((initialParameterState: InitialParameterState) => {
        if (hints.length) clearHintsTaken()
        const hintsMax = Number(initialParameterState?.Hints?.MaxHints)
        if (isNaN(hintsMax)) {
            setMaxHints(0)
            return
        }
        setMaxHints(hintsMax)
    }, [clearHintsTaken, hints.length, setMaxHints])

    useEffect(() => {
        SolverLogger.log('Registering Unity Solver Callbacks...')

        connection.on('clientSolverUpdate', (data: string) => {
            const update = JSON.parse(data)
            SolverLogger.info(`Received update from the solver:\n`, update.Model)
            if (!unityLoaded) {
                SolverLogger.warn('...but Unity is not loaded.')
                return
            }
            unityContext?.send('WebFacade', 'WebSolverUpdated', data)

            if (runningCurrentFunction.current === '' && functionsToRun.current.length > 0) {
                const currentfunction = functionsToRun.current[0]
                functionsToRun.current.shift()
                SolverLogger.info('CurrentFunction: ' + currentfunction)
                window.eval('ReactUnityWebGL.' + currentfunction)
            }
        })
        connection.on('clientMessageComplete', (data: string) => {
            const ack: SolverAcknowledgment = JSON.parse(data)
            SolverLogger.success(`Finished Ack: ${ack.CommandName}`)
            processAck(ack)

            runningCurrentFunction.current[0] && SolverLogger.info('CALL FINISHED ACTION ' + runningCurrentFunction.current[0])
            runningCurrentFunction.current = ''
            // Execute the next message if there are any in the message queue
            if (functionsToRun.current.length > 0) {
                const currentfunction = functionsToRun.current[0]
                functionsToRun.current.shift()
                SolverLogger.info(`CurrentFunction: ${currentfunction}`)
                window.eval('ReactUnityWebGL.' + currentfunction)
            }
        })
        connection.on('clientSafetyException', (data: string) => {
            const safetyMessage: SafetyMessage = JSON.parse(data)
            SolverLogger.info(`Client Safety Exception\n`, safetyMessage)
            handleSafetyException(safetyMessage)
            unityContext?.send('WebFacade', 'WebSolverError', JSON.stringify(data))
        })
        connection.on('clientInitialState', (data: string) => {
            const initState: InitialState = JSON.parse(data)
            SolverLogger.info(`Initial State\n`, initState)

            if (initState.Model.InitialState) {
                SolverLogger.info(`Initial State received from Solver:`)
                isDebug && console.table(initState.Model.InitialState)
            }
            if (initState.Model.InitialParameterState) {
                const initParamState = initState.Model.InitialParameterState
                SolverLogger.info(`Initial Parameter State received from Solver:\n`, initParamState)
                handleMaxHints(initParamState)
            }
            !unityLoaded && SolverLogger.warn('...but Unity is not loaded.')

            unityContext?.send('WebFacade', 'WebInitialPosition', data)
        })
        connection.on('clientPartState', (data: string) => {
            SolverLogger.info('Part State received from Solver:\n', data)
            !unityLoaded && SolverLogger.warn('...but Unity is not loaded.')
            unityContext?.send('WebFacade', 'currentPartState', data)
        })
        connection.on('clientReplaceRepairUpdate', (data: string) => {
            const partInfo: { Model: { Cost: number; Time: number } } = JSON.parse(data)
            const { Cost, Time } = partInfo.Model
            SolverLogger.info('PartInfo:\n', partInfo)
            const partialPart: Partial<Part> = {
                cost: Cost,
                time: Time
            }
            setReplacedPart(partialPart, true)
        })

        return () => unregisterCallbacks()
    }, [connection, handleMaxHints, handleSafetyException, processAck, unityContext, unityLoaded, unregisterCallbacks])

    const setReplacedPart = (part: Part | Partial<Part>, doConfirm?: boolean) => {
        replacedPart.current = replacedPart.current
            ? { ...replacedPart.current, ...part }
            : part
        if (replacedPart.current && doConfirm)
            setReplaceConfirmShow(true)
    }

    const toggleMsgDialog = () => {
        setMsgDialogShow(prev => !prev)
        if (receivedSafetyException)
            onZapandStop(receivedSafetyException)
    }

    const clearSpecsPart = () => setSpecsPart('')

    const doReplace = () => {
        if (!replacedPart.current || !replacedPart.current.solverPartID) return
        replaceItem(replacedPart.current.solverPartID)
        setReplaceConfirmShow(false)
        replacedPart.current = null
    }

    const acceptLdl = () => {
        verifyDead()
        setShowLDL(false)
    }

    const verifyDead = () => {
        const payload = JSON.stringify({
            Id: uuid(),
            GroupName: groupName,
            CommandName: SolverCommand.VerifyDeadPowerSupply
        })
        SendMessageToSolver(payload)
    }

    const handleErrorDialog = (title: string, body: string) => {
        GeneralLogger.warn('Exception:\n', title, body)
        setMsgDialog({ title, body })
        setMsgDialogShow(true)
    }

    useEffect(() => {
        if (!isInScene) clearSpecsPart()
    }, [isInScene])

    useEffect(() => {
        if (callbacksLoaded.current || !solverAvailable || !user) return

        RegisterUnityCallbacks()
        callbacksLoaded.current = true
        loadFault(DefaultFaultId) // If we don't do this now, Unity will request an initial state and a solver error will occur.

    }, [RegisterUnityCallbacks, loadFault, solverAvailable, user])

    const ldlInfo = simMod.ldlInfos.filter((info: LDLItem) => info.labs.includes(lessonModuleId))[0]


    const unityStyle: CSSProperties = useMemo(() => (
        {
            height: '100%',
            width: '100%',
            visibility: isInScene ? 'visible' : 'hidden'
        }
    ), [isInScene])

    if (!solverAvailable || !user) return <></>

    return (
        <>
            <UnityContainer>
                {unityContext && <Unity unityContext={unityContext} style={unityStyle} />}
            </UnityContainer>
            {
                specsPart && isInScene ? (
                    <PartSpecsViewer
                        partType={specsPart}
                        onClose={clearSpecsPart}
                    />
                ) : <></>
            }
            <Dialog
                key={'confirmreplace'}
                onHide={hideReplaceConfirm}
                visible={replaceConfirmShow}
                title={replacedPart.current ? `Replace ${replacedPart.current.displayPartID}?` : ''}
                buttons={[
                    <PrimaryButton key={`confBtn`} onClick={doReplace}>{`Replace`}</PrimaryButton>,
                    <SecondaryButton key={`cancelBtn`} onClick={hideReplaceConfirm}>{`Cancel`}</SecondaryButton>
                ]}
            >
                {
                    replacedPart.current ? (
                        <>
                            <p>{`Are you sure you want to replace the ${replacedPart.current.displayPartID} ${replacedPart.current.partType}?`}</p>
                            {
                                showReplaceCostTime &&
                                <p>{`This will cost $${replacedPart.current.cost?.toFixed(2) || `0.00`} and will take ${replacedPart.current.time || `0`} minutes.`}</p>
                            }
                        </>
                    ) : <></>
                }
            </Dialog>

            <Dialog
                title={msgDialog.title}
                visible={msgDialogShow}
                onHide={toggleMsgDialog}
            >
                {msgDialog.body}
            </Dialog>
            {
                ldlInfo ? (
                    <LDL
                        ldlInfo={ldlInfo}
                        show={showLDL}
                        onHide={toggleLDL}
                        onAccept={acceptLdl}
                        electricalCode={user.electricalCode}
                    />
                ) : <></>}
        </>
    )
}
export default UnityComponent

const UnityContainer = styled.div({
    height: '100%',
    minHeight: '48em',
    minWidth: '85.375em',
})