import { SimParameterKey } from '.'

export type EnvironmentVariableKey = keyof typeof process.env

export type EnvironmentVariableMapping = {
    environmentVariableName: EnvironmentVariableKey
    nameInVariableGroup?: string
    queryStringOverride?: SimParameterKey
}

const VAR_PREFIX = 'REACT_APP_'

// Some of these values may not be specified by variables in a variable group.
// They may be specified or even overridden by the File Creator task in the Task Group.
export const environmentVariableMappings: Array<EnvironmentVariableMapping> = [
    {
        environmentVariableName: `${VAR_PREFIX}MODULE_ID`,
        nameInVariableGroup: 'SimulationModuleId',
    },
    {
        environmentVariableName: `${VAR_PREFIX}ENVIRONMENT_NAME`,
        nameInVariableGroup: 'ResourceGroup',
    },
    {
        environmentVariableName: `${VAR_PREFIX}WEB_BUILD_NUMBER`,
        nameInVariableGroup: 'WebBuild',
    },
    {
        environmentVariableName: `${VAR_PREFIX}UNITY_BUILD_NUMBER`,
        nameInVariableGroup: 'UnityBuild',
    },
    {
        environmentVariableName: `${VAR_PREFIX}WEB_COMMIT_ID`,
        nameInVariableGroup: 'WebCommit',
    },
    {
        environmentVariableName: `${VAR_PREFIX}UNITY_COMMIT_ID`,
        nameInVariableGroup: 'UnityCommit',
    },
    {
        environmentVariableName: `${VAR_PREFIX}RELEASE_DATE`,
    },
    {
        environmentVariableName: `${VAR_PREFIX}DEFAULT_HELP_URL`,
    },
    {
        environmentVariableName: `${VAR_PREFIX}SOLVER_URL`,
    },
    {
        environmentVariableName: `${VAR_PREFIX}XAPI_URL`,
    },
    {
        environmentVariableName: `${VAR_PREFIX}IS_DEBUG`,
        nameInVariableGroup: 'isDebug',
    },
    {
        environmentVariableName: `${VAR_PREFIX}SHOW_DEBUG_MENU`,
        nameInVariableGroup: 'ShowDebugMenu',
        queryStringOverride: 'showDebugTools',
    },
    {
        environmentVariableName: `${VAR_PREFIX}LMS_REQUIRED`,
        nameInVariableGroup: 'LMSRequired',
    },
    {
        environmentVariableName: `${VAR_PREFIX}COMPANY_NAME`,
        nameInVariableGroup: 'FusionDefaultCompanyName',
    },
    {
        environmentVariableName: `${VAR_PREFIX}COMPANY_ID`,
        nameInVariableGroup: 'FusionDefaultCompanyId',
    },
    {
        environmentVariableName: `${VAR_PREFIX}USERID`,
        queryStringOverride: 'userId',
    },
    {
        environmentVariableName: `${VAR_PREFIX}USERNAME`,
        queryStringOverride: 'userName',
    },
    {
        environmentVariableName: `${VAR_PREFIX}DEFAULT_ELECTRICAL_CODE`,
        queryStringOverride: 'electricalCode',
    },
    {
        environmentVariableName: `${VAR_PREFIX}CAN_SELECT_FAULT`,
        nameInVariableGroup: 'CanSelectFault',
        queryStringOverride: 'canSelectFaults',
    },
    {
        environmentVariableName: `${VAR_PREFIX}SHOW_DISABLED_FAULTS`,
        nameInVariableGroup: 'ShowDisabledFaults',
        queryStringOverride: 'showDisabledFaults',
    },
    {
        environmentVariableName: `${VAR_PREFIX}CAN_USE_DISABLED_FAULTS`,
        nameInVariableGroup: 'CanUseDisabledFaults',
        queryStringOverride: 'canUseDisabledFaults',
    },
    {
        environmentVariableName: `${VAR_PREFIX}HINTS_ENABLED`,
        nameInVariableGroup: 'HintsEnabled',
        queryStringOverride: 'hintsEnabled',
    },
    {
        environmentVariableName: `${VAR_PREFIX}SHOW_REPLACE_COST_TIME`,
        nameInVariableGroup: 'ShowReplaceCostTime',
        queryStringOverride: 'showReplaceCostTime',
    },
    {
        environmentVariableName: `${VAR_PREFIX}COMPLETE_ON_FIRST_FAULT`,
        queryStringOverride: 'completeOnFirstFault',
    },
    {
        environmentVariableName: `${VAR_PREFIX}COMPLETE_ON_LEVEL`,
        queryStringOverride: 'completeOnLevel',
    },
    {
        environmentVariableName: `${VAR_PREFIX}SIM_LEVEL`,
        queryStringOverride: 'miniSimLevel',
    }
]

export default environmentVariableMappings