import { FC } from 'react'
import styled from 'styled-components'
import { WarningIcon } from '../styles/Icons'

const NoDataComponent: FC<{ text: string }> = ({ text }: { text: string }) => (
    <WarningBox>
        <div className="alert alert-warning text-center">
            <WarningIcon />&nbsp;&nbsp;
            {text}
        </div>
    </WarningBox>
)
export default NoDataComponent

const WarningBox = styled.div({
    display: 'flex',
    height: '26.875rem',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: '1.25em',
})