import 'core-js/stable' // For IE 11 support
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import {
    ModuleContextProvider, LMSContextProvider, UnityContextProvider,
    SolverContextProvider, HintsProvider, LearningLabProvider, TroubleshootProvider
} from './contexts'

import { BrowserDetect } from './common/utils'

import App from './App'
import { Compose } from './components'
import { WarningIcon } from './styles/Icons'
import { HelmetProvider } from 'react-helmet-async'

const providers = [
    HelmetProvider, HintsProvider, SolverContextProvider,
    LMSContextProvider, ModuleContextProvider, UnityContextProvider,
    LearningLabProvider, TroubleshootProvider
]
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <Compose components={providers}>
            {
                BrowserDetect.isIE || BrowserDetect.isSafari
                    ? (
                        <div className="alert alert-danger text-center" style={{ margin: '2em' }}>
                            <p><WarningIcon size={'2x'} /></p>
                            <p>{BrowserDetect.browserName} is not supported by the simulation you are trying to launch.</p>
                            <p>Please use the latest Chrome, Firefox or Microsoft Edge with Chromium to launch this simulation.</p>
                        </div>
                    ) : <App />
            }
        </Compose>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
