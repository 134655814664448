import { TableStyles } from 'react-data-table-component'
import styled from 'styled-components'

export const SimutechBlue = '#003462'
export const SimutechBlue90 = 'rgba(0, 52, 98, 0.9)'
export const SimutechBlueActive = '#002F58'
export const SimutechBlueHighlight = '#E7E9F0'
export const SimutechOrange = '#f47e56'
export const SimutechOrangeActive = '#f15d2c'

export const SelectedRowStyle = {
    backgroundColor: `${SimutechOrangeActive} !important`,
    color: `white !important`,
    '&&:hover': {
        backgroundColor: `${SimutechOrange} !important`,
        color: 'white !important',
    }
}

export const Wrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    margin: 'auto',
    padding: '1em'
})

export const TableTitle = styled.div({
    fontSize: '2.5em',
    paddingBottom: '1em',
    color: 'white'
})

export const customDataTableStyles: TableStyles = {

    tableWrapper: {
        style: {
            minWidth: '31.25rem',
            maxWidth: '56.25rem',
            width: '100%',
            height: '100%',
            margin: 'auto',
        },
    },
    header: {
        style: {
            fontSize: '2.5em',
            textAlign: 'center',
            backgroundColor: 'transparent',
            color: 'white',
            padding: '1em'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: SimutechBlueHighlight,
            color: SimutechOrangeActive
        },
        selectedHighlightStyle: SelectedRowStyle,
    },
    headRow: {
        style: {
            fontSize: '1.5em',
            paddingTop: '1em',
            paddingBottom: '1em',
            backgroundColor: SimutechBlueHighlight,
        },
    },
    headCells: {
        style: {
            color: SimutechBlue,
            fontWeight: 600,
        }
    },
    cells: {
        style: {
            fontSize: '1.25em',
        },
    },
    pagination: {
        style: {
            backgroundColor: SimutechBlueHighlight,
            color: SimutechBlue,
            maxWidth: '70rem',
            margin: 'auto',
        },
    }
}