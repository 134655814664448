import { useContext, createContext, Dispatch, ReactNode, useReducer, FC, useCallback } from 'react'

type State = {
    isLMSAvailable: boolean
    courseCompleted: boolean
}

const initialState: State = {
    isLMSAvailable: false,
    courseCompleted: false
}

enum ActionType {
    SET_LMS_AVAILABLE,
    SET_COURSE_COMPLETED,
}

type LMSContextAction =
    {
        type: ActionType.SET_LMS_AVAILABLE
        payload: boolean
    } |
    {
        type: ActionType.SET_COURSE_COMPLETED
        payload: boolean
    }

const LMSContext = createContext<{
    lmsState: State
    dispatch: Dispatch<LMSContextAction>
}>({ lmsState: initialState, dispatch: () => null })

const reducer = (state: State, action: LMSContextAction): State => {
    switch (action.type) {
        case ActionType.SET_LMS_AVAILABLE:
            return { ...state, isLMSAvailable: action.payload } as State
        case ActionType.SET_COURSE_COMPLETED:
            return { ...state, courseCompleted: action.payload } as State
        default:
            return state
    }
}

const LMSContextProvider: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const contextValue = { lmsState: state, dispatch }
    return (
        <LMSContext.Provider value={contextValue}>
            {children}
        </LMSContext.Provider>
    )
}

const useLMSContext = () => {
    const context = useContext(LMSContext)
    if (!context) throw new Error(
        `useLMSContext must be used within a LMSContextProvider.
        Wrap a parent component in <LMSContextProvider> to fix this error.`
    )
    const { lmsState, dispatch } = context

    const actions = {
        setLMSAvailable: useCallback((isAvailable: boolean) =>
            dispatch({ type: ActionType.SET_LMS_AVAILABLE, payload: isAvailable }), [dispatch]),
        setCourseCompleted: useCallback((completed: boolean) =>
            dispatch({ type: ActionType.SET_COURSE_COMPLETED, payload: completed }), [dispatch]),
    }
    return { lmsState, lmsActions: actions }
}
export default useLMSContext
export { LMSContextProvider }
