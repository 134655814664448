import { FC } from 'react'
import styled from 'styled-components'
import LoadingIndicator from 'react-spinners/PropagateLoader'

import { SimutechBlue, SimutechOrange } from '../styles/Theme'

type LoadingProps = {
    active: boolean
    text: string
}

const Loading: FC<LoadingProps> = ({ active, text }: LoadingProps) => {
    if (!active) return null

    return (
        <LoadingOverlay>
            <Content>
                <LoadingIndicator
                    color={SimutechOrange}
                    loading={active}
                />
                <LoadingText>{`${text ? text : 'Loading'}...`}</LoadingText>
            </Content>
        </LoadingOverlay>
    )

}
export default Loading

const LoadingOverlay = styled.div({
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 0,
    margin: 0,
    minHeight: '100vh',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: SimutechBlue
})

const Content = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    //margin: 'auto',
    padding: '2em',
    color: 'white'
})

const LoadingText = styled.div({
    fontSize: '1.25rem',
    margin: '3rem, 0',
    padding: '1.5rem'
})